import { AppLayout as Main } from '@/layouts'
import { createSubRoutes } from '@/router/helpers'
import { view } from '@/helpers'
import AuthMiddleware from '@/middleware/auth'

export default createSubRoutes('/', Main, [
  {
    path: '/contacts',
    name: 'Contacts',
    component: view('Contacts')
  },
  {
    path: '/contacts/create',
    name: 'ContactCreate',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
    },
    component: view('ContactDetail')
  },
  {
    path: '/contacts/:id',
    name: 'ContactDetail',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
    },
    component: view('ContactDetail')
  },
])

import InvalidCompanyException from '@/exceptions/InvalidCompanyException'

/**
 * Authentication
 */
export default class Auth {
  // Key for authentication
  tokenKey = '_auth.token'

  // Key for user
  userKey = '_auth.user'

  constructor({ httpClient, storage }) {
    this.httpClient = httpClient
    this.storage = storage
  }

  /**
   * Autentica user
   * @param  {Object} credentials
   */
  async attempt(credentials = {}) {
    const { data } = await this.httpClient.request({
      method: 'POST',
      url: 'auth/login',
      data: credentials
    })

    const { access_token, user } = data

    // Set credentials
    this.saveToken(access_token)

    if (user && user.id) {
      return this.saveUser(user)
    }

    return await this.refreshUser()
  }

  /**
   * Login as user
   * @param  {Object} credentials
   */
  async loginAs(token = {}) {
    const { data } = await this.httpClient.request({
      method: 'POST',
      url: 'auth/login-as',
      data: token
    })

    const { access_token, user } = data

    // Set credentials
    this.saveToken(access_token)

    if (user && user.id) {
      return this.saveUser(user)
    }

    return await this.refreshUser()
  }

  /**
   * Refresh current user
   * @return {Auth}
   */
  async refreshUser() {
    const user = await this.fetchUser()

    this.saveUser(user)

    return user
  }

  /**
   * Fetch user
   *
   * @return {Object}
   */
  async fetchUser() {
    const { data } = await this.httpClient.request({
      method: 'GET',
      url: 'user/me',
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      }
    })

    if (!data.company) {
      //throw new InvalidCompanyException('Invalid company')
    }
    return data
  }

  /**
   * Logout user
   */
  async logout(token) {
    if (!token) {
      token = this.getToken()
    }

    this.clearStorage()

    if (token) {
      this.httpClient.request({
        method: 'POST',
        url: 'auth/logout',
        data: { token }
      })
    }
  }

  /**
   * Clear local storage
   */
  clearStorage() {
    this.storage.remove(this.tokenKey)
    this.storage.remove(this.userKey)
  }

  /**
   * Set new authentication token
   *
   * @param {String} token
   */
  saveToken(token) {
    this.storage.set(this.tokenKey, token)
    return token
  }

  /**
   * Save current user locally
   *
   * @param {Object} user
   */
  saveUser(user = {}) {
    this.storage.set(this.userKey, user)
    return user
  }

  /**
   * Get current user
   *
   * @return {Object|null}
   */
  user() {
    return this.check() ? this.storage.get(this.userKey) : null
  }

  /**
   * Get current token
   *
   * @return {String|undefined}
   */
  getToken() {
    return this.storage.get(this.tokenKey)
  }

  /**
   * Check if user is authenticated
   *
   * @return {boolean}
   */
  check() {
    return this.storage.has(this.tokenKey)
  }
}

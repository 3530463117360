import ApiBase from '@/api/ApiBase'

// Projects API Abstraction
export default class Projects extends ApiBase {

  /**
   * Check existing date already for project
   *
   * @return {Promise}
   */
  checkDate(data = {}, options = {}) {
    return this.authRequest('POST', 'projects/check/date', { data, ...options })
  }

  /**
   * Count projects
   *
   * @return {Promise}
   */
  count(options = {}) {
    return this.authRequest('GET', 'projects/count', { ...options })
  }

  /**
   * Search projects
   *
   * @return {Promise}
   */
  search(search) {
    return this.authRequest('GET', `projects/search/${search}`)
  }

  /**
   * Create project
   *
   * @return {Promise}
   */
  create(data = {}, options = {}) {
    return this.authRequest('POST', 'projects', { data, ...options })
  }

  /**
   * Save project notes
   *
   * @return {Promise}
   */
  saveNotes(id, data = {}, options = {}) {
    return this.authRequest('PATCH', `projects/${id}/notes`, { data, ...options })
  }

  /**
   * Save project date
   *
   * @return {Promise}
   */
  saveDate(id, data = {}, options = {}) {
    return this.authRequest('PATCH', `projects/${id}/date`, { data, ...options })
  }

  /**
   * Update project
   *
   * @return {Promise}
   */
  update(id, data = {}, options = {}) {
    return this.authRequest('PATCH', `projects/${id}`, { data, ...options })
  }

  /**
   * createClientPortal
   * @param  {String}
   * @return {Promise}
   */
  createClientPortal(id) {
    return this.authRequest('GET', `projects/createtoken/${id}`)
  }

  /**
   * Patch state
   * @param  {String}
   * @return {Promise}
   */
  updateState(id, state, options = {}) {
    return this.authRequest('PATCH', `projects/${id}/state/${state}`,  {...options})
  }

  /**
   * Delete project item
   *
   * @return {Promise}
   */
  deleteItem(item, projectId) {
    if(item.type == 'email'){
      return this.authRequest('DELETE', `projects/${projectId}/${item.type}/${item.item_id}/${item.email_type}`)
    }
    return this.authRequest('DELETE', `projects/${projectId}/${item.type}/${item.id}/none`)
  }

  /**
   * Load project
   *
   * @return {Promise}
   */
  load(id, options = {}) {
    return this.authRequest('GET', `projects/${id}`, { ...options })
  }

  /**
   * Fetch projects
   *
   * @return {Promise}
   */
  list(data = {}) {
    return this.authRequest(
      'GET',
      `projects?filterYear=${data.filterYear}&filterMonth=${data.filterMonth}&filterWorkflow=${data.filterWorkflow}&companyId=${data.companyId}&query=${data.query}&filter=${data.filter}&limit=${data.limit}&page=${data.page}&orderBy=${data.orderBy}&ascending=${data.ascending}&byColumn=${data.byColumn}`)
  }

  /**
   * Load project
   *
   * @return {Promise}
   */
  getProposalByState(id, state, options = {}) {
    return this.authRequest('GET', `projects/${id}/proposals/state/${state}`, { ...options })
  }

  /**
   * Update project task
   *
   * @return {Promise}
   */
  updateTask(projectId, id, data = {}) {
    return this.authRequest('PATCH', `projects/${projectId}/task/${id}`, { data })
  }

  /**
   * Archive project
   *
   * @return {Promise}
   */
  archive(projectId, data = {}) {
    return this.authRequest('PATCH', `projects/${projectId}/archive`, { data })
  }

  /**
   * Unarchive project
   *
   * @return {Promise}
   */
  unarchive(projectId, data = {}) {
    return this.authRequest('PATCH', `projects/${projectId}/unarchive`, { data })
  }

  /**
   * Delete project
   *
   * @return {Promise}
   */
  delete(projectId, data = {}) {
    return this.authRequest('DELETE', `projects/${projectId}`, { data })
  }

  /**
   * Delete project task
   *
   * @return {Promise}
   */
  deleteTask(projectId, id) {
    return this.authRequest('DELETE', `projects/${projectId}/task/${id}`)
  }

  /**
   * Add project task
   *
   * @return {Promise}
   */
  createTask(projectId) {
    return this.authRequest('POST', `projects/${projectId}/task`)
  }

  /**
   * Load project tags
   *
   * @return {Promise}
   */
  loadTags(id, tags = {}) {
    return this.authRequest('GET', `projects/${id}/tags`)
  }

  /**
   * Sync project tags
   *
   * @return {Promise}
   */
  syncTags(id, data = {}) {
    return this.authRequest('POST', `projects/${id}/tags`, { data })
  }

}

import ApiBase from '@/api/ApiBase'

// IntroVideo API Abstraction
export default class IntroVideo extends ApiBase {

  check(company, step, data = {}) {
    return this.authRequest('GET', `${company}/intro/video/${step}`, { data } )
  }

  finish(company, data = {}) {
    return this.authRequest('POST', `${company}/intro/video/finish`, { data } )
  }
}

import '@/registerServiceWorker'

import Vue from 'vue'
import VueGtag from 'vue-gtag'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import App from '@/views/App'
import { loadIcons } from '@/utils/font-awesome'
import { setFilters } from '@/filters'
import { createRouter } from '@/router'
import { createStore } from '@/store'
import { install as installPlugins } from '@/plugins'
import { registerHttpInterceptors } from '@/interceptors'
import config from '@/config'
import GoogleAuth from '@/config/google_oAuth.js'
import VueLogger from 'vuejs-logger'
import '@/mixins'

const isProduction = process.env.NODE_ENV === 'production'

const options = {
    isEnabled: true,
    logLevel : isProduction ? 'error' : 'debug',
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : true,
    separator: '|',
    showConsoleColors: true
}

Vue.use(VueLogger, options)

const gauthOption = {
  clientId: '248391604423-fv62vlt999gmt16cu39dgt8oa63oi1j4.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account'
}
Vue.use(GoogleAuth, gauthOption)


Vue.config.productionTip = false

// Set filters
setFilters()

// Load font-awesome icons
loadIcons()

// Install all plugins
installPlugins(Vue)

// Install router
const router = createRouter()

// Create Store
const store = createStore()

Vue.use(VueGtag, {
  config: { id: config.googleAnalyticsId }
}, router)

// Register interceptors
registerHttpInterceptors(Vue, router)

if (config.sentryDsn) {
  Sentry.init({
    environment: config.env,
    release: config.appVersion,
    dsn: config.sentryDsn,
    debug: config.sentryDebug,
    integrations: [new Integrations.Vue({Vue, attachProps: true})]
  })

  Sentry.configureScope(s => s.setTag('transaction_id', config.transactionId))
}

new Vue({
  router,
  store,
  async mounted() {
    setInterval(async () => await this.checkSessionHealth(), 10 * 60 * 1000)
  },
  methods: {
    async checkSessionHealth() {
      if (this.$auth.check()) {
        const response = await this.$api.get('health').ping()
      }
    }
  },
  render: h => h(App),
}).$mount('#app')

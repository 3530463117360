import ApiBase from '@/api/ApiBase'

// Company API Abstraction
export default class Referral extends ApiBase {

  /**
   * Get all referral
   *
   * @param {String} company
   * @param  {Object} data
   *
   * @return {Promise}
   */
  all(company, data) {
    return this.authRequest('GET', `${company}/referral`, { data })
  }
}

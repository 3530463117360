import ApiBase from '@/api/ApiBase'
import { client } from './VOClient'

// TemplateMail API Abstraction
export default class TemplateMail extends ApiBase {

  /**
   * List
   * @param  {String}
   * @return {Promise}
   */
  list(company, options = {}) {
    return this.authRequest('GET', `company/${company}/templates/mails`, options)
  }

  /**
   * Update / Create
   * @param  {String}
   * @return {Promise}
   */
  updateOrCreate(data = {}, options = {}) {
    return this.authRequest('POST', 'templates/mails', {data, options})
  }

  /**
   * Duplicate
   * @param  {String}
   * @return {Promise}
   */
  duplicate(company, id, options = {}) {
    return this.authRequest('POST', `company/${company}/templates/mails/${id}/duplicate`, options)
  }

  /**
   * Delete
   * @param  {String}
   * @return {Promise}
   */
  delete(id, options = {}) {
    return this.authRequest('DELETE', `templates/mails/${id}`, options)
  }

  /**
   * Get detail
   * @param  {String}
   * @return {Promise}
   */
  detail(company, id, options = {}) {
    return this.authRequest('GET', `company/${company}/templates/mails/${id}/detail`, options)
  }
}

export const getMailTemplates = (companyId, data = {}) => {
  return client.get(`company/${companyId}/templates/mails`, data)
}

import auth from '@/auth'

export default (to, from, next) => {

  if (auth.check() && auth.user().company) {
    return next({ name: 'Projects' })
  }

  if (!auth.check()) {
    return next({ name: 'Login' })
  }

  return next()
}

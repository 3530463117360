<style lang="scss" src="./style.scss"></style>

<template>
  <div
    v-if="show"
    class="text-center h-full flex items-center"
  >
    <div class="w-full">
      <img
        src="@/assets/img/icons/loader.svg"
        width="50"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'VSpinner',
  props: {
    show: {
      type: Boolean,
      required: true
    }
  }
}
</script>

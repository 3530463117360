<template>
  <div
    class="icon"
    :class="[`icon--${name}`, `icon--${size}`, { 'has-align-fix': fixAlign }]"
  >
    <!-- <svg class="icon__svg">
      <use xmlns:xlink="http://www.w3.org/1999/xlink" :xlink:href="'#icon--' + name"></use>
    </svg> -->

    <div v-if="name == 'font_size'">
      <svg
        aria-hidden="true"
        width="14"
        height="16"
        viewBox="0 0 448 512"
        focusable="false"
        class="fa-icon"
      ><g><path d="M432 32c8.8 0 16 7.2 16 16v80c0 8.8-7.2 16-16 16h-32c-8.8 0-16-7.2-16-16v-16h-120v112h24c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-128c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h24v-112h-120v16c0 8.8-7.2 16-16 16h-32c-8.8 0-16-7.2-16-16v-80c0-8.8 7.2-16 16-16h416zM363.3 292.7l80 80c2.6 2.6 4.7 7.7 4.7 11.3s-2.1 8.7-4.7 11.3l-80 80c-10 10-27.3 3-27.3-11.3v-48h-224v48c0 15.6-18 20.6-27.3 11.3l-80-80c-2.6-2.6-4.7-7.7-4.7-11.3s2.1-8.7 4.7-11.3l80-80c10-10 27.3-3 27.3 11.3v48h224v-48c0-15.6 18-20.6 27.3-11.3z" /></g></svg>
    </div>
    <div v-if="name == 'heading'">
      <svg
        aria-hidden="true"
        width="16"
        height="16"
        viewBox="0 0 512 512"
        focusable="false"
        class="fa-icon"
      ><g><path d="M448 96v320h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-160c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h32v-128h-192v128h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-160c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h32v-320h-32c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h160c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-32v128h192v-128h-32c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h160c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-32z" /></g></svg>
    </div>

    <div v-if="name == 'font_family'">
      <svg
        aria-hidden="true"
        width="14"
        height="16"
        viewBox="0 0 448 512"
        focusable="false"
        class="fa-icon"
      ><g><path d="M432 416c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-128c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h19.6l-23.3-64h-152.6l-23.3 64h19.6c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-128c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h23.4l130.7-362.3c4.1-12 17.6-21.7 30.3-21.7h0 47.2 0c12.6 0 26.2 9.7 30.3 21.7l130.7 362.3h23.4zM176.8 272h94.3l-47.2-129.5z" /></g></svg>
    </div>
    <div v-if="name == 'bold'">
      <svg
        aria-hidden="true"
        width="12"
        height="16"
        viewBox="0 0 384 512"
        focusable="false"
        class="fa-icon"
      ><g><path d="M333.5 238c34.4 27.5 55.6 71.2 50.8 119.6-6.9 70.7-70.2 122.4-141 122.4h-209.3c-8.8 0-16-7.2-16-16v-48c0-8.8 7.2-16 16-16h31.9v-288h-31.9c-8.8 0-16-7.2-16-16v-48c0-8.8 7.2-16 16-16h199.4c74.6 0 134.4 64.5 127.1 140.8-2 20.3-14.1 49.5-27 65.2zM145.7 112v96h87.8c26.5 0 48-21.5 48-48s-21.5-48-48-48h-87.8zM233.4 400c30.9 0 56-25.1 56-56s-25.1-56-56-56h-87.8v112h87.8z" /></g></svg>
    </div>
    <div v-if="name == 'italic'">
      <svg
        aria-hidden="true"
        width="10"
        height="16"
        viewBox="0 0 320 512"
        focusable="false"
        class="fa-icon"
      ><g><path d="M320 48v32c0 8.8-7.2 16-16 16h-62.8l-80 320h46.8c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-192c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h62.8l80-320h-46.8c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h192c8.8 0 16 7.2 16 16z" /></g></svg>
    </div>
    <div v-if="name == 'underline'">
      <svg
        aria-hidden="true"
        width="14"
        height="16"
        viewBox="0 0 448 512"
        focusable="false"
        class="fa-icon"
      ><g><path d="M32 64c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h144c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-32v160c0 44.2 35.8 80 80 80s80-35.8 80-80v-160h-32c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h144c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-32v160c0 88.2-71.8 160-160 160s-160-71.8-160-160v-160h-32zM432 448c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-416c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h416z" /></g></svg>
    </div>
    <div v-if="name == 'link'">
      <svg
        aria-hidden="true"
        width="16"
        height="16"
        viewBox="0 0 512 512"
        focusable="false"
        class="fa-icon"
      ><g><path d="M326.6 185.4c59.7 59.8 58.9 155.7 0.4 214.6-0.1 0.1-0.2 0.3-0.4 0.4l-67.2 67.2c-59.3 59.3-155.7 59.3-215 0-59.3-59.3-59.3-155.7 0-215l37.1-37.1c9.8-9.8 26.8-3.3 27.3 10.6 0.6 17.7 3.8 35.5 9.7 52.7 2 5.8 0.6 12.3-3.8 16.6l-13.1 13.1c-28 28-28.9 73.7-1.2 102 28 28.6 74.1 28.7 102.3 0.5l67.2-67.2c28.2-28.2 28.1-73.8 0-101.8-3.7-3.7-7.4-6.6-10.3-8.6-3.7-2.5-6.8-8.2-6.9-12.6-0.4-10.6 3.3-21.5 11.7-29.8l21.1-21.1c5.5-5.5 14.2-6.2 20.6-1.7 6.1 4.2 15.3 12 20.5 17.2zM467.5 44.4c59.3 59.3 59.3 155.7 0 215l-37.1 37.1c-9.8 9.8-26.8 3.3-27.3-10.6-0.6-17.7-3.8-35.5-9.7-52.7-2-5.8-0.6-12.3 3.8-16.6l13.1-13.1c28-28 28.9-73.7 1.2-102-28-28.6-74.1-28.7-102.3-0.5l-67.2 67.2c-28.2 28.2-28.1 73.8 0 101.8 3.7 3.7 7.4 6.6 10.3 8.6 3.7 2.5 6.8 8.2 6.9 12.6 0.4 10.6-3.3 21.5-11.7 29.8l-21.1 21.1c-5.5 5.5-14.2 6.2-20.6 1.7-6.1-4.2-15.3-12-20.5-17.2-59.7-59.8-58.9-155.7-0.4-214.6 0.1-0.1 0.2-0.3 0.4-0.4l67.2-67.2c59.3-59.3 155.7-59.3 215 0z" /></g></svg>
    </div>
    <div v-if="name == 'ol'">
      <svg
        aria-hidden="true"
        width="16"
        height="16"
        viewBox="0 0 512 512"
        focusable="false"
        class="fa-icon"
      ><g><path d="M61.8 401c19.6 5.1 28.7 20.5 28.7 34.9 0 21.4-14.3 44.1-48.5 44.1-16.6 0-29.3-4.8-37-9.4-5.8-4.2-6.3-9.8-2.6-15.9l5.6-9.3c3.9-6.6 9.1-7 15.6-3.1 4.1 1.7 11 3.1 15.5 3.1 10.2 0 14.4-3.5 14.4-8.2 0-6.6-5.6-9.1-15.9-9.1h-4.7c-6 0-9.3-2.1-12.3-7.9l-1-1.9c-2.5-4.8-1.2-9.8 2.8-14.9l5.6-7c2.9-3.6 7.8-9.1 11-12.3h-22.8c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h57c7.5 0 11.3 4 11.3 11.3v3.3c0 0.2 0 0.6 0 0.9 0 4.2-2.3 10.2-5.1 13.3zM496 224c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-320c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h320zM496 64c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-320c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h320zM496 384c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-320c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h320zM16 160c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h16v-64h-8c-4.4 0-8-3.6-8-8 0-1 0.4-2.6 0.8-3.6l8-16c1.2-2.4 4.4-4.4 7.1-4.4h24c4.4 0 8 3.6 8 8v88h16c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8h-64zM12.1 320c-7 0-12.1-4-12.1-11.4v-4c0-47.3 51-56.4 51-69.1 0-7.2-6-8.8-9.3-8.8h-0.1c-3 0-7.2 1.7-9.4 3.8-5.1 4.9-10.5 7-16.1 2.4l-8.6-6.9c-5.8-4.5-7.2-9.8-2.8-15.4 6.7-8.8 19.1-18.8 40.5-18.8 19.5 0 44.5 10.5 44.5 39.6 0 37.8-45 46.2-48.3 56.4h38.7c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8h-67.9z" /></g></svg>
    </div>
    <div v-if="name == 'ul'">
      <svg
        aria-hidden="true"
        width="16"
        height="16"
        viewBox="0 0 512 512"
        focusable="false"
        class="fa-icon"
      ><g><path d="M48 48c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM48 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM48 368c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM496 384c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-320c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h320zM496 64c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-320c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h320zM496 224c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-320c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h320z" /></g></svg>
    </div>
    <div v-if="name == 'quote'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      ><title>close-quote</title><path d="M18.559,3.932a4.942,4.942,0,1,0,0,9.883,4.609,4.609,0,0,0,1.115-.141.25.25,0,0,1,.276.368,6.83,6.83,0,0,1-5.878,3.523,1.25,1.25,0,0,0,0,2.5,9.71,9.71,0,0,0,9.428-9.95V8.873A4.947,4.947,0,0,0,18.559,3.932Z" /><path d="M6.236,3.932a4.942,4.942,0,0,0,0,9.883,4.6,4.6,0,0,0,1.115-.141.25.25,0,0,1,.277.368A6.83,6.83,0,0,1,1.75,17.565a1.25,1.25,0,0,0,0,2.5,9.711,9.711,0,0,0,9.428-9.95V8.873A4.947,4.947,0,0,0,6.236,3.932Z" /></svg>
    </div>
    <div v-if="name == 'redo'">
      <svg
        aria-hidden="true"
        width="16"
        height="16"
        viewBox="0 0 512 512"
        focusable="false"
        class="fa-icon"
      ><g><path d="M500.3 0c6.6 0 12 5.4 12 12v200.3c0 6.6-5.4 12-12 12h-200.3c-6.6 0-12-5.4-12-12v-47.4 0c0-6.6 5.4-12 12-12 0.2 0 0.4 0 0.6 0l101.5 4.9c-28.9-43-94.3-77.8-146.1-77.8-97.2 0-176 78.8-176 176 0 97.2 78.8 176 176 176 36.7 0 88.7-19.7 116.3-43.9 1.9-1.6 5.4-3 7.9-3 2.7 0 6.5 1.6 8.5 3.5l34 34c1.9 1.9 3.5 5.7 3.5 8.5 0 3-1.8 7-4 8.9-39 35.3-113.3 63.9-165.8 63.9h-0.3c-136.9 0-247.9-110.9-248-247.8-0.1-136.7 111.3-248.2 248-248.2h0.3c63.2 0 147.7 39.1 188.6 87.3l-4-82.8c0-0.2 0-0.4 0-0.6 0-6.6 5.4-12 12-12h0 47.4z" /></g></svg>
    </div>
    <div v-if="name == 'undo'">
      <svg
        aria-hidden="true"
        width="16"
        height="16"
        viewBox="0 0 512 512"
        focusable="false"
        class="fa-icon"
      ><g><path d="M212.3 224.3h-200.3c-6.6 0-12-5.4-12-12v-200.3c0-6.6 5.4-12 12-12h48c6.6 0 12 5.4 12 12v78.1c45.8-50.8 112.3-82.6 186.2-82.1 136.9 1 246.4 111.6 246.2 248.5-0.3 136.7-111.2 247.5-248 247.5-64.1 0-122.5-24.3-166.5-64.2-5.1-4.6-5.3-12.6-0.5-17.4l34-34c4.5-4.5 11.7-4.7 16.4-0.5 31 27.5 71.9 44.1 116.6 44.1 97.3 0 176-78.7 176-176 0-97.3-78.7-176-176-176-58.5 0-110.3 28.5-142.3 72.3h98.3c6.6 0 12 5.4 12 12v48c0 6.6-5.4 12-12 12z" /></g></svg>
    </div>
    <div v-if="name == 'image'">
      <svg
        id="icon--image"
        viewBox="0 0 24 24"
      ><circle
        cx="9.75"
        cy="6.247"
        r="2.25"
      /><path d="M16.916 8.71A1.027 1.027 0 0016 8.158a1.007 1.007 0 00-.892.586l-1.558 3.434a.249.249 0 01-.422.053l-.82-1.024a1 1 0 00-.813-.376 1.007 1.007 0 00-.787.426L7.59 15.71a.5.5 0 00.41.79h12a.5.5 0 00.425-.237.5.5 0 00.022-.486z" /><path d="M22 0H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2V2a2 2 0 00-2-2zm-.145 18.354a.5.5 0 01-.354.146H6a.5.5 0 01-.5-.5V2.5A.5.5 0 016 2h15.5a.5.5 0 01.5.5V18a.5.5 0 01-.145.351z" /><path d="M19.5 22h-17a.5.5 0 01-.5-.5v-17a1 1 0 00-2 0V22a2 2 0 002 2h17.5a1 1 0 000-2z" /></svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'normal',
    },
    modifier: {
      type: String,
      default: null,
    },
    fixAlign: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.icon {
  position: relative;
  vertical-align: middle;
  width: 15px;
  height:auto;
  fill: currentColor;
  text-align:center;

  // &.has-align-fix {
  //  top: -.1rem;
  // }
  &__svg {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
  }
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}
// svg sprite
body > svg,
.icon use > svg,
symbol {
  path,
  rect,
  circle,
  g {
    fill: currentColor;
    stroke: none;
  }
  *[d="M0 0h24v24H0z"] {
    display: none;
  }
}
</style>

import ApiBase from './ApiBase'
import qs from 'qs'

/**
 * A wrapper class to make working with the API a bit more pleasant
 */
class VOClient {
  constructor () {
    this.client = new ApiBase()
  }

  getErrors(response) {
    const { message, errors } = ((response || {}).data || {})

    if (typeof errors === 'object') {
      return Object.entries(errors).reduce((prev, [key, value]) => {
        return [...prev, ...value]
      }, [])
    }

    return message
      ? [message]
      : ['An unknown error happened']
  }

  async request(method, url, data = {}, options = {})
  {
    try {
      const res = await this.client.authRequest(method, url, { data, ...options })
      return [res, null]
    } catch (e) {
      return [e.response, this.getErrors(e.response)]
    }
  }

  get(url, data = null) {
    return this.request(
      'GET',
      data ? `${url}?${qs.stringify(data)}` : url
    )
  }

  post(url, data = {}) {
    return this.request('POST', url, data)
  }

  put(url, data = {}) {
    return this.request('PUT', url, data)
  }

  patch(url, data = {}) {
    return this.request('PATCH', url, data)
  }

  delete(url, data = {}) {
    return this.request('DELETE', url, data)
  }
}

export const client = new VOClient()

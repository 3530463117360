<style lang="scss">

</style>
<template>
  <div>
    <modal
      name="emailModal"
      class="emailModal modal"
      width="90%"
      height="auto"
      :max-width="700"
      :adaptive="true"
    >
      <div
        v-if="isSendingMail"
        class="text-center"
      >
        <img
          src="@/assets/img/icons/loader.svg"
          width="50"
          class="py-10"
        >
      </div>

      <div
        v-else
        class="my-8 mx-8 pt-5 relative"
      >
        <div
          class="close "
          @click="$modal.hide('emailModal')"
        />
        <input
          v-model="toEmailView"
          v-validate="{ required: true }"
          type="text"
          class="w-full underline-box"
          name="toEmail"
          placeholder="To email"
        >
        <input
          v-model="fromEmailView"
          v-validate="{ required: true }"
          type="text"
          class="w-full underline-box"
          name="fromEmail"
          placeholder="From email"
          readonly="readonly"
        >
        <input
          v-model="subjectEmail"
          v-validate="{ required: true }"
          type="text"
          class="w-full underline-box"
          name="subjectEmail"
          placeholder="Subject"
        >

        <div
          v-model="contentEmail"
          :editor="editor"
          :config="editorConfig"
        />

        <div class="flex w-full justify-end mt-8">
          <div>
            <div
              class="inline-block text-green underline mr-3 cursor-pointer"
              @click="$emit('saveAsDraft')"
            >
              Save as draft
            </div> <div
              class="inline-block w-24 text-center green-btn"
              @click="sendMail()"
            >
              Send
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { ckEditorConfig } from '@/utils/helpers'

export default {
  name: 'VEmailModal',
  props: {
    isSendingMail: {
      type: Boolean,
      default: false
    },
    contentEmail: {
      type: String,
      default: ''
    },
    fromEmail: {
      type: String,
      default: ''
    },
    fromEmailView: {
      type: String,
      default: ''
    },
    toEmail: {
      type: Array,
      default: ''
    },
    toEmailView: {
      type: String,
      default: ''
    },
    subjectEmail: {
      type: String,
      default: ''
    },
    emailTag: {
      type: String,
      default: ''
    },
    emailTags: {
      type: Array,
      default: null
    },
    autocompleteEmailTagItems: {
      type: Array,
      default: null
    },
  },
  data() {
    return {

    }
  },
  computed: {

  },
}
</script>

import { Mark } from 'tiptap'
import { toggleMark, updateMark, markInputRule } from 'tiptap-commands'

export default class FontType extends Mark {

  // choose a unique name
  get name() {
    return 'font_type'
  }

  get schema() {
    return {
        attrs: {
          name: {
            default: 'Arial',
          },
        },
        inline: true,
        group: 'inline',
        parseDOM: [
            {
              style: 'font-family',
              getAttrs: value => ({ name: value }),
            },
        ],
       toDOM: mark => ['span', { style: `font-family: ${mark.attrs.name};` }, 0],
    }
  }

  commands({ type }) {
    return attrs => updateMark(type, attrs)
  }

  inputRules({ type }) {
    return [
      markInputRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)$/, type),
    ]
  }
}

<style lang="scss">
  .tutorial-btn{
    background:#F6F6F6;
    border:1px solid #E4E4E4;
    border-radius:4px;
    padding:9px 8px 7px 8px;
    cursor:pointer;
    height:42px;

    .ico{
      padding:0 0 0 0;
    }

    &:hover{
      background: darken(#F6F6F6, 2%);
    }

    &.dark{
      color:#B1B6CD;
      background:#3F4354;
      border:1px solid #3F424F;

      &:hover{
        background: lighten(#3F4354, 4%);
      }
    }
  }
</style>
<template>
  <div
    class="tutorial-btn flex items-center"
    :class="[mode == 'dark' ? 'dark' : '']"
    @click="$emit('click')"
  >
    <div class="mr-2">
      <span v-if="mode == 'light'">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.6519 7.83582L12.8442 9.64416C12.5347 9.95306 12.0292 9.95364 11.7192 9.64416C11.4091 9.33409 11.4091 8.82862 11.7192 8.51856L13.5269 6.71022C14.0956 6.14215 14.4092 5.39038 14.4097 4.59298C14.4097 3.84531 14.1383 3.13099 13.6457 2.58222C13.1285 2.00597 12.3914 1.67659 11.5706 1.65436C11.5402 1.65378 11.5103 1.65319 11.4799 1.65319C10.5988 1.65319 9.74351 2.0048 9.12221 2.62552L7.47417 4.27356C7.1641 4.58362 6.65922 4.58304 6.34857 4.27356C6.1988 4.1232 6.11572 3.92312 6.11572 3.71075C6.11572 3.49839 6.1988 3.29831 6.34857 3.14795L7.9966 1.49991C8.94553 0.551574 10.27 0.0303094 11.6121 0.0636563C12.8746 0.0981733 14.0166 0.614173 14.8286 1.51746C15.5839 2.35874 15.9999 3.45158 15.9999 4.5924C15.9999 5.81512 15.5213 6.96588 14.6519 7.83582Z" fill="white"/>
          <path d="M9.64212 12.8455L7.98356 14.5046C7.03756 15.4506 5.73527 15.9713 4.37741 15.9362C3.11783 15.9017 1.97819 15.3851 1.1685 14.4812C0.414979 13.6405 0.000190512 12.55 0.000190512 11.4086C-0.0109251 10.1871 0.464707 9.03281 1.33992 8.15818L3.14767 6.35043C3.29802 6.20007 3.49752 6.11758 3.70989 6.11758C3.92225 6.11758 4.12233 6.20066 4.27269 6.35043C4.42246 6.50078 4.50553 6.70086 4.50553 6.91323C4.50553 7.1256 4.42246 7.32568 4.27269 7.47603L2.46494 9.28378C1.89453 9.85419 1.58446 10.6071 1.59148 11.401C1.59148 12.154 1.86235 12.8712 2.35378 13.4194C2.86919 13.9945 3.604 14.3233 4.42129 14.3455C5.31931 14.3601 6.21968 14.0179 6.85912 13.3784L8.51769 11.7199C8.82659 11.4104 9.33206 11.4098 9.64271 11.7199C9.95219 12.0305 9.95219 12.5354 9.64212 12.8455Z" fill="white"/>
          <path d="M4.44476 10.4281L10.4273 4.44555C10.5823 4.29052 10.7859 4.21329 10.9895 4.21329C11.1931 4.21329 11.3967 4.2911 11.5517 4.44555C11.8618 4.75562 11.8618 5.26109 11.5517 5.57116L5.56978 11.5537C5.26907 11.8544 4.74546 11.8544 4.44476 11.5537C4.29499 11.4034 4.21191 11.2033 4.21191 10.9909C4.21191 10.7785 4.29499 10.5785 4.44476 10.4281Z" fill="white"/>
        </svg>
      </span>
      <span v-else>
        <span v-if="mode == 'dark'">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.6519 7.83582L12.8442 9.64416C12.5347 9.95306 12.0292 9.95364 11.7192 9.64416C11.4091 9.33409 11.4091 8.82862 11.7192 8.51856L13.5269 6.71022C14.0956 6.14215 14.4092 5.39038 14.4097 4.59298C14.4097 3.84531 14.1383 3.13099 13.6457 2.58222C13.1285 2.00597 12.3914 1.67659 11.5706 1.65436C11.5402 1.65378 11.5103 1.65319 11.4799 1.65319C10.5988 1.65319 9.74351 2.0048 9.12221 2.62552L7.47417 4.27356C7.1641 4.58362 6.65922 4.58304 6.34857 4.27356C6.1988 4.1232 6.11572 3.92312 6.11572 3.71075C6.11572 3.49839 6.1988 3.29831 6.34857 3.14795L7.9966 1.49991C8.94553 0.551574 10.27 0.0303094 11.6121 0.0636563C12.8746 0.0981733 14.0166 0.614173 14.8286 1.51746C15.5839 2.35874 15.9999 3.45158 15.9999 4.5924C15.9999 5.81512 15.5213 6.96588 14.6519 7.83582Z" fill="white"/>
          <path d="M9.64212 12.8455L7.98356 14.5046C7.03756 15.4506 5.73527 15.9713 4.37741 15.9362C3.11783 15.9017 1.97819 15.3851 1.1685 14.4812C0.414979 13.6405 0.000190512 12.55 0.000190512 11.4086C-0.0109251 10.1871 0.464707 9.03281 1.33992 8.15818L3.14767 6.35043C3.29802 6.20007 3.49752 6.11758 3.70989 6.11758C3.92225 6.11758 4.12233 6.20066 4.27269 6.35043C4.42246 6.50078 4.50553 6.70086 4.50553 6.91323C4.50553 7.1256 4.42246 7.32568 4.27269 7.47603L2.46494 9.28378C1.89453 9.85419 1.58446 10.6071 1.59148 11.401C1.59148 12.154 1.86235 12.8712 2.35378 13.4194C2.86919 13.9945 3.604 14.3233 4.42129 14.3455C5.31931 14.3601 6.21968 14.0179 6.85912 13.3784L8.51769 11.7199C8.82659 11.4104 9.33206 11.4098 9.64271 11.7199C9.95219 12.0305 9.95219 12.5354 9.64212 12.8455Z" fill="white"/>
          <path d="M4.44476 10.4281L10.4273 4.44555C10.5823 4.29052 10.7859 4.21329 10.9895 4.21329C11.1931 4.21329 11.3967 4.2911 11.5517 4.44555C11.8618 4.75562 11.8618 5.26109 11.5517 5.57116L5.56978 11.5537C5.26907 11.8544 4.74546 11.8544 4.44476 11.5537C4.29499 11.4034 4.21191 11.2033 4.21191 10.9909C4.21191 10.7785 4.29499 10.5785 4.44476 10.4281Z" fill="white"/>
        </svg>
      </span>
      </span>
    </div>
    <div>
      <div class="text">
        Client Portal
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VCheckbox',
  props: {
    mode: {
      type: String,
      default: 'light'
    },
  }
}
</script>

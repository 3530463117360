import auth from '@/auth'
import Api from '@/api'

const templateId = null

export default {
  namespaced: true,
  state: {
    templateId,
  },
  mutations: {
    /**
     * Set current template
     */
    SET_TEMPLATE_ID(state, templateId) {
      state.templateId = templateId
    },
  },
  actions: {
    /**
     * Load template
     */
    async loadTemplate({ commit, state }, input) {
      commit('SET_TEMPLATE_ID', input)
      return 'success'
    },
  },
  getters: {
    loadTemplateId: state => state.templateId,
  }
}

<template>
  <div
    class="input"
    :class="[`input--${type}`]"
    @click="focus()"
  >
    <div class="input__wrapper">
      <component
        :is="inputType"
        ref="input"
        class="input__field"
        :type="type"
        spellcheck="false"
        :value.prop="valueModel"
        :placeholder="placeholder"
        :disabled="disabled"
        v-bind="$attrs"
        v-on="listeners"
        @input="valueModel = $event.currentTarget.value"
        @focus="onFocus"
        @blur="onBlur"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VInput',
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: [String, Number],
      default: undefined
    },
    mask: {
      type: [Object, String],
      default: undefined
    }
  },
  data() {
    return {
      focused: false
    }
  },
  computed: {
    inputType() {
      if (this.type === 'textarea') {
        return this.type
      }

      return 'input'
    },
    listeners() {
      return Object.keys(this.$listeners)
        .filter(key => key !== 'input' && key !== 'focus' && key !== 'blur')
        .reduce((obj, key) => {
          obj[key] = this.$listeners[key]
          return obj
        }, {})
    },
    valueModel: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  mounted() {
    this.applyMask()
  },
  beforeDestroy() {
    this.removeMask()
  },
  methods: {
    isMaskAvailable() {
      return this.mask && this.$inputMask
    },
    applyMask() {
      if (!this.isMaskAvailable()) return

      const mask =
        typeof this.mask === 'object'
          ? this.mask
          : {
              mask: this.mask
            }

      this.$inputMask({
        autoUnmask: true,
        onincomplete: this.onMaskIncomplete.bind(this),
        mask
      }).mask(this.$refs.input)
    },
    removeMask() {
      if (!this.isMaskAvailable()) return

      // Remove mask from input
      if (this.$refs.input.inputmask) {
        this.$refs.input.inputmask.remove()
      }
    },
    onMaskIncomplete() {
      this.$refs.input.value = ''
    },
    focus() {
      this.$refs.input.focus()
    },
    onBlur(event) {
      this.focused = false
      this.$emit('blur', event)
    },
    onFocus(event) {
      this.focused = true
      this.$emit('focus', event)
    }
  }
}
</script>

<style lang="scss">
$focus-color: color(input, focus);
$field-border-color: color(input, normal);

.input {
  &,
  &__wrapper {
    display: block;
    width: 100%;
    margin-top: 5px;
  }

  &__field {
    width: 100%;
    height: auto;
    display: block;
    padding: 6px 12px;
    line-height: 1.5;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    color: color(input, color);
    border: 1px solid $field-border-color;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-color: #fff;

    &textarea {
      resize: none;
      height: auto;
    }

    &:focus {
      border-color: $focus-color;
      box-shadow: 0 0 0 3px rgba($focus-color, 0.25);
      outline: 0;
    }
  }

  &--calculator {
    width: 54px;
    display: inline-block;

    &,
    .input__wrapper {
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    .input__field {
      background-color: transparent;
      border: 0;
      border-bottom: 2px solid rgba(#fff, .6);
      border-radius: 0;
      color: #fff;
      font-size: 24px;
      padding: 0 0 2px 0;
      text-align: right;

      &:focus {
        border-bottom-color: #fff;
        outline: 0;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button{
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &.result{
      .input__field{
        color:#000;
        border-bottom: 2px solid rgba(#000, .6);
      }
    }
  }

  &--lg {
    .input__field {
      height: 53px;
    }
  }

}
</style>

import AuthMiddleware from '@/middleware/auth'
import AuthWithoutCompanyMiddleware from '@/middleware/authWithoutCompany'
import GuestMiddleware from '@/middleware/guest'

export const middlewares = {
  auth: AuthMiddleware,
  authWithoutCompany: AuthWithoutCompanyMiddleware,
  guest: GuestMiddleware
}

export const applyMiddleware = (...names) => {
  const middleware = []

  names.forEach(name => {
    if (middlewares[name]) {
      middleware.push(middlewares[name])
    }
  })

  return {
    meta: {
      middleware
    }
  }
}

export const ckEditorConfig = {
                toolbar: ['heading',  'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote']
              }

export function round(num, decimalPlaces = 2) {

    // num = num.toFixed(4)
    // num = Math.round(num + "e" + 4);
    // num = Number(num + "e" + -4);

    // let result = num.toFixed(decimalPlaces)
    // result = Math.round(result + "e" + decimalPlaces);
    // result = Number(result + "e" + -decimalPlaces);
    //return result;

    num = Math.round(num + 'e' + decimalPlaces)
    return Number(num + 'e' + -decimalPlaces)

    //return Math.ceil(num * 100)/100;

}


export function trim2Decimals(num) {
  if (typeof num !== 'number') {
    return num;
  }

  const result = Math.round(num * 100) / 100;
  return result.toFixed(2);
}

/**
 * Debounce callback
 *
 * @param  {Function} cb callback
 * @param  {Number}   wait Miliseconds to wait
 */
export function debounce(cb, wait) {
  let timeout

  return function() {
    const context = this
    const args = arguments

    const later = () => {
      timeout = null
      cb.apply(context, args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (!timeout) cb.apply(context, args)
  }
}

export const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult
  if (removedIndex === null && addedIndex === null) return arr

  const result = [...arr]
  let itemToAdd = payload

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0]
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd)
  }

  return result
}

export const generateItems = (count, creator) => {
  const result = []
  for (let i = 0; i < count; i++) {
    result.push(creator(i))
  }
  return result
}

export const calculateDiscountTotal = (items, discount, discountTotal, isTaxEnable, isTaxPriceIncluded) => {
  if (discountTotal <= 0) {
    return round(discountTotal)
  }

  const calculateItemTotal = (item) => {
    return parseFloat(item.price * (item.due_percentage / 100)) * item.quantity
  }

  const calculateNewTotal = (total, itemTotal, discountApplied) => {
    return parseFloat(total) + parseFloat(discountApplied ? itemTotal - round(itemTotal - (itemTotal * (discount / 100))) : itemTotal)
  }

  discountTotal = items.reduce((total, item) => {
    let itemTotal = calculateItemTotal(item)
    if (itemTotal <= 0) return total

    if (!item.is_optional || (item.is_optional && item.is_selected)) {
      return calculateNewTotal(total, itemTotal, isTaxEnable && !isTaxPriceIncluded)
    }

    return total
  }, 0)

  return round(discountTotal)
}

export const calculateTaxPrice = (isTaxIncluded, price, tax) => {
  let result = 0
  if(isTaxIncluded){
    result  = ((parseFloat(price) / (100 + parseFloat(tax)))*parseFloat(tax))
  } else {
    result = (parseFloat(price) * (parseFloat(tax) / 100))
  }
  return result
}

export const calculateTaxTotal = (items, originalTotal, discountedTotal, isTaxIncluded) => {

  // let taxClassTotal = {}
  // let taxTotal = 0
  // let that = this

  // items.forEach(function(item) {
  //   let taxClass = item.tax.toString()
  //   if(item.price > 0 && !item.is_optional || (item.is_optional && item.is_selected)){
  //     if(isTaxIncluded){
  //       taxTotal += (parseFloat(item.price) / (100 + parseFloat(item.tax)))*parseFloat(item.tax)
  //     } else {
  //       taxTotal += parseFloat(item.price) * (parseFloat(item.tax) / 100)
  //     }


  //   }
  // })
  // return Number(taxTotal)

  let results = {
    'total': 0
  }
  let taxClassTotal = {}
  let that = this
  let itemTotalPerTaxClass = {}

  items.forEach(function(item) {

    let taxClass = item.tax.toString()
    let tax = 0

    if(!item.due_percentage){
      item.due_percentage = 100
    }

    let itemTotal = (item.price*(item.due_percentage/100))*item.quantity
    let itemPlainTotal = itemTotalPerTaxClass[taxClass]
    if (typeof(itemPlainTotal) == 'undefined'){
      itemPlainTotal = 0
    }

    if(!item.is_optional || (item.is_optional && item.is_selected)){
      itemPlainTotal = Number(itemPlainTotal) + Number(itemTotal)
      itemTotalPerTaxClass[taxClass] = Number(itemPlainTotal)
    }
    if(isTaxIncluded){
      tax  = ((parseFloat(itemTotal) / (100 + parseFloat(item.tax)))*parseFloat(item.tax))
    } else {
      tax = (parseFloat(itemTotal) * (parseFloat(item.tax) / 100))
    }

    let taxTotal = taxClassTotal[taxClass]
    if (typeof(taxTotal) == 'undefined'){
      taxTotal = 0
    }

    if(!item.is_optional || (item.is_optional && item.is_selected)){
      // taxTotal = Number(taxTotal) + Number(tax)
      // taxClassTotal[taxClass] = Number((taxTotal)

      taxTotal = +taxTotal + parseFloat(tax)
      taxClassTotal[taxClass] = (taxTotal)
    }
  })

  let totalPrice = 0
  if(discountedTotal > 0){
    if(isTaxIncluded){
      Object.keys(taxClassTotal).forEach(taxRate => {
        let newDiscountedPrice = ((1 / originalTotal) * discountedTotal) * ((1-(100/(Number(taxRate)+100)))*itemTotalPerTaxClass[taxRate])
        totalPrice += round(newDiscountedPrice)
      })
    } else {
      Object.keys(taxClassTotal).forEach(taxRate => {
        let newDiscountedPrice = (taxClassTotal[taxRate] / originalTotal) * discountedTotal
        totalPrice += round(newDiscountedPrice)
      })
    }
  } else {
    Object.keys(taxClassTotal).forEach(taxRate => {
      totalPrice += round(taxClassTotal[taxRate])
    })
  }

  results['total'] = totalPrice
  return results['total']
}

export const calculateEachTaxClassPrice = (items, originalTotal, discountedTotal, isTaxIncluded) => {

  let results = {}
  let taxClassTotal = {}
  let itemTotalPerTaxClass = {}
  let itemPlainTotal = 0
  let that = this

  items.forEach(function(item) {
    let taxClass = item.tax.toString()
    let tax = 0

    if(!item.due_percentage){
      item.due_percentage = 100
    }
    let itemTotal = (item.price*(item.due_percentage/100))*item.quantity
    let itemPlainTotal = itemTotalPerTaxClass[taxClass]
    if (typeof(itemPlainTotal) == 'undefined'){
      itemPlainTotal = 0
    }

    itemPlainTotal = parseFloat(itemPlainTotal) + parseFloat(itemTotal)
    itemTotalPerTaxClass[taxClass] = parseFloat(itemPlainTotal)
    if(isTaxIncluded){
        tax  = ((parseFloat(itemTotal) / (100 + parseFloat(item.tax)))*parseFloat(item.tax))
      } else {
        tax = (parseFloat(itemTotal) * (parseFloat(item.tax) / 100))
      }

    let taxTotal = taxClassTotal[taxClass]
    if (typeof(taxTotal) == 'undefined'){
      taxTotal = 0
    }

    taxTotal = +taxTotal + parseFloat(tax)
    taxClassTotal[taxClass] = (taxTotal)
  })

  if(discountedTotal > 0){
    if(isTaxIncluded){
      Object.keys(taxClassTotal).forEach(taxRate => {
        let newDiscountedPrice = ((1 / originalTotal) * discountedTotal) * ((1-(100/(parseFloat(taxRate)+100)))*itemTotalPerTaxClass[taxRate])
        results[taxRate] = round(newDiscountedPrice)
      })
    } else {
      Object.keys(taxClassTotal).forEach(taxRate => {
        let newDiscountedPrice = (taxClassTotal[taxRate] / originalTotal) * discountedTotal
        results[taxRate] = round(newDiscountedPrice)
      })
    }
  } else {
    Object.keys(taxClassTotal).forEach(taxRate => {
      results[taxRate] = round(taxClassTotal[taxRate])
    })
  }


  return results
}

export const calculateEachTaxClassPriceWithAdditional = (items, additonalItems, originalTotal, discountedTotal, isTaxIncluded) => {

  let results = {}
  let taxClassTotal = {}
  let itemTotalPerTaxClass = {}
  let itemPlainTotal = 0
  let that = this

  items.forEach(function(item) {
    let taxClass = item.tax.toString()
    let tax = 0

    if(!item.due_percentage){
      item.due_percentage = 100
    }
    let itemTotal = (item.price*(item.due_percentage/100))*item.quantity
    let itemPlainTotal = itemTotalPerTaxClass[taxClass]
    if (typeof(itemPlainTotal) == 'undefined'){
      itemPlainTotal = 0
    }

    itemPlainTotal = parseFloat(itemPlainTotal) + parseFloat(itemTotal)
    itemTotalPerTaxClass[taxClass] = parseFloat(itemPlainTotal)
    if(isTaxIncluded){
        tax  = ((parseFloat(itemTotal) / (100 + parseFloat(item.tax)))*parseFloat(item.tax))
      } else {
        tax = (parseFloat(itemTotal) * (parseFloat(item.tax) / 100))
      }

    let taxTotal = taxClassTotal[taxClass]
    if (typeof(taxTotal) == 'undefined'){
      taxTotal = 0
    }

    taxTotal = +taxTotal + parseFloat(tax)
    taxClassTotal[taxClass] = (taxTotal)
  })

  additonalItems.forEach(function(item) {
    let taxClass = item.tax.toString()

    if(item.is_selected){
      let tax = 0

      if(!item.due_percentage){
        item.due_percentage = 100
      }
      let itemTotal = (item.price*(item.due_percentage/100))*item.quantity
      let itemPlainTotal = itemTotalPerTaxClass[taxClass]
      if (typeof(itemPlainTotal) == 'undefined'){
        itemPlainTotal = 0
      }

      itemPlainTotal = parseFloat(itemPlainTotal) + parseFloat(itemTotal)
      itemTotalPerTaxClass[taxClass] = parseFloat(itemPlainTotal)
      if(isTaxIncluded){
        tax  = ((parseFloat(itemTotal) / (100 + parseFloat(item.tax)))*parseFloat(item.tax))
      } else {
        tax = (parseFloat(itemTotal) * (parseFloat(item.tax) / 100))
      }

      let taxTotal = results[taxClass]

      if (typeof(taxTotal) == 'undefined'){
        taxTotal = 0
      }
      taxTotal = +taxTotal + parseFloat(tax)

      if (typeof(taxClassTotal[taxClass]) == 'undefined'){
        taxClassTotal[taxClass] = 0
      }

      taxClassTotal[taxClass] += taxTotal
    }
  })

  if(discountedTotal > 0){
    if(isTaxIncluded){
      Object.keys(taxClassTotal).forEach(taxRate => {
        let newDiscountedPrice = ((1 / originalTotal) * discountedTotal) * ((1-(100/(parseFloat(taxRate)+100)))*itemTotalPerTaxClass[taxRate])
        results[taxRate] = round(newDiscountedPrice)
      })
    } else {
      Object.keys(taxClassTotal).forEach(taxRate => {
        let newDiscountedPrice = (taxClassTotal[taxRate] / originalTotal) * discountedTotal
        results[taxRate] = round(newDiscountedPrice)
      })
    }
  } else {
    Object.keys(taxClassTotal).forEach(taxRate => {
      results[taxRate] = round(taxClassTotal[taxRate])
    })
  }

  return results
}


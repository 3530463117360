import { AppLayout as Main } from '@/layouts'
import { createSubRoutes } from '@/router/helpers'
import { view } from '@/helpers'
import AuthMiddleware from '@/middleware/auth'

export default createSubRoutes('/', Main, [
  {
    path: '/projects/:id/proposal/choose',
    name: 'ProposalChoose',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
      hideFooter: true
    },
    component: view('Proposals/ProposalChoose')
  },{
    path: '/projects/:id/proposal/create',
    name: 'ProposalCreate',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
      hideFooter: true
    },
    component: view('Proposals/ProposalEdit')
  },{
    path: '/projects/:id/proposal/:proposalId',
    name: 'ProposalDetail',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
      hideFooter: true
    },
    component: view('Proposals/ProposalEdit')
  },{
    path: '/proposals/preview/:proposalId',
    name: 'ProposalPreview',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
      hideFooter: true
    },
    component: view('Proposals/ProposalPreview')
  }
])

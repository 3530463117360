<template>
  <div class="mb-3 language-switcher">
    <div class="flex flex-row language-switcher__group">
      <template v-for="locale in languages">
        <button
          type="submit"
          class="flex flex-row items-center bg-white p-3 text-grey-darker font-normal"
          :class="({
            'bg-white': selectedLanguage === locale,
            'bg-grey-f6': selectedLanguage !== locale,
          })"
          @click.prevent="$emit('change', locale)"
          >
          {{ locale.toUpperCase() }}
        </button>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    /**
     * Component name.
     *
     * @type {String}
     */
    name: 'LanguageSwitcher',

    /**
     * The passed properties.
     *
     * @type {Object}
     */
    props: {
      label: {
        type: String,
        default: ''
      },
      languages: {
        type: Array,
        default: () => {[]},
      },
      selectedLanguage: {
        type: String,
        default: '',
      }
    }
  }
</script>

<style lang="scss">
  .language-switcher {
    > span {
      font-size: 14px;
    }

    .language-switcher__group {
      button {
        border-radius: 0 !important;
        border: 1px solid #d1d1d1;
        padding: 12px 16px;
        transition: all .2s ease-out;

        &:hover {
          opacity: .7;
          background: #f6f6f6;
        }

        &:first-child {
          border-top-left-radius: 3px !important;
          border-bottom-left-radius: 3px !important;
        }

        &:last-child {
          border-top-right-radius: 3px !important;
          border-bottom-right-radius: 3px !important;
        }
      }

      button + button {
        border-left: 0;
      }
    }
  }
</style>

export {
  faSpinner,
  faBell,
  faQuestionCircle,
  faRedo,
  faTrash,
  faPlusCircle
} from '@fortawesome/free-solid-svg-icons'

// Custom icons
export const faVesperDashboard = {
  prefix: 'fas',
  iconName: 'vesper-dashboard',
  icon: [
    14,
    14,
    [],
    'f0000',
    'M7.765 0H14v4.667H7.765V0zm0 14V6.235H14V14H7.765zM0 14V9.333h6.235V14H0zm0-6.235V0h6.235v7.765H0z'
  ]
}

// Documentation
export const faVesperDocumentation = {
  prefix: 'fas',
  iconName: 'vesper-documentation',
  icon: [
  14,
  18,
  [],
  'f0000',
  'M7 13.958a4.29 4.29 0 0 0 2.2-.587 4.354 4.354 0 0 0 1.593-1.593c.392-.67.587-1.4.587-2.19s-.195-1.52-.587-2.19A4.35 4.35 0 0 0 7 5.24c-.754 0-1.46.182-2.117.545a4.29 4.29 0 0 0-1.572 1.467L2.221 6.1v3.479h3.48L4.296 8.195a2.95 2.95 0 0 1 1.1-1.205A2.97 2.97 0 0 1 7 6.539c.545 0 1.055.136 1.53.409.475.272.852.642 1.132 1.11.28.468.419.975.419 1.52s-.14 1.051-.42 1.52c-.279.467-.656.841-1.131 1.12-.475.28-.985.42-1.53.42-.503 0-.978-.119-1.425-.357a2.92 2.92 0 0 1-1.069-.943H2.997a4.263 4.263 0 0 0 1.582 1.897c.72.482 1.527.723 2.421.723zM8.76 0L14 5.24v10.479c0 .475-.175.88-.524 1.215-.35.335-.761.503-1.236.503H1.76c-.475 0-.887-.168-1.236-.503A1.623 1.623 0 0 1 0 15.72l.042-14c0-.475.168-.88.503-1.216C.88.168 1.285 0 1.76 0h7z'
  ]
}

// Futures
export const faVesperFutures = {
  prefix: 'fas',
  iconName: 'vesper-futures',
  icon: [
  14,
  14,
  [],
  'f0000',
  'M10.479 14v-3.521H14V14h-3.521zm0-5.24V5.24H14v3.52h-3.521zm-5.24-5.239V0H8.76v3.521H5.24zM10.48 0H14v3.521h-3.521V0zm-5.24 8.76V5.24H8.76v3.52H5.24zM0 8.76V5.24h3.521v3.52H0zM0 14v-3.521h3.521V14H0zm5.24 0v-3.521h3.52V14H5.24zM0 3.521V0h3.521v3.521H0z'
  ]
}

// News
export const faVesperNews = {
  prefix: 'fas',
  iconName: 'vesper-news',
  icon: [
    14,
    14,
    [],
    'f0000',
    'M12.542 0a1.4 1.4 0 0 1 1.02.438A1.4 1.4 0 0 1 14 1.458v11.084a1.4 1.4 0 0 1-.438 1.02 1.4 1.4 0 0 1-1.02.438H1.458a1.4 1.4 0 0 1-1.02-.438A1.4 1.4 0 0 1 0 12.543V1.458C0 1.07.146.73.438.438A1.4 1.4 0 0 1 1.458 0h11.084zM8.75 11.083v-1.75H2.917v1.75H8.75zm2.333-3.208v-1.75H2.917v1.75h8.166zm0-3.208v-1.75H2.917v1.75h8.166z'
  ]
}

// Forecast
export const faVesperForecast = {
  prefix: 'fas',
  iconName: 'vesper-forecast',
  icon: [
    14,
    14,
    [],
    'f0000',
    'M9 0h5v5l-1.906-1.906L8 7.5 4.656 4 0 8l4.656-6.344L8 5l2.938-3.063z'
  ]
}

// Calculations
export const faVesperCalculations = {
  prefix: 'fas',
  iconName: 'vesper-calculations',
  icon: [
    14,
    19,
    [],
    'f0000',
    'M12.444 0c.454 0 .827.148 1.118.445.292.297.438.677.438 1.138v15.834c0 .461-.146.84-.438 1.138-.291.297-.664.445-1.118.445H1.556c-.454 0-.827-.148-1.119-.445C.147 18.258 0 17.878 0 17.417V1.583C0 1.122.146.743.438.445.728.148 1.102 0 1.556 0h10.888zm-.388 10.688H9.722v6.333h2.334v-6.334zm0-3.959H9.722v2.375h2.334V6.73zm-3.89 7.917H5.834v2.375h2.334v-2.375zm0-3.959H5.834v2.376h2.334v-2.376zm0-3.958H5.834v2.375h2.334V6.73zm-3.888 7.917H1.944v2.375h2.334v-2.375zm0-3.959H1.944v2.376h2.334v-2.376zm0-3.958H1.944v2.375h2.334V6.73zM1.944 4.354h10.112V1.98H1.944v2.375z'
  ]
}

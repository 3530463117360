<template>
  <div>
    <div class="block lg:hidden">
      <div class="bg-grey-black px-4">
        <div class="select-container w-full my-5">
          <select
            v-model="settingNav"
            @change="changeNav"
          >
            <option
              value="/settings"
            >
              Company
            </option>
            <option
              value="/settings/invoicing"
            >
              Proposal & Invoice
            </option>
            <option
              value="/settings/tax"
            >
              Tax
            </option>
            <option
              value="/settings/plan-and-billing"
            >
              Plan & Billing
            </option>
            <option
              value="/settings/email"
            >
              Email
            </option>
            <option
              value="/settings/account"
            >
              My Account
            </option>
            <option
              v-if="user.company.is_ambassador || user.company.is_referrer"
              value="/settings/referral"
            >
              Referral
            </option>
            <option
              value="/settings/language"
            >
              Language
            </option>
            <option
              value="/settings/integration"
            >
              API Integration
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="hidden lg:block">
      <h1 class="text-black text-3xl mb-5">
        Settings
      </h1>
      <router-link
        to="/settings"
        exact
        class="nav"
      >
        Company
      </router-link>
      <router-link
        to="/settings/invoicing"
        exact
        class="nav"
      >
        Proposal & Invoice
      </router-link>
      <router-link
        to="/settings/tax"
        exact
        class="nav"
      >
        Tax
      </router-link>
      <router-link
        to="/settings/plan-and-billing"
        exact
        class="nav"
      >
        Plan & Billing
      </router-link>
      <router-link
        to="/settings/email"
        exact
        class="nav"
      >
        Email
      </router-link>
      <router-link
        to="/settings/account"
        exact
        class="nav"
      >
        My Account
      </router-link>
      <router-link
        v-if="user.company.is_ambassador || user.company.is_referrer"
        to="/settings/referral"
        exact
        class="nav"
      >
        Referral
      </router-link>
      <router-link
        to="/settings/language"
        exact
        class="nav"
      >
        Language
      </router-link>
      <router-link
        to="/settings/integration"
        exact
        class="nav"
      >
        API Integration
      </router-link>
    </div>
  </div>
</template>

<script>
import auth from '@/auth'

export default {
  name: 'SettingMenu',
  data(){
    return {
      user: auth.user(),
      settingNav: '/settings',
    }
  },
  mounted(){
    this.settingNav = this.$router.currentRoute.path
  },
  methods:{
    changeNav(){
      this.$router.push(this.settingNav)
    }
  }
}
</script>

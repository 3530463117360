<template>
  <div
    class="app-wrapper"
    :class="{'embed' : this.$route.name == 'LeadForm' || this.$route.name == 'QuestionnairePreview'}"
  >
    <div class="app">
      <Header />
      <div class="app__body">
        <div class="app__body-content">
          <router-view />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import VSidebar from '@/components/VSidebar'
import Header from './Header'
import Footer from './Footer'

export default {
  name: 'AppLayout',
  components: {
      Header,
      Footer,
  }
}
</script>

<style lang="scss">

.app-wrapper{
  min-height:100%;
  background:#F4F4F4;
  display:flex;

  &.embed{
    padding:10px 0px;
    min-width: 0;
    background:none;
  }
}

.app {
  width: 100%;
  min-height:100%;
  height:auto;
  padding-bottom:160px;

  &__body, &__body-content{
    height:100%;
  }
}
</style>

<template>
  <table-component
    ref="table"
    class="z-20 mt-6 languages-table"
    :data="languages"
    sort-by="created_at"
    sort-order="desc"
    table-class="card"
    :show-filter="false"
  >
    <table-column
      :sortable="false"
      label="Active"
      cell-class="text-grey-semi-light"
    >
      <template slot-scope="row">
        <v-checkbox
          :active="row.isDefault"
          :id="`active-language-${row.code}`"
          name="active-languages[]"
          :value="activeLanguages.indexOf(row.code) !== -1"
          :checked="activeLanguages.indexOf(row.code) !== -1"
          @input="$event => $emit('onActiveChange', row.code)"
        />
      </template>
    </table-column>
    <table-column
      :sortable="false"
      label="Language"
      cell-class="text-grey-semi-light"
    >
      <template slot-scope="row">
        {{ row.label }}
      </template>
    </table-column>
    <table-column
      :sortable="false"
      label="Default"
      cell-class="text-grey-darker"
    >
      <template slot-scope="row">
        <v-radio
          v-show="row.isActive"
          name="is-default-language"
          :value="row.code"
          :isChecked="row.code === defaultLanguage"
          @change="$emit('onDefaultChange', $event)"
        />
      </template>
    </table-column>
    <table-column
      v-if="canDelete"
      :sortable="false"
      label=""
      cell-class="text-grey-semi-light"
    >
      <template slot-scope="row">
        <img
          v-if="! row.isActive && ! row.isDefault"
          class="absolute pin-r pin-t z-10 py-5 mr-5 mt-0 cursor-pointer"
          src="@/assets/img/icons/delete.svg"
          alt="Delete"
          width="17"
          @click="$emit('delete', row.code)"
        />
      </template>
    </table-column>
  </table-component>
</template>

<script>
  export default {
    /**
     * Component name.
     *
     * @type {String}
     */
    name: 'LanguagesTable',

    /**
     * The passed properties.
     *
     * @type {Object}
     */
    props: {
      languages: {
        type: Array,
        default: () => {[]}
      },
      defaultLanguage: {
        type: String,
        default: ''
      },
      activeLanguages: {
        type: Array,
        default: () => {[]}
      },
      canDelete: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss">
  .languages-table {
    margin-top: 24px;
    margin-bottom: 24px;

    .table-component__table-wrapper {
      border: 1px solid #d1d1d1;
      border-radius: 4px;
    }

    input {
      min-height: 0;
    }

    table {
      border-spacing: 0;

      .table-component__table__head {


        .table-component__th {
          width: 170px;
          padding: 16px;
          font-weight: 400;
          border-bottom: 1px solid #ededed;
        }
      }

      .table-component__table__body {
        tr {
          td {
            width: auto;
            height: 58px;
            vertical-align: middle;
            padding: 16px;
            border: 0;
            font-weight: 400;
            color: #4a4a4a;

            &:first-child,
            &:last-child {
              border-radius: 0;
              border-left: 0;
              border-right: 0;
            }
          }

          &:nth-child(even) {
            td {
              background-color: transparent;
            }
          }
        }

        tr + tr {
          td {
            border-top: 1px solid #ededed;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .card .table-component__th:nth-child(3),
    .card .table-component__table__body tr td:last-child {
        display: table-cell;
    }
  }
</style>

import * as layouts from '@/layouts'

export default {
  install(Vue) {
    // Global layouts
    Object.keys(layouts).forEach(layout => {
      Vue.component(layout, layouts[layout])
    })
  }
}

import ApiBase from '@/api/ApiBase'

// Promo API Abstraction
export default class Mollie extends ApiBase {

  /**
   * Create payment intent
   * @param  {String}
   * @return {Promise}
   */
  getAuthLink() {
    return this.authRequest('GET', 'webhooks/mollie/connect/authorize')
  }

  saveAuth(companyId, code) {
    return this.authRequest('POST', 'webhooks/mollie/connect/create-token', {
      data: {
        code: code,
        companyId: companyId
      }
    })
  }

  pay(invoiceId) {
    return this.authRequest('POST', 'webhooks/mollie/connect/create-payment', {
      data: {
        invoiceId: invoiceId
      }
    } )
  }

  getProfiles() {
    return this.authRequest('GET', 'webhooks/mollie/connect/profiles')
  }

  getMethods(invoiceId) {
    return this.authRequest('GET', 'webhooks/mollie/connect/methods?invoiceId=' + invoiceId)
  }

  disconnect(data = {}) {
    return this.authRequest('POST', `webhooks/mollie/connect/disconnect`, { data } )
  }
}

<template>
  <footer
    v-show="!$route.meta.hideFooter"
    class="bg-white px-4"
  >
    <div class="flex px-4 mx-auto text-center items-center py-6 md:py-8 flex-wrap max-w-2xl ">
      <div
        class="w-full mb-3 "
        :class="[!isIntro ? 'md:hidden block' : 'hidden']"
      >
        <router-link
          class="text-grey-semi-light"
          to="/learning"
        >
          Learning Center
        </router-link> <span class="mx-2 text-grey-semi-light">&#8226;</span>
        <router-link
          class="text-grey-semi-light"
          to="/settings/plan-and-billing"
        >
          Plans & Billing
        </router-link>
        <span class="hidden md:inline mx-2 text-grey-semi-light">&#8226;</span>
        <a
          class="block md:inline mt-2 md:mt-0 text-grey-semi-light"
          href="https://octoa.com/updates"
          target="_blank"
        >
          Product updates
        </a>
      </div>
      <div class="w-full flex-wrap flex text-grey-semi-light items-end">
        <div class="w-full md:w-1/2 text-center md:text-left">
          <router-link
            to="/"
            class="block"
          >
            <img
              src="@/assets/img/octoa-blue.svg"
              width="80"
            >
          </router-link>
          <div class="mt-2 text-xs">
            <span class="text-grey-semi-light ">Made with</span> <span class="text-red mx-1 inline-block">❤️</span> <span class="text-grey-semi-light">in The Netherlands</span>
          </div>
        </div>
        <div
          v-if="isAuth"
          class="w-full md:w-1/2 mt-2 md:mt-0"
        >
          <div class="text-xs text-grey-semi-light w-full md:text-right">
            <div
              class="justify-end mb-1"
              :class="[!isIntro ? 'md:flex hidden' : 'hidden']"
            >
              <a
                class="text-grey-semi-light"
                href="https://intercom.help/octoa/en/"
              >
                Help Center
              </a> <span class="mx-2">&#8226;</span>
              <router-link
                class="text-grey-semi-light"
                to="/settings/plan-and-billing"
              >
                Plans & Billing
              </router-link><span class="mx-2 text-grey-semi-light">&#8226;</span>
              <a
                class="text-grey-semi-light"
                href="https://octoa.com/updates"
                target="_blank"
              >
                Product updates
              </a>
            </div>
            Copyright © {{ new Date().getFullYear() }} Octoa. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import auth from '@/auth'

export default {
	data(){
	    return {
        isAuth: auth.check(),
        isIntro: false,
	    }
	},
  watch:{
    $route (to, from){
      if(to.query.page == 'intro'){
        this.isIntro = true
      } else {
        this.isIntro = false
      }
    }
  },
  mounted(){
    if(this.$route.query.page == 'intro'){
      this.isIntro = true
    }
  },
  methods: {
    logout() {
      this.$toasted.clear()

      try {
        this.$store.dispatch('auth/logout')
        this.$router.replace({ name: 'Login' })
      } catch(e) {
        this.$toasted.global.api_error(e)
      }
    }
  }
}
</script>

import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import Model from "@ckeditor/ckeditor5-ui/src/model";
import Collection from "@ckeditor/ckeditor5-utils/src/collection";
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import {
  addListToDropdown,
  createDropdown
} from "@ckeditor/ckeditor5-ui/src/dropdown/utils";
import SplitButtonView from "@ckeditor/ckeditor5-ui/src/dropdown/button/splitbuttonview";

export default class InsertDropDown extends Plugin {
  init() {
    const editor = this.editor;
    editor.ui.componentFactory.add("InsertDropDown", locale => {

      const dropdownView = createDropdown(locale);
      dropdownView.buttonView.set({
        withText: true,
        label: "Type @ Token",
        tooltip: 'A token is a variable which will be replace by the actual content.'
      });

      const items = new Collection();

      items.add({
        type: "button",
        model: new Model({
          withText: true,
          label: "clientFirstName"
        })
      });

      items.add({
        type: "button",
        model: new Model({
          withText: true,
          label: "clientLastName"
        })
      });

      items.add({
        type: "button",
        model: new Model({
          withText: true,
          label: "clientEmail"
        })
      });

      items.add({
        type: "button",
        model: new Model({
          withText: true,
          label: "clientCompanyName"
        })
      });

      items.add({
        type: "button",
        model: new Model({
          withText: true,
          label: "clientAddress"
        })
      });

      items.add({
        type: "button",
        model: new Model({
          withText: true,
          label: "clientPostalCode"
        })
      });

      items.add({
        type: "button",
        model: new Model({
          withText: true,
          label: "clientCity"
        })
      });

      items.add({
        type: "button",
        model: new Model({
          withText: true,
          label: "clientCountry"
        })
      });

      items.add({
        type: "button",
        model: new Model({
          withText: true,
          label: "projectDate"
        })
      });

      items.add({
        type: "button",
        model: new Model({
          withText: true,
          label: "projectName"
        })
      });

      items.add({
        type: "button",
        model: new Model({
          withText: true,
          label: "documentLink"
        })
      });

      items.add({
        type: "button",
        model: new Model({
          withText: true,
          label: "clientPortal"
        })
      });

      items.add({
        type: "button",
        model: new Model({
          withText: true,
          label: "location"
        })
      });

      addListToDropdown(dropdownView, items);
      dropdownView.on('execute', (eventInfo) => {
        const { label } = eventInfo.source;
        const viewFragment = editor.data.processor.toView( '<span class="mention" data-mention="@'+label+'">@'+label+'</span>' );
        const modelFragment = editor.data.toModel( viewFragment );
        editor.model.insertContent(modelFragment);
      });

      return dropdownView;
    });


  }
}

<style lang="scss">
  .switch {
    cursor: pointer;
    display: inline-flex;
    color: #9AA8BD;
    align-items: center;

    &__option {
      width: 50px;
      height: 24px;
      margin:0 5px;
      border-radius: 14px;
      border:1px solid #E3E6EB;
      background-color: #fff;
      position: relative;
      opacity: .8;

      &:after {
        transition: all .3s ease-in-out;
        width: 20px;
        height: 20px;
        position: absolute;
        left: 2px;
        right: auto;
        top: 50%;
        margin-top: -10px;
        content: '';
        background-color: #796EFF;
        border-radius: 50%;
      }
    }

    &--enabled {
      .switch__option {
        &:after {
          left: auto;
          right: 2px;
        }
      }
    }
  }
</style>

<template>
  <div
    class="switch"
    :class="{'switch--enabled': enabled}"
    @click.prevent="toggle"
  >
    {{ leftOption }}
    <div class="switch__option" />
    {{ rightOption }}
  </div>
</template>

<script>
export default {
  name: 'VSwitch',
  inheritAttrs: false,
  props: {
    leftOption: {
      type: String,
      default: 'off'
    },
    rightOption: {
      type: String,
      default: 'on'
    },
    enabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggle() {
      this.$emit('update:enabled', !this.enabled)
    }
  }
}
</script>

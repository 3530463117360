import ApiBase from '@/api/ApiBase'

// Contacts API Abstraction
export default class Contacts extends ApiBase {

  /**
   * Search contacts
   *
   * @return {Promise}
   */
  search(text, options = {}) {
    return this.authRequest('GET', `contacts?search=${text}`, { ...options })
  }

  /**
   * Search contacts with auto suggest
   *
   * @return {Promise}
   */
  searchAutoSuggest(data, options = {}) {
    return this.authRequest('GET', 'contacts/search/auto-suggest', { data, ...options })
  }

  /**
   * Save contact
   *
   * @return {Promise}
   */
  save(data = {}) {
    return this.authRequest('POST', 'contacts', { data })
  }

  /**
   * Load specific contact
   *
   * @return {Promise}
   */
  load(id, options = {}) {
    return this.authRequest('GET', `contacts/${id}`, { ...options })
  }

  /**
   * Load list contacts
   *
   * @return {Promise}
   */
  list(withProjects, withoutProjects, filter, fieldName, order, options = {}) {
    return this.authRequest('GET', `contacts?directJSON=1&withProjects=${withProjects}&withoutProjects=${withoutProjects}&search=${filter}&sortFieldName=${fieldName}&sortOrder=${order}`, { ...options })
  }

  /**
   * Check duplicate email
   *
   * @return {Promise}
   */
  checkEmail(data = {}) {
    return this.authRequest('POST', 'contacts/email', { data })
  }

  /**
   * Delete contact
   *
   * @return {Promise}
   */
  delete(id) {
    return this.authRequest('DELETE', `contacts/${id}`)
  }
}

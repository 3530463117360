<template>
  <div
    class="col"
    :class="[colSize, ...responsive]"
  >
    <slot />
  </div>
</template>

<script>
import Fraction from 'fraction.js'

const gridSize = 12

const validator = value => value >= gridSize || value <= gridSize

export default {
  name: 'VCol',
  props: {
    size: {
      type: [Number, undefined],
      default: null,
      validator
    },
    md: {
      type: [Number, undefined],
      default: null,
      validator
    },
    lg: {
      type: [Number, undefined],
      default: null,
      validator
    },
    xl: {
      type: [Number, undefined],
      default: null,
      validator
    }
  },
  computed: {
    colSize() {
      return this.getCalculatedClass(this.size)
    },
    responsive() {
      return ['md', 'sm', 'lg', 'xl']
        .map(variant => {
          if (this[variant]) {
            return this.getCalculatedClass(this[variant], variant)
          }
        })
        .filter(Boolean)
    }
  },
  methods: {
    getCalculatedClass(size, variant = '') {
      const responsiveVariant = variant ? `${variant}:` : ''

      if (size >= gridSize) {
        return 'flex-full'
      }

      const fraction = new Fraction(size / gridSize).toFraction(true)

      return `${responsiveVariant}flex-${fraction}`
    }
  }
}
</script>

<template>
  <div class="image">
    <!--  <v-spinner
      :show="loading"
      class="image__loader"
    /> -->
    <img
      v-show="!loading"
      ref="image"
      :width="width"
      :alt="alt"
      class="image__el"
    >
  </div>
</template>
<script>
//import VSpinner from '@/components/VSpinner'

export default {
  name: 'VImage',
  components: {
    //VSpinner
  },
  props: {
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      isImageLoaded: false
    }
  },
  async mounted() {
    this.loading = true

    try {
      await this.load(this.src)

      setTimeout(() => {
        this.loading = false
      }, 0)

      setTimeout(() => {
        this.isImageLoaded = true
      }, 1000)
    } catch (e) {
      this.isImageLoaded = false
      this.loading = false
    }
  },
  methods: {
    load(src) {
      return new Promise((resolve, reject) => {
        const $image = this.$refs.image
        $image.src = src
        $image.onload = () => resolve($image)
        $image.onerror = () => reject(new Error())
      })
    }
  }
}
</script>
<style lang="scss">
.image {
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &__el {
    max-width: 100%;
    height: auto;
    border: 0;
  }

}
</style>

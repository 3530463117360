;['VUE_APP_FRONT_BASE_URL', 'VUE_APP_API_BASE_URL', 'APP_VERSION'].forEach(envKey => {
  if (!process.env[envKey]) {
    throw new Error(`Variable not defined ${envKey}`)
  }
})

export default {
  env: process.env.NODE_ENV,
  baseUrl: process.env.BASE_URL,
  appVersion: process.env.APP_VERSION,
  frontBaseUrl: process.env.VUE_APP_FRONT_BASE_URL,
  apiBaseUrl: process.env.VUE_APP_API_BASE_URL,
  apiTimeout: 30 * 1000,
  transactionId: Math.random().toString(36).substr(2, 9),
  sentryDsn: process.env.VUE_APP_SENTRY_DSN || '',
  sentryDebug: process.env.VUE_APP_SENTRY_DEBUG || false,
  googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID || ''
}

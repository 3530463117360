<style lang="scss" src="./style.scss"></style>

<template>
  <div class="relative">
    <div
      role="button"
      class="inline-block select-none"
      @click="toggleDropDown()"
    >
      <slot name="link" />
    </div>
    <div
      v-show="isDropdownOpen"
      class="cursor-pointer absolute mt-1"
      :class="[align == 'right' ? 'pin-r' : 'pin-l']"
      @click="closeDropDown()"
    >
      <slot name="dropdown" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VDropdown',
  props: {
    align: {
      type: String,
      default: 'right'
    },
  },
  data(){
      return {
        isDropdownOpen: false
      }
  },
  created() {
    let self = this
    window.addEventListener('click', function(e){
      // close dropdown when clicked outside
      if (!self.$el.contains(e.target)){
        self.isDropdownOpen = false
      }
    })
  },
  beforeDestroy() {
    let self = this
    window.addEventListener('click', function(e){
      // close dropdown when clicked outside
      if (!self.$el.contains(e.target)){
        self.isDropdownOpen = false
      }
    })
  },
  methods: {
    toggleDropDown(){
      this.isDropdownOpen = (this.isDropdownOpen) ? false : true
    },
    closeDropDown(){
      this.isDropdownOpen = false
    }
  }
}
</script>

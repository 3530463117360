import auth from '@/auth'

/**
 * Register http interceptors
 */
export const registerHttpInterceptors = (Vue, router) => {
  // Auth response interceptor
  Vue.http.interceptors.response.use(
    response => response,
    err => {
      if (
        err &&
        err.response &&
        err.response.data &&
        ((err.response.status === 401 && err.response.data.message === 'invalid_token' ) ||
        (err.response.status === 403 && (
            (Array.isArray(err.response.data) && err.response.data.includes('token_expired'))) ||
            (Array.isArray(err.response.data) && err.response.data.includes('token_invalid'))
        ))
      ) {
        Vue.alert.create({
          title: 'Session expired',
          text: 'Your session has expired. Please login again.'
        })

        auth.clearStorage()
        return router.replace({ name: 'Login' })
      }

      return Promise.reject(err)
    }
  )
}

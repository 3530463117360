import { AppLayout as Main } from '@/layouts'
import { createSubRoutes } from '@/router/helpers'
import { view } from '@/helpers'
import AuthMiddleware from '@/middleware/auth'

export default createSubRoutes('/', Main, [
  {
    path: '/invoices',
    name: 'InvoiceAll',
    component: view('Invoices/InvoiceAll')
  },
  {
    path: '/projects/:id/invoice/choose',
    name: 'InvoiceChoose',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
      hideFooter: true
    },
    component: view('Invoices/InvoiceChoose')
  },
  {
    path: '/invoice/choose',
    name: 'InvoiceChooseWithoutProject',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
      hideFooter: true
    },
    component: view('Invoices/InvoiceChoose')
  },
  {
    path: '/projects/:id/invoice/create',
    name: 'InvoiceCreate',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
      hideFooter: true
    },
    component: view('Invoices/InvoiceEdit')
  },
  {
    path: '/invoices/create',
    name: 'InvoiceCreateWithoutProject',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
      hideFooter: true
    },
    component: view('Invoices/InvoiceEdit')
  },
  {
    path: '/projects/:id/invoice/:invoiceId',
    name: 'InvoiceDetail',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
      hideFooter: true
    },
    component: view('Invoices/InvoiceEdit')
  },
  {
    path: '/invoice/:invoiceId',
    name: 'InvoiceWithoutProjectEdit',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
      hideFooter: true
    },
    component: view('Invoices/InvoiceEdit')
  },
  ,{
    path: '/invoices/preview/:invoiceId',
    name: 'InvoicePreview',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
      hideFooter: true
    },
    component: view('Invoices/InvoicePreview')
  }
])

import VueAutosuggest from 'vue-autosuggest'
import vueDebounce from 'vue-debounce'

export default {
  expose(Vue) {
    Vue.use(VueAutosuggest)
    Vue.use(vueDebounce)

  }
}

 <template>
  <ckeditor
      :editor="editor"
      :value="value"
      :config="editorConfigWithMention"
      @input="ev => $emit('input', ev)"
  />
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor"
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment.js';
import AutoImage from '@ckeditor/ckeditor5-image/src/autoimage.js';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat.js';
import Autolink from '@ckeditor/ckeditor5-link/src/autolink.js';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote.js';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold.js';
import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder.js';
import CKFinderUploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter.js';
import CloudServices from '@ckeditor/ckeditor5-cloud-services/src/cloudservices.js';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials.js';
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js';
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor.js';
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily.js';
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize.js';
import Heading from '@ckeditor/ckeditor5-heading/src/heading.js';
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight.js';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline.js';
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed.js';
import Image from '@ckeditor/ckeditor5-image/src/image.js';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption.js';
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert.js';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize.js';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle.js';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar.js';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload.js';
import Indent from '@ckeditor/ckeditor5-indent/src/indent.js';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic.js';
import Link from '@ckeditor/ckeditor5-link/src/link.js';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage.js';
import List from '@ckeditor/ckeditor5-list/src/list.js';
//import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle.js';
// import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed.js';
// import MediaEmbedToolbar from '@ckeditor/ckeditor5-media-embed/src/mediaembedtoolbar.js';
import Mention from '@ckeditor/ckeditor5-mention/src/mention.js';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat.js';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough.js';
import Table from '@ckeditor/ckeditor5-table/src/table.js';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar.js';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation.js';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline.js';
import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount.js';
import InsertDropDown from '@/utils/ckeditor/dropdown'
import ImageViaUrlEmbed from '@/utils/ckeditor/imageviaurlembed'

export default {
  name: 'VCkeditorToken',
  props: {
    value: {
      type: String,
      default: ''
    },
    allowToken: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: this.content,
      editorConfigWithMention: {
        plugins: [
          Alignment,
          AutoImage,
          Autoformat,
          Autolink,
          BlockQuote,
          Bold,
          // CKFinder,
          // CKFinderUploadAdapter,
          CloudServices,
          Essentials,
          FontBackgroundColor,
          FontColor,
          FontFamily,
          FontSize,
          Heading,
          Highlight,
          HorizontalLine,
          HtmlEmbed,
          Image,
          //ImageCaption,
          ImageInsert,
          // ImageResize,
          ImageStyle,
          ImageToolbar,
          //ImageUpload,
          Indent,
          Italic,
          Link,
          LinkImage,
          List,
          //ListStyle,
          // MediaEmbed,
          // MediaEmbedToolbar,
          Mention,
          Paragraph,
          PasteFromOffice,
          RemoveFormat,
          Strikethrough,
          Table,
          TableCellProperties,
          TableProperties,
          TableToolbar,
          TextTransformation,
          Underline,
          WordCount,
          ImageViaUrlEmbed,
          InsertDropDown,
        ],
        link: {
          defaultProtocol: 'https://',
        },
        mention: {
            feeds: [
                {
                    marker: '@',
                    feed: [ '@clientFirstName', '@clientLastName', '@clientEmail', '@clientAddress', '@clientPostalCode',
                      '@clientCity', '@clientCountry', '@clientCompanyName', '@projectDate', '@documentLink',
                      '@projectName', '@location', '@clientPortal' ],
                    minimumCharacters: 0
                }
            ]
        },
        toolbar: {
          items: [
            //'heading',
            //'|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'fontSize',
            'fontColor',
            'link',
            'bulletedList',
            'numberedList',
            // '|',
            // 'outdent',
            // 'indent',
            '|',
            'ImageViaUrlEmbed',
            //'blockQuote',
            'insertTable',
            // 'undo',
            // 'redo',
            'InsertDropDown',
          ]
        },
        language: 'en',
        fontSize: {
          options: [
            8,
            10,
            12,
            'default',
            16,
            18,
            21
          ]
        },
        image: {
          toolbar: [
            'linkImage',
            //'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
          ],
          // styles: [
          //   'full',
          //   'alignLeft',
          //   'alignCenter',
          //   'alignRight',
          // ]
        },
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableCellProperties',
            'tableProperties'
          ]
        },
      },
    }
  },
}
</script>
<style lang="scss">

</style>


import ApiBase from '@/api/ApiBase'

// Timezone API Abstraction
export default class Timezone extends ApiBase {
  /**
   * Email
   *
   * @param {String} company
   * @param  {Object} data
   *
   * @return {Promise}
   */
  all(data = {}) {
    return this.authRequest('GET', 'timezone', { data } )
  }
}

<template>
  <div>
    <modal
      :name="name"
      class="modal"
      classes="text-center"
      width="100%"
      :max-width="videoModalWidth"
      height="auto"
      :adaptive="true"
      :scrollable="true"
      @closed="modalClosed"
    >
      <div class="flex items-center h-full">
        <div class="-mt-20 md:mt-0  w-full">
          <div v-show="!isVideoPlaying">
            <div class="title">
              {{ title }}
            </div>
            <p class="mb-8 text-base">
              {{ description }}
            </p>
          </div>

          <div class="mx-auto cursor-pointer w-10/12">
            <img
              v-show="!isVideoPlaying"
              :src="require(`@/assets/img/video-thumbnails/${thumbnail}`)"
              class="w-full"
              @click="playIntroVideo"
            >
          </div>
          <div class="mx-auto cursor-pointer w-full">
            <vue-plyr
              v-show="isVideoPlaying"
              ref="plyr"
            >
              <div
                data-plyr-provider="youtube"
                :data-plyr-embed-id="embed"
              />
            </vue-plyr>
          </div>
          <div class="mx-auto cursor-pointer w-10/12">
            <div
              v-show="!isVideoPlaying"
              class="mx-auto mt-3"
              @click="playIntroVideo"
            >
              <div class="green-btn button">
                Play video
              </div>
            </div>
          </div>
          <div
            class="mt-6 cursor-pointer underline"
            @click="skipIntroVideo"
          >
            <span v-if="!isVideoPlaying">No thanks, I'll explore it myself.</span>
            <span v-else>Thanks, I am ready to explore.</span>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import auth from '@/auth'
import { logException } from '@/helpers'

export default {
  props: {
    name: {
      type: String,
      default: 'videoModal'
    },
    show: {
      type: Boolean,
      default: false
    },
    step: {
      type: String,
      default: ''
    },
    thumbnail: {
      type: String,
      default: ''
    },
    embed: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  },
  data(){
    return {
      loading: false,
      isVideoPlaying: false,
      videoModalWidth: 500,
      user: auth.user(),
    }
  },
  computed: {
    player () {
      return this.$refs.plyr.player
    }
  },
  watch: {
    'show': {
      handler(newVal, oldVal) {
        if(newVal){
          this.$modal.show(this.name)
        }
      },
      deep: true
    }
  },
  async mounted(){

  },
  methods: {
    playIntroVideo(){
      this.videoModalWidth =  window.innerWidth/1.8
      this.isVideoPlaying = true
      this.player.play()
    },
    async skipIntroVideo(){
      try {
        const { data } = await this.$api.get('introVideo').finish(this.user.company.id, {step: this.step})
        this.$modal.hide(this.name)
        this.$emit('close')
        auth.refreshUser()
      } catch(e){
        logException(e)
      }
    },
    cancel(){
      this.$modal.hide(this.name)
      this.$emit('close')
    },
    async save(){

    },
    async modalClosed(){
      await this.skipIntroVideo()
    }
  }
}
</script>
<style lang="scss">
.plyr__video-embed iframe {
  top: -50%!important;
  height: 200%!important;
}
</style>

import ApiBase from '@/api/ApiBase'

// TemplateContract API Abstraction
export default class TemplateContract extends ApiBase {

  /**
   * List
   * @param  {String}
   * @return {Promise}
   */
  list(company, options = {}) {
    return this.authRequest('GET', `company/${company}/templates/contracts`, options)
  }

  /**
   * Update / Create
   * @param  {String}
   * @return {Promise}
   */
  updateOrCreate(data = {}, options = {}) {
    return this.authRequest('POST', 'templates/contracts', {data, options})
  }

  /**
   * Duplicate
   * @param  {String}
   * @return {Promise}
   */
  duplicate(company, id, options = {}) {
    return this.authRequest('POST', `company/${company}/templates/contracts/${id}/duplicate`, options)
  }

  /**
   * Delete
   * @param  {String}
   * @return {Promise}
   */
  delete(id, options = {}) {
    return this.authRequest('DELETE', `templates/contracts/${id}`, options)
  }

  /**
   * Get detail
   * @param  {String}
   * @return {Promise}
   */
  detail(company, id, options = {}) {
    return this.authRequest('GET', `company/${company}/templates/contracts/${id}/detail`, options)
  }
}

import ApiBase from '@/api/ApiBase'
import { client } from './VOClient'

// Invoice API Abstraction
export default class Mail extends ApiBase {

  /**
   * Send
   * @param  {String}
   * @return {Promise}
   */
  send(data = {}, options = {}) {
    return this.authRequest('POST', 'mails/send', {data, options})
  }

  /**
   * Save as draft
   * @param  {String}
   * @return {Promise}
   */
  saveAsDraft(data = {}, options = {}) {
    return this.authRequest('POST', 'mails/draft', {data, options})
  }

  /**
   * Load draft
   * @param  {String}
   * @return {Promise}
   */
  loadDraft(id, type, options = {}) {
    return this.authRequest('GET', `mails/draft/${type}/${id}`, { options })
  }

  /**
   * Load log
   * @param  {String}
   * @return {Promise}
   */
  loadLog(id, type, options = {}) {
    return this.authRequest('GET', `mails/log/${type}/${id}`, { options })
  }

  /**
   * Check SMTP connection
   * @param  {String}
   * @return {Promise}
   */
  smtpCheck(data, options = {}) {
    return this.authRequest('POST', `mails/smtp/check`, {data, options })
  }

  /**
   * Send SMTP test mail
   * @param  {String}
   * @return {Promise}
   */
  smtpTest(data, options = {}) {
    return this.authRequest('POST', `mails/smtp/test`, {data, options })
  }
}

/**
 *
 * @param {Object} data
 * @param {String} data.to
 * @param {String} data.from
 * @param {String} data.senderName
 * @param {String} data.subject
 * @param {String} data.content
 * @param {String} data.type
 * @param {Number} data.itemId
 * @param {Object} data.company
 * @returns {Promise<[*,null]|[*,(*|[*]|[string])]|undefined>}
 */
export const sendMail = (data) => {

  return client.post('mails/send', data)
}

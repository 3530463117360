import auth from '@/auth'

export default (to, from, next) => {

  // If the user is authenticated and is trying to access
  // an unprotected url, redirect to home.

  // if (auth.check() && auth.user().company) {
  //   return next({ name: 'Projects' })
  // }
  // if (auth.check() && auth.user().company === undefined) {
  //   return next({ name: 'RegisterCompany' })
  // }

  return next()
}

import { AppLayout as Main } from '@/layouts'
import { createSubRoutes } from '@/router/helpers'
import { view } from '@/helpers'

export default createSubRoutes('/', Main, [
  {
    path: '/projects',
    name: 'Projects',
    component: view('Projects')
  },
  {
    path: '/projects/:id',
    name: 'ProjectDetail',
    component: view('ProjectDetail')
  },
])

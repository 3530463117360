import English from '@/plugins/translations/en.json'
import Dutch from '@/plugins/translations/nl.json'
import German from '@/plugins/translations/de.json'
import Italian from '@/plugins/translations/it.json'
import Spanish from '@/plugins/translations/es.json'
import French from '@/plugins/translations/fr.json'
import Finnish from '@/plugins/translations/fi.json'
import Danish from '@/plugins/translations/da.json'
import Norwegian from '@/plugins/translations/no.json'
import Portuguese from '@/plugins/translations/pt.json'
import Swedish from '@/plugins/translations/sv.json'

export const translate = (str, locale) => {
    let translation = ''
    switch(locale) {
      case 'da':
        Object.entries(Danish).map(function (value) {
          if(value[0] == str){
            translation = value[1]
          }
        })
        break
        case 'no':
        Object.entries(Norwegian).map(function (value) {
          if(value[0] == str){
            translation = value[1]
          }
        })
        break
        case 'pt':
        Object.entries(Portuguese).map(function (value) {
          if(value[0] == str){
            translation = value[1]
          }
        })
        break
        case 'sv':
        Object.entries(Swedish).map(function (value) {
          if(value[0] == str){
            translation = value[1]
          }
        })
        break
      case 'nl':
        Object.entries(Dutch).map(function (value) {
          if(value[0] == str){
            translation = value[1]
          }
        })
        break
      case 'de':
        Object.entries(German).map(function (value) {
          if(value[0] == str){
            translation = value[1]
          }
        })
        break
      case 'it':
        Object.entries(Italian).map(function (value) {
          if(value[0] == str){
            translation = value[1]
          }
        })
        break
      case 'es':
        Object.entries(Spanish).map(function (value) {
          if(value[0] == str){
            translation = value[1]
          }
        })
        break
      case 'fr':
        Object.entries(French).map(function (value) {
          if(value[0] == str){
            translation = value[1]
          }
        })
        break
      case 'fi':
        Object.entries(Finnish).map(function (value) {
          if(value[0] == str){
            translation = value[1]
          }
        })
        break
      default:
        Object.entries(English).map(function (value) {
          if(value[0] == str){
            translation = value[1]
          }
        })
    }
    return translation
  }

import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import config from '@/config'
import company from '@/store/company'
import auth from '@/store/auth'
import template from '@/store/template'

const appVersion = config.appVersion

export const createStore = () => {
  return new Vuex.Store({
    plugins: [createLogger()],
    state: {
      hasNewUpdate: false,
      appVersion
    },
    mutations: {
      SET_APP_HAS_NEW_UDPATE(state, hasUpdate) {
        state.hasNewUpdate = hasUpdate
      }
    },
    actions: {
      setHasNewUpdate({ commit }, hasUpdate) {
        commit('SET_APP_HAS_NEW_UDPATE', hasUpdate)
      }
    },
    modules: {
      company,
      auth,
      template,
    }
  })
}

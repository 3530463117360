import { Extension } from 'tiptap'
import { chainCommands, newlineInCode, createParagraphNear, liftEmptyBlock, splitBlockKeepMarks } from 'tiptap-commands'

export default class Enter extends Extension {

  keys() {
    return {
      Enter(state, dispatch, view) {

        chainCommands(newlineInCode, createParagraphNear, liftEmptyBlock, splitBlockKeepMarks)(state, dispatch, view)
        return true
      }
    }
  }

}

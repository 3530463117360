import VeeValidate, {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate'
// import custom from "@/i18n/pt-BR/validator";

export default {
  expose(Vue) {
    Vue.use(VeeValidate, {
      // locale: "pt-BR",
      inject: true
      // dictionary: {
      //   "pt-BR": { custom }
      // }
    })

    // Install componentes
    Vue.component('ValidationProvider', ValidationProvider)
    Vue.component('ValidationObserver', ValidationObserver)
  }
}

import ApiBase from '@/api/ApiBase'

// Pdf API Abstraction
export default class Pdf extends ApiBase {

  /**
   * Single invoice Pdf
   * @param  {String}
   * @return {Promise}
   */
  invoice(data = {}, options = {}) {
    if(!data.isSafari && !data.isMobile){
      return this.request('POST', 'view/pdf/invoice', { data, responseType: 'arraybuffer' } )
    } else {
      return this.request('POST', 'view/pdf/invoice', { data, options } )
    }
  }

  /**
   * Single proposal Pdf
   * @param  {String}
   * @return {Promise}
   */
  proposal(data = {}, options = {}) {
    if(!data.isSafari && !data.isMobile){
      return this.request('POST', 'view/pdf/proposal', { data, responseType: 'arraybuffer' } )
    } else {
      return this.request('POST', 'view/pdf/proposal', { data, options } )
    }
  }
}

import { AppLayout as Main } from '@/layouts'
import { createSubRoutes } from '@/router/helpers'
import { view } from '@/helpers'

export default createSubRoutes('/', Main, [
  {
    path: '/tasks',
    name: 'Tasks',
    component: view('Tasks')
  }
])

import Router from 'vue-router'
import routes from '@/router/routes'
import config from '@/config'
import auth from '@/auth'
import { nextFactory } from '@/router/helpers'
import { addDays, isBefore, differenceInDays } from 'date-fns'

export const createRouter = () => {
  const router = new Router({
    mode: 'history',
    base: config.baseUrl,
    routes,
    scrollBehavior (to, from, savedPosition) {
      return { x: 0, y: 0 }
    }
  })

  function patchRouterMethod (router, methodName)
  {
      router['old' + methodName] = router[methodName]
      router[methodName] = async function (location)
      {
          return router['old' + methodName](location).catch((error) =>
          {
              if (error.name === 'NavigationDuplicated')
              {
                  return this.currentRoute
              }
              throw error
          })
      }
  }

  patchRouterMethod(router, 'push')
  patchRouterMethod(router, 'replace')

  router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
      const middleware = Array.isArray(to.meta.middleware)
        ? to.meta.middleware
        : [to.meta.middleware]

      // eslint-disable-next-line
      const factory = nextFactory({ from, next, to, router }, middleware, 1);

      //Dynamic check auth if trial / subscription
      if(auth.check()){
        auth.refreshUser()
        let user = auth.user()

        if(user.company){
          let trialDaysLeft = differenceInDays(
            user.company.trial_end_at,
            new Date()
          )

          if(!user.company.is_subscription_active && trialDaysLeft < 1){
            router.push('/start')
          }

          if(user.company.is_subscription_active && user.company.subscription_end_at && !isBefore(new Date(), user.company.subscription_end_at)){
            router.push('/reactivate')
          }
        }
      }

      return middleware[0](to, from, factory)
    }
    return next()
  })

  return router
}

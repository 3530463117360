import { AppLayout as Main } from '@/layouts'
import { createSubRoutes } from '@/router/helpers'
import { view } from '@/helpers'

export default createSubRoutes('/', Main, [
  {
    path: '/',
    name: 'Dashboard',
    component: view('Dashboard')
  },
])

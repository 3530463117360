<template>
  <div
    :class="['bg-white rounded border', border]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VCard',
  props: {
    overwritePadding: {
      type: Boolean,
      default: false
    },
    border: {
      type: String,
      default: 'border-grey-lighter'
    }
  }
}
</script>

<style scoped>

</style>

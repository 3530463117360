import VCalendar from 'v-calendar'

export default {
  expose(Vue) {
    Vue.use(VCalendar, {
      popoverAlign: 'left',
      datePickerTintColor: '#006fff',
      datePickerShowDayPopover: false,
      firstDayOfWeek: 2,  // Monday
    })
    Vue.options.components.vDatePicker.options.components.DatePicker.options.props.attributes = {
      type: Array,
      default: function () {
        return [{
          dot: {
            color: 'red',
            class: 'my-dot-class',
          },
          dates: new Date()
        }]
      }
    }
  }
}

import swal from 'sweetalert'

class Alert {
  /**
   * Create new alert
   *
   * @param  {Object} options
   */
  create(options = {}) {
    return swal({ ...options })
  }

  /**
   * Show error message
   *
   * @param  {Object} options
   */
  error(options = {}) {
    return this.create({
      icon: 'error',
      button: 'Close',
      ...options
    })
  }

  /**
   * Confirm warning
   * @param  {Object} options
   */
  confirm(options = {}) {
    return this.create({
      icon: require('@/assets/img/icons/ico-warning.svg'),
      dangerMode: true,
      buttons: ['No', 'Yes'],
      ...options
    })
  }

  /**
   * Alert warning
   * @param  {Object} options
   */
  alert(options = {}) {
    return this.create({
      icon: require('@/assets/img/icons/ico-warning.svg'),
      dangerMode: true,
      cancel: {
        text: 'Cancel',
        value: null,
        visible: false,
        className: '',
        closeModal: true,
      },
      confirm: {
        text: 'Check',
        value: true,
        visible: true,
        className: '',
        closeModal: true
      },
      ...options
    })
  }
}

export default new Alert()

import ApiBase from '@/api/ApiBase'

// Invoice API Abstraction
export default class Invoice extends ApiBase {

   /**
   * Create test invoice
   * @param  {String}
   * @return {Promise}
   */
  createTestInvoice(company, data = {}, options = {}) {
    return this.authRequest(
      'GET',
      `${company}/invoices/create/sample`)
  }

  /**
   * Count invoices
   *
   * @return {Promise}
   */
  count(companyId, options = {}) {
    return this.authRequest('GET', `${companyId}/invoices/count`, { ...options })
  }

  /**
   * Overview
   * @param  {String}
   * @return {Promise}
   */
  overview(data = {}, options = {}) {
    return this.authRequest(
      'GET',
      `${data.companyId}/invoices/overview?companyId=${data.companyId}&query=${data.query}&filter=${data.filter}&limit=${data.limit}&page=${data.page}&orderBy=${data.orderBy}&ascending=${data.ascending}&byColumn=${data.byColumn}`)
  }

  /**
   * Get
   * @param  {String}
   * @return {Promise}
   */
  list(data = {}, options = {}) {
    return this.authRequest('POST', 'invoices', {data, options})
  }

  /**
   * Update / Create
   * @param  {String}
   * @return {Promise}
   */
  save(data = {}, options = {}) {
    return this.authRequest('POST', 'invoices', {data, options})
  }

  /**
   * Get detail
   * @param  {String}
   * @return {Promise}
   */
  detail(target, type, options = {}) {
    return this.authRequest('GET', `invoices/${target}/detail?type=${type}`, options)
  }

  /**
   * Delete
   * @param  {Object}
   * @return {Promise}
   */
  delete(data = {}, options = {}) {
    return this.authRequest('DELETE', 'invoices', { data, options })
  }


  /**
   * Add payment
   * @param  {String}
   * @return {Promise}
   */
  addPayment(target, data = {}, options = {}) {
    return this.authRequest('POST', `invoices/${target}/payment`, {data, options})
  }

  /**
   * View for public
   * @param  {String}
   * @return {Promise}
   */
  view(target, options = {}) {
    return this.authRequest('GET', `view/invoices/${target}`, options)
  }

   /**
   * Patch state
   * @param  {String}
   * @return {Promise}
   */
  updateState(target, state, options = {}) {
    return this.authRequest('PATCH', `invoices/${target}/${state}`,  options)
  }


  /**
   * Get last invoice number
   * @param  {String}
   * @return {Promise}
   */
  lastInvoiceNumber(options = {}) {
    return this.authRequest('GET', 'invoices/all/last-invoice-number', options)
  }

  /**
   * Check if invoice number already exist
   * @param  {String}
   * @return {Promise}
   */
  checkDuplicateInvoiceNumber(data = {}, options = {}) {
    return this.authRequest('POST', 'invoices/all/check-duplicate-invoice-number', {data, options})
  }

  /**
   * Export to Moneybird
   * @param  {String}
   * @return {Promise}
   */
  exportMoneybird(company, data = {}, options = {}) {
    return this.authRequest(
      'POST',
      `${company}/invoices/export/moneybird` , {data, options})
  }

}

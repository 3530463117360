import Register from './Register'
import Company from './Company'
import Health from './Health'
import Projects from './Projects'
import User from './User'
import Tags from './Tags'
import Contacts from './Contacts'
import Tasks from './Tasks'
import Settings from './Settings'
import Currency from './Currency'
import LeadForm from './LeadForm'
import TemplateMail from './TemplateMail'
import TemplateProduct from './TemplateProduct'
import TemplateContract from './TemplateContract'
import TemplateInvoice from './TemplateInvoice'
import TemplateProposal from './TemplateProposal'
import Invoice from './Invoice'
import Proposal from './Proposal'
import Mail from './Mail'
import Lead from './Lead'
import OAuth from './OAuth'
import Subscription from './Subscription'
import Billing from './Billing'
import Timezone from './Timezone'
import IntroVideo from './IntroVideo'
import Country from './Country'
import Referral from './Referral'
import Pdf from './Pdf'
import Promo from './Promo'
import Stripe from './Stripe'
import DiscountCode from './DiscountCode'
import Dashboard from './Dashboard'
import Calendar from './Calendar'
import Moneybird from './Moneybird'
import Pictime from './Pictime'
import GoogleCalendar from './GoogleCalendar'
import Questionnaire from './Questionnaire'
import TemplateQuestionnaire from './TemplateQuestionnaire'
import Mollie from './Mollie'

export default new Map()
  .set('register', new Register())
  .set('company', new Company())
  .set('health', new Health())
  .set('projects', new Projects())
  .set('user', new User())
  .set('tags', new Tags())
  .set('contacts', new Contacts())
  .set('tasks', new Tasks())
  .set('settings', new Settings())
  .set('currency', new Currency())
  .set('leadform', new LeadForm())
  .set('templateMail', new TemplateMail())
  .set('templateProduct', new TemplateProduct())
  .set('templateContract', new TemplateContract())
  .set('templateInvoice', new TemplateInvoice())
  .set('templateProposal', new TemplateProposal())
  .set('invoice', new Invoice())
  .set('proposal', new Proposal())
  .set('mail', new Mail())
  .set('lead', new Lead())
  .set('oauth', new OAuth())
  .set('subscription', new Subscription())
  .set('billing', new Billing())
  .set('timezone', new Timezone())
  .set('introVideo', new IntroVideo())
  .set('country', new Country())
  .set('referral', new Referral())
  .set('pdf', new Pdf())
  .set('promo', new Promo())
  .set('stripe', new Stripe())
  .set('mollie', new Mollie())
  .set('discountCode', new DiscountCode())
  .set('dashboard', new Dashboard())
  .set('calendar', new Calendar())
  .set('moneybird', new Moneybird())
  .set('pictime', new Pictime())
  .set('googleCalendar', new GoogleCalendar())
  .set('questionnaire', new Questionnaire())
  .set('templateQuestionnaire', new TemplateQuestionnaire())


import Auth from './auth'
import storage from '@/storage'
import httpClient from '@/http'

const authInstance = new Auth({
  httpClient,
  storage
})

export default authInstance

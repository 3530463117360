import * as components from '@/components'

export default {
  install(Vue) {
    // Global components
    Object.keys(components).forEach(component => {
      Vue.component(component, components[component])
    })
  }
}

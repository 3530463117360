import ApiBase from '@/api/ApiBase'

// Promo API Abstraction
export default class Calendar extends ApiBase {

  view(company, viewBooked, data = {}) {
    return this.authRequest('GET', `${company}/calendar/view?viewBooked=${viewBooked}`, { data } )
  }

}

<template>
  <font-awesome-icon
    v-if="props.sortable"
    :icon="icon"
    class="ml-1"
  />
</template>

<script>
  import { library } from '@fortawesome/fontawesome-svg-core'
  import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

  library.add(faSort)
  library.add(faSortUp)
  library.add(faSortDown)
    export default {
        name: 'VtSortControl',
        components:{
          FontAwesomeIcon: FontAwesomeIcon
        },
        props: ['props'],
        computed: {
            icon() {
                // sortStatus = { sorted: Boolean, asc: Boolean }

                // if not sorted return base icon
                if (!this.props.sortStatus.sorted) return 'sort'

                // return sort direction icon
                return this.props.sortStatus.asc ? 'sort-up' : 'sort-down'
            }
        }
    }
</script>

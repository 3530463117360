import ApiBase from '@/api/ApiBase'

// TemplateProduct API Abstraction
export default class TemplateProduct extends ApiBase {

  /**
   * Check product name
   * @param  {Object}
   * @return {Promise}
   */
  checkProductName(data = {}, options = {}) {
    return this.authRequest('POST', 'templates/products/name', {data, options})
  }

  /**
   * Search
   * @param  {Object}
   * @return {Promise}
   */
  search(data = {}, options = {}) {
    return this.authRequest('POST', 'templates/products/search', {data, options})
  }

  /**
   * List
   * @param  {String}
   * @return {Promise}
   */
  list(company, options = {}) {
    return this.authRequest('GET', `company/${company}/templates/products`, options)
  }

  /**
   * Update / Create
   * @param  {Object}
   * @return {Promise}
   */
  updateOrCreate(data = {}, options = {}) {
    return this.authRequest('POST', 'templates/products', {data, options})
  }

  /**
   * Duplicate
   * @param  {String}
   * @return {Promise}
   */
  duplicate(company, id, options = {}) {
    return this.authRequest('POST', `company/${company}/templates/products/${id}/duplicate`, options)
  }

  /**
   * Delete
   * @param  {String}
   * @return {Promise}
   */
  delete(id, options = {}) {
    return this.authRequest('DELETE', `templates/products/${id}`, options)
  }

  /**
   * Get detail
   * @param  {String}
   * @param  {String}
   * @return {Promise}
   */
  detail(company, id, options = {}) {
    return this.authRequest('GET', `company/${company}/templates/products/${id}/detail`, options)
  }
}

import ApiBase from '@/api/ApiBase'

// Tags API Abstraction
export default class Tags extends ApiBase {
  /**
   * Search tags
   *
   * @return {Promise}
   */
  search(text, options = {}) {
    return this.authRequest('GET', `tags?search=${text}`, { ...options })
  }

}

import { applyMiddleware } from '@/middleware'

// Create app routes, auto-add auth middleware
export const createSubRoutes = (path = '/', mainComponent, children = []) => ({
  path,
  component: mainComponent,
  children: children.map(route => {
    return {
      ...applyMiddleware('auth'),
      ...route
    }
  })
})

// Middleware generator
export const nextFactory = (context, middleware, index) => {
  const subsequentMiddleware = middleware[index]

  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters)
    // Then run the subsequent Middleware with a next `nextMiddleware()` callback.
    let nextMiddleware = nextFactory(context, middleware, index + 1)
    return subsequentMiddleware(context.to, context.from, nextMiddleware)
  }
}

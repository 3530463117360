import Toasted from 'vue-toasted'
import { handleLaravelErrors } from '@/helpers'

export default {
  expose(Vue) {
    Vue.use(Toasted, {
      theme: 'bubble',
      position: 'bottom-center',
      duration: 30 * 500,
      containerClass: 'toasted',
    })

    // Success
    let successOptions = {
        type : 'success',
        iconPack: 'callback',
        icon: (el) => {
            el.innerHTML = '<div class="mr-2"><svg width="18px" height="15px" viewBox="0 0 18 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="04---Desktop-Pages" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="12.1---New-Invoice" transform="translate(-1238.000000, -1891.000000)" fill="#FFFFFF" fill-rule="nonzero"><g id="check" transform="translate(1238.000000, 1891.000000)"><path d="M17.3595,2.55 L15.2655,0.45 C15.1951468,0.379468724 15.0996204,0.339829662 15,0.339829662 C14.9003796,0.339829662 14.8048532,0.379468724 14.7345,0.45 L5.8905,9.3 C5.8201468,9.37053128 5.72462045,9.41017034 5.625,9.41017034 C5.52537955,9.41017034 5.4298532,9.37053128 5.3595,9.3 L3.2655,7.2 C3.1951468,7.12946872 3.09962045,7.08982966 3,7.08982966 C2.90037955,7.08982966 2.8048532,7.12946872 2.7345,7.2 L0.6405,9.3 C0.494106773,9.44643744 0.494106773,9.68381256 0.6405,9.83025 L5.3595,14.55 C5.4298532,14.6205313 5.52537955,14.6601703 5.625,14.6601703 C5.72462045,14.6601703 5.8201468,14.6205313 5.8905,14.55 L17.3595,3.075 C17.5024286,2.92918368 17.5024286,2.69581632 17.3595,2.55 Z" id="Path"></path></g></g></g></svg></div>'
            return el
        }
    }

    Vue.toasted.register('general_success', (payload) => {
        // if there is no message passed show default message
        if(! payload.message) {
          return 'Success!'
        }

        // if there is a message show it with the message
        return payload.message
      },
      successOptions
    )

    Vue.toasted.register('general_success', (payload) => {
        // if there is no message passed show default message
        if(! payload.message) {
          return 'Success!'
        }

        // if there is a message show it with the message
        return payload.message
      },
      successOptions
    )

    // Errors
    let errorOptions = {
        type : 'error',
        iconPack: 'callback',
        icon: (el) => {
            el.innerHTML = '<div class="mr-2"><svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="04---Desktop-Pages" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="12.1---New-Invoice" transform="translate(-1129.000000, -1801.000000)" fill="#FFFFFF" fill-rule="nonzero"><g id="alert-circle" transform="translate(1129.000000, 1801.000000)"><path d="M8.98725,-6.67001177e-16 C6.58245208,0.0393692133 4.28974586,1.02353558 2.60475,2.73975 C0.901248811,4.43575534 -0.039064572,6.75174748 1.31622144e-15,9.15525 C-0.00279391719,11.5027891 0.928884931,13.7549059 2.58933934,15.4143753 C4.24979375,17.0738447 6.502463,18.0041869 8.85,18.0000141 L9.0105,18.0000141 C14.019174,17.9484679 18.0407965,13.8520229 18,8.84325 L18,8.84325 C18.0055908,6.46661244 17.0522947,4.1881922 15.3558772,2.52367873 C13.6594597,0.859165258 11.3633531,-0.0507067015 8.98725,-6.67001177e-16 Z M7.875,12.4065 C7.86407829,12.1094351 7.97309113,11.8204602 8.17749875,11.604626 C8.38190637,11.3887919 8.66453052,11.2642371 8.96175,11.259 L8.982,11.259 C9.59701564,11.2601825 10.1012937,11.7469118 10.12425,12.3615 C10.1353795,12.6586211 10.0264361,12.9477144 9.82198456,13.1635949 C9.61753302,13.3794754 9.33478689,13.5039673 9.0375,13.509 L9.01725,13.509 C8.40254732,13.5070455 7.89871674,13.0207481 7.875,12.4065 Z M8.25,9.375 L8.25,4.875 C8.25,4.46078644 8.58578644,4.125 9,4.125 C9.41421356,4.125 9.75,4.46078644 9.75,4.875 L9.75,9.375 C9.75,9.78921356 9.41421356,10.125 9,10.125 C8.58578644,10.125 8.25,9.78921356 8.25,9.375 Z" id="Shape"></path></g></g></g></svg></div>'
            return el
        }
    }

    Vue.toasted.register('general_error', (payload) => {
        // if there is no message passed show default message
        if(! payload.message || payload.message == 'Request failed with status code 500') {
          return 'Oops.. Something Went Wrong..'
        }

        // if there is a message show it with the message
        return payload.message
      },
      errorOptions
    )

    Vue.toasted.register('api_error', (payload) => {
        // if there is no message passed show default message
        if(! payload.message || payload.message == 'Request failed with status code 500') {
          return 'Oops.. Something Went Wrong..'
        }

        // if there is a message show it with the message
        return payload.message
      },
      errorOptions
    )

    Vue.prototype.$toasted = Vue.toasted
  }
}

/**
 * Storage abstraction
 */
import Store from 'store'

export class Storage {
  /**
   * Set new value into the storage
   *
   * @param {string} key
   * @param {mixed} value
   */
  set(key, value) {
    return Store.set(key, value)
  }

  /**
   * Get value from storage
   *
   * @param  {string} key
   * @return {mixed}
   */
  get(key) {
    return Store.get(key)
  }

  /**
   * Check if value exists in the storage
   *
   * @param  {String}  key
   * @return {Boolean}
   */
  has(key) {
    const value = this.get(key)

    if (!value) {
      return false
    }

    return String(value).trim() !== ''
  }

  /**
   * Remove item from storage
   *
   * @param  {String} key
   */
  remove(key) {
    Store.remove(key)
  }

  /**
   * Clear storage
   */
  clear() {
    Store.clearAll()
  }

  /**
   * Get all values from storage
   *
   * @return {Array}
   */
  all() {
    const all = []
    Store.each((key, value) => all.push({ [key]: value }))
    return all
  }
}

export default new Storage()

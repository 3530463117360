import * as Sentry from '@sentry/browser'
import axios from 'axios'
import randomColor from 'randomcolor'
import Auth from '@/auth'
import config from '@/config'
import numeral from 'numeral'

numeral.register('locale', 'eu', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    currency: {
        symbol: '€'
    }
})

numeral.register('locale', 'uk', {
    delimiters: {
        thousands: ',',
        decimal: '.'
    },
    currency: {
        symbol: '£'
    }
})

numeral.register('locale', 'na', {
    delimiters: {
        thousands: ',',
        decimal: '.'
    },
    currency: {
        symbol: '$'
    }
})

numeral.register('locale', 'pl', {
    delimiters: {
        thousands: ',',
        decimal: '.'
    },
    currency: {
        symbol: 'zł'
    }
})


numeral.register('locale', 'sc', {
    delimiters: {
        thousands: ',',
        decimal: '.'
    },
    currency: {
        symbol: 'kr'
    }
})


export function formatNumeral(value, code) {
  const n = numeral()
  n.set(value)

  switch(code) {
    case 'GBP':
      numeral.locale('uk')
      return n.format('$0,0.00')
      break
    case 'USD': case 'CAD':
      numeral.locale('na')
      return n.format('$0,0.00')
      break
    case 'PLN':
      numeral.locale('pl')
      return n.format('0,0[.]00 $')
      break
    case 'NOK': case 'SEK': case 'DKK':
      numeral.locale('sc')
      return n.format('0,0[.]00 $')
      break
    default:
      numeral.locale('eu')
      return n.format('0,0[.]00 $')
  }


}

/**
 * Import view using lazy load
 */
export function view(v) {
  return () => import(/* webpackChunkName: "view" */ `./views/${v}.vue`)
}

/**
 * Check if is laravel error
 *
 * @param  {Error}  e
 * @return {Boolean}
 */
export function isLaravelError(e) {
  return e && e.response && e.response.data && e.response.data.errors
}

/**
 * Get laravel error messages
 *
 * @param  {Error} e
 * @return {String}
 */
export function handleLaravelErrors(e) {
  if (axios.isCancel(e)) {
    console.warn('Request canceled')
    return
  }

  const user = Auth.check() ? { id: Auth.user().id } : undefined

  const sentryData = { user }

  if (!isLaravelError(e)) {
    logException(e, { ...sentryData })
    return 'Ops... something went wrong'
  }

  logException(e, { level: 'debug', ...sentryData })

  return Object.values(e.response.data.errors).join('<br>')
}

export const logException = (e, options = {}) => {
  if (config.env !== 'production') {
    console.error(e)
  }

  if (axios.isCancel(e)) {
    return
  }

  Sentry.captureException(e, {
    level: 'error',
    ...options
  })
}

/**
 * Remove all characters that are not a number
 *
 * @param  {String} value
 * @return {String}
 */
export function onlyNumbers(value) {
  if (!value || typeof value !== 'string') return ''

  return value.replace(/[^0-9]/g, '')
}

/**
 * Debounce callback
 *
 * @param  {Function} cb callback
 * @param  {Number}   wait Miliseconds to wait
 */
export function debounce(cb, wait) {
  let timeout

  return function() {
    const context = this
    const args = arguments

    const later = () => {
      timeout = null
      cb.apply(context, args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (!timeout) cb.apply(context, args)
  }
}

export const pluck = (array, key) => array.map((item) => item[key])

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

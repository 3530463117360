import ApiBase from '@/api/ApiBase'

// Moneybird API Abstraction
export default class Moneybird extends ApiBase {

  /**
   * Connect
   * @param  {String}
   * @return {Promise}
   */
  connect(company, data = {}) {
    return this.authRequest('POST', `${company}/integration/moneybird/connect`, { data } )
  }

  /**
   * Get access token
   * @param  {String}
   * @return {Promise}
   */
  fetchAccessToken(company, data = {}) {
    return this.authRequest('POST', `${company}/integration/moneybird/fetch-access-token`, { data } )
  }

  /**
   * Export init
  * @param  {String}
   * @return {Promise}
   */
  init(company, data = {}) {
    return this.authRequest('POST', `${company}/integration/moneybird/init`, { data } )
  }

  /**
   * Export all invoices
   * @param  {String}
   * @return {Promise}
   */
  export(company, data = {}) {
    return this.authRequest('POST', `${company}/integration/moneybird/export`, { data } )
  }

  /**
   * Toggle auto export invoices
   * @param  {String}
   * @return {Promise}
   */
  toggleAutoExportInvoices(company, data = {}) {
    return this.authRequest('POST', `${company}/integration/moneybird/toggle/auto-export-invoices`, { data } )
  }

  /**
   * Toggle always replace data
   * @param  {String}
   * @return {Promise}
   */
  toggleAlwaysReplaceData(company, data = {}) {
    return this.authRequest('POST', `${company}/integration/moneybird/toggle/always-replace-data`, { data } )
  }

  /**
   * Disconnect
   * @param  {String}
   * @return {Promise}
   */
  disconnect(company, data = {}) {
    return this.authRequest('POST', `${company}/integration/moneybird/disconnect`, { data } )
  }

}

import ApiBase from '@/api/ApiBase'

// TemplateInvoice API Abstraction
export default class TemplateInvoice extends ApiBase {

  /**
   * List
   * @param  {String}
   * @return {Promise}
   */
  list(company, options = {}) {
    return this.authRequest('GET', `company/${company}/templates/invoices`, options)
  }

  /**
   * Update / Create
   * @param  {String}
   * @return {Promise}
   */
  updateOrCreate(data = {}, options = {}) {
    return this.authRequest('POST', 'templates/invoices', {data, options})
  }

  /**
   * Duplicate
   * @param  {String}
   * @return {Promise}
   */
  duplicate(company, id, options = {}) {
    return this.authRequest('POST', `company/${company}/templates/invoices/${id}/duplicate`, options)
  }

  /**
   * Delete
   * @param  {String}
   * @return {Promise}
   */
  delete(id, options = {}) {
    return this.authRequest('DELETE', `templates/invoices/${id}`, options)
  }

  /**
   * Get detail
   * @param  {String}
   * @return {Promise}
   */
  detail(company, id, options = {}) {
    return this.authRequest('GET', `company/${company}/templates/invoices/${id}/detail`, options)
  }
}

import ApiBase from '@/api/ApiBase'

// LeadForm API Abstraction
export default class LeadForm extends ApiBase {

  /**
   * Get lead form
   * @param  {String} company
   * @return {Promise}
   */
  get(token, options = {}) {
    return this.authRequest('GET', `lead-forms/${token}`, options)
  }

  /**
   * List lead forms
   * @param  {String} company
   * @return {Promise}
   */
  list(company, options = {}) {
    return this.authRequest('GET', `company/${company}/lead-forms`, options)
  }

  /**
   * Update / Create lead form
   * @param  {String} company
   * @return {Promise}
   */
  updateOrCreate(data = {}, options = {}) {
    return this.authRequest('POST', 'lead-forms', {data, options})
  }

  /**
   * Duplicate
   * @param  {String}
   * @return {Promise}
   */
  duplicate(company, id, options = {}) {
    return this.authRequest('POST', `company/${company}/lead-forms/${id}/duplicate`, options)
  }

  /**
   * Delete lead form
   * @param  {String} company
   * @return {Promise}
   */
  delete(id, options = {}) {
    return this.authRequest('DELETE', `lead-forms/${id}`, options)
  }

  /**
   * Get lead form detail
   * @param  {String} company
   * @return {Promise}
   */
  detail(reference, fetchBy, options = {}) {
    return this.authRequest('GET', `lead-forms/${reference}?fetchBy=${fetchBy}`, options)
  }
}

import Vue from 'vue'
import { addDays, addMonths, addYears, format, isValid, distanceInWordsToNow, differenceInDays } from 'date-fns'
import { parseFromTimeZone, formatToTimeZone, convertToTimeZone } from 'date-fns-timezone'
import nl from 'date-fns/locale/nl'
import de from 'date-fns/locale/de'
import ro from 'date-fns/locale/ro'
import it from 'date-fns/locale/it'
import es from 'date-fns/locale/es'
import fr from 'date-fns/locale/fr'
import en from 'date-fns/locale/en'
import fi from 'date-fns/locale/fi'

/**
 * Internal dependencies
 */
import { getAvailableCustomLanguages } from '@/locales';

const locales = {nl, de, ro, it, es, fr, en, fi}

const defaultTimezone = 'Etc/UTC'

export function showHideDecimal(num, decimalSeparator) {
  if(num % 1 == 0){
    return parseInt(num);
  }

  let localeString = 'en'
  if(decimalSeparator == ','){
    localeString = 'nl'
  }

  return parseFloat(num).toLocaleString(localeString, {minimumFractionDigits: 2});
}

export function excerptEmailContent(value) {
  var div = document.createElement('div')
  div.innerHTML = value
  var text = div.textContent || div.innerText || ''

  if(text.length > 180){
    text = text.substring(0,180)+'...'
  }
  return text.trim()
}

export function isIsoDate(str) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false
  var d = new Date(str)
  return d.toISOString()===str
}

export function formatToLowerCase(value){
  return value.toLowerCase()
}

export function formatLeadFormError(value){
  value = value.toLowerCase()
  value = value.charAt(0).toUpperCase() + value.slice(1)
  return value.split('-').join(' ')
}

export function toUpperCaseFormat(value) {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export function generateDateToTimeZone(value, userTimezone) {
  (isIsoDate(value)) ? value.replace(' ', 'T') : ''
  const inputDate = new Date(value)
  //const UTCDate = parseFromTimeZone(inputDate, { timeZone: defaultTimezone })
  const date = convertToTimeZone(inputDate, { timeZone: userTimezone })

  return date
}

export const setFilters = () => {

  Vue.filter('trialDaysLeft', function (value, userTimezone) {
    if(value){
      return  differenceInDays(
        value,
        new Date()
      )
    }
    return '-'
  })

  Vue.filter('viewLocale', function (value) {
    if(value){
      switch(value) {
        case 'de':
          return 'German'
          break
        case 'nl':
          return 'Dutch'
          break
        case 'es':
          return 'Spanish'
          break
        case 'it':
          return 'Italian'
          break
        case 'ro':
          return 'Romania'
          break
        case 'fr':
          return 'French'
          break
        case 'fi':
          return 'Finnish'
          break
        case 'dk':
          return 'Danish'
          break
        case 'no':
          return 'Norwegian'
          break;
        case 'pt':
          return 'Portuguese'
          break
        case 'se':
          return 'Swedish'
          break
        default:
          return getAvailableCustomLanguages()[value] || 'N/A'
      }
    }
    return 'English'
  })

  Vue.filter('viewDate', function (value, locale) {
    if(value){
      (isIsoDate(value)) ? value.replace(' ', 'T') : ''
      const date = convertToTimeZone(value, { timeZone: defaultTimezone })
      if(locale == 'en'){
        return format(date, 'MMM D, YYYY', {locale: locales[locale]})
      }
      if(locale == 'fi'){
        return format(date, 'D.M.YYYY', {locale: locales[locale]})
      }
      if(locale == 'de'){
        return format(date, 'D. MMM YYYY', {locale: locales[locale]})
      }
      return format(date, 'D MMM, YYYY', {locale: locales[locale]})
    }
    return '-'
  })

  Vue.filter('viewDateWithTime', function (value, locale) {
    if(value){
      (isIsoDate(value)) ? value.replace(' ', 'T') : ''
      const date = convertToTimeZone(value, { timeZone: defaultTimezone })
      return format(date, 'MMM D, YYYY h:mm a', {locale: locales[locale]})
    }
    return '-'
  })

  Vue.filter('humanDateNoConvert', function (value) {
    if(value){
      (isIsoDate(value)) ? value.replace(' ', 'T') : ''
      const date = convertToTimeZone(value, { timeZone: defaultTimezone })
      return format(date, 'MMM D, YYYY')
    }
    return '-'
  })

  Vue.filter('humanDate', function (value, userTimezone) {
    if(value){
      (isIsoDate(value)) ? value.replace(' ', 'T') : ''
      const inputDate = new Date(value)
      //const UTCDate = parseFromTimeZone(inputDate, { timeZone: defaultTimezone })
      const date = convertToTimeZone(inputDate, { timeZone: userTimezone })

      return format(date, 'MMM D, YYYY')
    }
    return '-'
  })

  Vue.filter('timeFromNow', function (value, userTimezone, isConvert) {
    if(value){
      (isIsoDate(value)) ? value.replace(' ', 'T') : ''
      const inputDate = new Date(value)
      //const UTCDate = parseFromTimeZone(inputDate, { timeZone: defaultTimezone })
      // if(!isConvert){
      //   return distanceInWordsToNow(inputDate)
      // }

      // const date = convertToTimeZone(inputDate, { timeZone: userTimezone })
      return distanceInWordsToNow(inputDate)
    }
    return '-'
  })

   Vue.filter('timeFromNowSimple', function (value, userTimezone) {
    if(value){
      (isIsoDate(value)) ? value.replace(' ', 'T') : ''
      const inputDate = new Date(value)
      //const UTCDate = parseFromTimeZone(inputDate, { timeZone: defaultTimezone })
      // let date = convertToTimeZone(inputDate, { timeZone: userTimezone })
      // if(!isConvert){
      //   date = inputDate
      // }

      let word = distanceInWordsToNow(inputDate)
      let stringArray = word.split(' ')
      if(stringArray[0] == 'about'){
        word = word.substr(word.indexOf(' ') + 1)
      }

      return word
    }
    return '-'
  })

  Vue.filter('humanDateWithTimeStamp', function (value, userTimezone) {
    if(value){
      const date = generateDateToTimeZone(value, userTimezone)
      return format(date, 'MMM D, YYYY h:mm a')
    }
    return '-'
  })

  Vue.filter('humanDateWithoutYear', function (value, userTimezone) {
    if(value){
      (isIsoDate(value)) ? value.replace(' ', 'T') : ''
      const inputDate = new Date(value)
      //const UTCDate = parseFromTimeZone(inputDate, { timeZone: defaultTimezone })
      const date = convertToTimeZone(inputDate, { timeZone: userTimezone })
      return format(date, 'MMM D')
    }
    return '-'
  })
}

import ApiBase from '@/api/ApiBase'

// Pictime API Abstraction
export default class Pictime extends ApiBase {
  /**
   * Connect
   * @param  {String}
   * @return {Promise}
   */
  connect(company, clientCode) {
    return this.authRequest('POST', `${company}/integration/pictime/connect?clientCode=${clientCode}`, {  } )
  }
  /**
   * Disconnect
   * @param  {String}
   * @return {Promise}
   */
  disconnect(company, data = {}) {
    return this.authRequest('POST', `${company}/integration/pictime/disconnect`, { data } )
  }
}

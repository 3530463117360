import ApiBase from '@/api/ApiBase'

// GoogleCalendar API Abstraction
export default class GoogleCalendar extends ApiBase {

  /**
   * Connect
   * @param  {String}
   * @return {Promise}
   */
  connect(company, data = {}) {
    return this.authRequest('POST', `${company}/integration/google-calendar/connect`, { data } )
  }

  /**
   * Update calendar to sync to
   * @param  {String}
   * @return {Promise}
   */
  saveSettingsAndSyncToCalendar(company, data = {}) {
    return this.authRequest('POST', `${company}/integration/google-calendar/save-settings-and-sync-to-calendar`, { data } )
  }

  /**
   * Disconnect
   * @param  {String}
   * @return {Promise}
   */
  disconnect(company, data = {}) {
    return this.authRequest('POST', `${company}/integration/google-calendar/disconnect`, { data } )
  }
}

import ApiBase from '@/api/ApiBase'

// Company API Abstraction
export default class Subscription extends ApiBase {

  /**
   * Create subscription
   *
   * @param {String} company
   * @param  {Object} data
   *
   * @return {Promise}
   */
  create(company, data) {
    return this.authRequest('POST', `${company}/subscription`, { data })
  }

  extendTrial(company, data) {
    return this.authRequest('POST', `${company}/subscription/extend-trial`, { data })
  }

  /**
   * Patch payment method
   *
   * @param {String} company
   * @param  {Object} data
   * @return {Promise}
   */
  updatePayment(company, data = {}, options = {}) {
    return this.authRequest('PUT', `${company}/subscription/payment-method` , { data, ...options })
  }

  /**
   * Patch subscription period
   *
   * @param {String} company
   * @param  {Object} data
   * @return {Promise}
   */
  updatePeriod(company, data = {}, options = {}) {
    return this.authRequest('PATCH', `${company}/subscription/swap-plans` , { data, ...options })
  }

  /**
   * Cancel subscription
   *
   * @param {String} company
   * @param  {Object} data
   *
   * @return {Promise}
   */
  cancel(company, data) {
    return this.authRequest('DELETE', `${company}/subscription`, { data })
  }

  /**
   * Reactivate subscription while subscription still active
   *
   * @param {String} company
   * @param  {Object} data
   *
   * @return {Promise}
   */
  reactivateWhileActive(company, data) {
    return this.authRequest('PATCH', `${company}/subscription/reactivate-while-active`, { data })
  }

  /**
   * Reactivate subscription
   *
   * @param {String} company
   * @param  {Object} data
   *
   * @return {Promise}
   */
  reactivate(company, data) {
    return this.authRequest('PATCH', `${company}/subscription`, { data })
  }
}

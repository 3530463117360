<template>
  <div>
    <modal
      name="addTaxModal"
      class="modal"
      width="90%"
      height="240"
      :max-width="500"
      :adaptive="true"
      :click-to-close="false"
    >
      <div class="flex justify-between">
        <div class="title">
          Add a tax class
        </div>
        <div
          class="close "
          @click="cancel"
        />
      </div>
      <p class="leading-normal">
        You can add more Tax classes in your settings afterwards.
      </p>
      <div
        v-if="!loading"
        class="flex mt-5 items-center"
      >
        <div class="mr-2">
          VAT
        </div>
        <div class="w-3/12">
          <input
            id="tax"
            ref="add"
            v-model="tax"
            name="tax"
            type="number"
            min="1"
            placeholder=""
          > %
        </div>
      </div>
      <div class="flex mt-5 items-center">
        <div class="w-3/12">
          <v-button
            class="green-btn"
            @click="save"
          >
            Save
          </v-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import auth from '@/auth'
import { logException } from '@/helpers'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return {
      loading: false,
      tax: 0,
      user: auth.user(),
    }
  },
  watch: {
    'show': {
      handler(newVal, oldVal) {
        if(newVal){
          this.$modal.show('addTaxModal')
        }
      },
      deep: true
    }
  },
  async mounted(){

  },
  methods: {
    cancel(){
      this.$modal.hide('addTaxModal')
      this.$emit('close')
    },
    async save(){
      if(this.tax < 1){
        this.$toasted.global.general_error({
          message : 'Please add a value above zero.'
        })
        return false
      }

      try {
        const { data } = await this.$api.get('settings').addTax(this.user.company.id, {tax: this.tax} )

        try {
          await this.$store.dispatch('company/refreshCompanyDetails')
            const company = this.$store.getters['company/details']
            auth.refreshUser()

            let taxes = company.taxes
            let defaultTaxPercent = taxes[0].value

            this.$emit('saved', taxes, defaultTaxPercent)

          } catch(e) {
          this.$toasted.global.api_error(e)
        }
      } catch(e){
        this.$toasted.global.api_error(e)
      }
    }
  }
}
</script>

<style lang="scss">
  .link-items{
    height:68px;

    div{
      height:100%;
      cursor:pointer;
      padding-top:25px;
      padding-left:20px;

      &.border-right{
        padding-right:20px;
        border-right:1px solid #3F424F;
      }
    }
  }
</style>
<template>
  <div>
    <div
      class="mobile-bottom-menu"
      :class="{ 'is-active': isMobileMenuOpen }"
    >
      <div
        class="bg"
        @click="handleCloseMobileMenu"
      />
      <div class="content">
        <div
          class="close-container"
          @click.capture="handleCloseMobileMenu"
        >
          <a
            href="#"
            class="close"
          />
        </div>
        <div
          v-if="item && item.token && type && !template && mode == 'edit'"
          class="flex items-center icon"
          @click.capture="handleEvent('preview')"
        >
          <div
            class="w-1/12 text-center"
          >
            <svg
              width="21px"
              height="13px"
              viewBox="0 0 21 13"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="04---Desktop-Pages"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="8.1---New-Proposal"
                  transform="translate(-1179.000000, -27.000000)"
                  fill="#B1B6CD"
                  fill-rule="nonzero"
                >
                  <g
                    id="view-1"
                    transform="translate(1179.666667, 27.000000)"
                  >
                    <path
                      id="Shape"
                      d="M19.5225149,4.93745923 C17.3183483,2.51245923 13.6625149,-0.0508741058 9.99584825,0.000792560846 C6.32918158,-0.0517074392 2.67334825,2.51329256 0.469181584,4.93745923 C-0.156393861,5.63687384 -0.156393861,6.69471128 0.469181584,7.39412589 C2.64751492,9.79329256 6.25168158,12.3341259 9.86168158,12.3341259 L10.1183483,12.3341259 C13.7408483,12.3341259 17.3441816,9.79329256 19.5250149,7.39329256 C20.1497618,6.69351492 20.1486852,5.63596346 19.5225149,4.93745923 Z M6.16251492,6.16745923 C6.16251492,4.05036769 7.87875671,2.33412589 9.99584825,2.33412589 C12.1129398,2.33412589 13.8291816,4.05036769 13.8291816,6.16745923 C13.8291816,8.28455077 12.1129398,10.0007926 9.99584825,10.0007926 C7.87875671,10.0007926 6.16251492,8.28455077 6.16251492,6.16745923 Z"
                    />
                    <circle
                      id="Oval"
                      cx="9.99584825"
                      cy="6.16745923"
                      r="1.66666667"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="ml-2 text-grey-darker text-sm">
            View
          </div>
        </div>

        <div
          v-if="item && item.token && type && !template && mode == 'view' && !item.answered_at"
          class="flex items-center icon"
          @click="handleEvent('edit')"
        >
          <div
            class="w-1/12 text-center"
          >
            <svg
              width="18px"
              height="18px"
              viewBox="0 0 18 18"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="04---Desktop-Pages"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="8.1---New-Proposal"
                  transform="translate(-1001.000000, -24.000000)"
                  fill="#B1B6CD"
                  fill-rule="nonzero"
                >
                  <g
                    id="pencil-write-2"
                    transform="translate(1001.000000, 24.000000)"
                  >
                    <path
                      id="Path"
                      d="M10.299,11.44725 C10.1554712,11.5909254 9.98426193,11.7039593 9.79575,11.7795 L7.1445,12.84 C6.58730743,13.063031 5.95080782,12.9324635 5.52642216,12.5080778 C5.10203649,12.0836922 4.97146903,11.4471926 5.1945,10.89 L6.25575,8.23875 C6.33097937,8.05003456 6.4437597,7.87856763 6.58725,7.73475 L11.7,2.625 L2.679,2.625 C1.38828867,2.62623961 0.342153633,3.67203914 0.3405,4.96275 L0.3405,15.28725 C0.342565793,16.5777898 1.38845965,17.6233482 2.679,17.625 L13.00275,17.625 C14.2931684,17.6233472 15.3388472,16.5776684 15.3405,15.28725 L15.3405,6.40575 L10.299,11.44725 Z"
                    />
                    <path
                      id="Path"
                      d="M15.09975,0.81375 L7.383,8.5305 C7.34719882,8.56658804 7.31890386,8.60941284 7.29975,8.6565 L6.23925,11.30775 C6.18334681,11.4472361 6.21610991,11.6066242 6.3225,11.71275 C6.42932022,11.8173182 6.58749764,11.8495993 6.72675,11.79525 L9.375,10.73475 C9.42221092,10.7158317 9.46508008,10.6875074 9.501,10.6515 L17.22,2.9355 C17.6100657,2.55902569 17.7666452,2.001377 17.6295572,1.47688575 C17.4924692,0.952394508 17.0830477,0.542683356 16.5586535,0.405224455 C16.0342594,0.267765553 15.4765001,0.423950601 15.09975,0.81375 L15.09975,0.81375 Z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="ml-2 text-grey-darker text-sm">
            Edit
          </div>
        </div>
        <div
          v-if="item && type == 'invoice' && item.state == 'sent'"
          class="flex items-center icon"
          @click="handleEvent('addPayment')"
        >
          <div
            class="w-1/12 text-center"
          >
            <svg
              width="21px"
              height="15px"
              viewBox="0 0 21 15"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="04---Desktop-Pages"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="8.1---New-Proposal"
                  transform="translate(-1061.000000, -26.000000)"
                  fill="#B1B6CD"
                  fill-rule="nonzero"
                >
                  <g
                    id="accounting-bills"
                    transform="translate(1061.000000, 26.000000)"
                  >
                    <path
                      id="Shape"
                      d="M17.8282186,11.193 L17.8282186,1.71325 C17.8316305,1.46539804 17.736521,1.22630741 17.5637244,1.04858787 C17.3909279,0.870868338 17.1546026,0.769081626 16.90675,0.765625 L1.2495,0.765625 C1.00164735,0.769081626 0.765322117,0.870868338 0.592525561,1.04858787 C0.419729005,1.22630741 0.324619483,1.46539804 0.328031423,1.71325 L0.328031423,11.193 C0.324619483,11.440852 0.419729005,11.6799426 0.592525561,11.8576621 C0.765322117,12.0353817 1.00164735,12.1371684 1.2495,12.140625 L16.90675,12.140625 C17.1546026,12.1371684 17.3909279,12.0353817 17.5637244,11.8576621 C17.736521,11.6799426 17.8316305,11.440852 17.8282186,11.193 Z M9.078125,9.078125 C7.62837753,9.078125 6.453125,7.90287247 6.453125,6.453125 C6.453125,5.00337753 7.62837753,3.828125 9.078125,3.828125 C10.5278725,3.828125 11.703125,5.00337753 11.703125,6.453125 C11.703125,7.14931829 11.4265633,7.81699731 10.9342803,8.3092803 C10.4419973,8.80156329 9.77431829,9.078125 9.078125,9.078125 Z"
                    />
                    <path
                      id="Path"
                      d="M20.015625,3.171875 C19.6531881,3.171875 19.359375,3.46568813 19.359375,3.828125 L19.359375,13.453125 C19.359375,13.5739373 19.2614373,13.671875 19.140625,13.671875 L3.390625,13.671875 C3.02818813,13.671875 2.734375,13.9656881 2.734375,14.328125 C2.734375,14.6905619 3.02818813,14.984375 3.390625,14.984375 L19.140625,14.984375 C19.9859112,14.9834106 20.6709106,14.2984112 20.671875,13.453125 L20.671875,3.828125 C20.671875,3.46568813 20.3780619,3.171875 20.015625,3.171875 Z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="ml-2 text-grey-darker text-sm">
            Add payment
          </div>
        </div>
        <div
          v-if="item && item.token"
          class="flex items-center icon"
          @click="handleEvent('share')"
        >
          <div
            class="w-1/12 text-center"
          >
            <svg
              width="18px"
              height="18px"
              viewBox="0 0 18 18"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="04---Desktop-Pages"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="8.1---New-Proposal"
                  transform="translate(-1122.000000, -25.000000)"
                  fill="#B1B6CD"
                  fill-rule="nonzero"
                >
                  <g
                    id="share"
                    transform="translate(1122.000000, 25.000000)"
                  >
                    <path
                      id="Path"
                      d="M14.4,10.08 C13.4253464,10.0807172 12.493051,10.4784896 11.81808,11.1816 L7.86312,9.20376 C7.89701789,9.01764823 7.91603803,8.82913213 7.92,8.64 C7.91607775,8.45086546 7.89705729,8.2623462 7.86312,8.07624 L11.81808,6.0984 C13.017396,7.33089531 14.9218149,7.54093081 16.3608987,6.59942065 C17.7999825,5.6579105 18.3703018,3.82879543 17.7213439,2.23623237 C17.0723859,0.643669308 15.3860779,-0.26588637 13.698809,0.0665696999 C12.01154,0.39902577 10.7963151,1.88029364 10.8,3.6 C10.8039238,3.7893729 10.8229441,3.97813128 10.85688,4.16448 L6.90192,6.14088 C5.88296984,5.08408644 4.32440273,4.75185467 2.96306841,5.30125517 C1.60173409,5.85065566 0.710357062,7.17162364 0.710357062,8.63964 C0.710357062,10.1076564 1.60173409,11.4286243 2.96306841,11.9780248 C4.32440273,12.5274253 5.88296984,12.1951936 6.90192,11.1384 L10.85688,13.11552 C10.8229046,13.3018631 10.803884,13.4906247 10.8,13.68 C10.8,15.6682251 12.4117749,17.28 14.4,17.28 C16.3882251,17.28 18,15.6682251 18,13.68 C18,11.6917749 16.3882251,10.08 14.4,10.08 Z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="ml-2 text-grey-darker text-sm">
            Share link
          </div>
        </div>
        <div
          v-if="item && item.token && item.state == 'draft'"
          class="flex items-center icon"
          @click="handleEvent('markAsSent')"
        >
          <div class="w-1/12 text-center">
            <svg
              width="20px"
              height="19px"
              viewBox="0 0 20 19"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="04---Desktop-Pages"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="8.1---New-Proposal"
                  transform="translate(-1293.000000, -24.000000)"
                  fill="#B1B6CD"
                  fill-rule="nonzero"
                >
                  <g
                    id="send-email"
                    transform="translate(1293.000000, 24.000000)"
                  >
                    <path
                      id="Path"
                      d="M19.85,0.0966666667 C19.7309544,-0.002708744 19.5665702,-0.0277516553 19.4233333,0.0316666667 L0.256666667,7.94833333 C0.0999508816,8.01368953 -0.00142976764,8.16760488 0.000377405786,8.33739273 C0.00223340071,8.50718059 0.106910791,8.65887309 0.265,8.72083333 L5.54166667,10.77 C5.6713041,10.8205377 5.8176129,10.8030306 5.93166667,10.7233333 L12.9316667,5.745 C13.1094492,5.61646365 13.3564456,5.64779779 13.4965006,5.81665525 C13.6365555,5.98551271 13.6216874,6.23404436 13.4625,6.385 L7.62916667,12.0058333 C7.5476907,12.0843698 7.50166384,12.1926683 7.50166667,12.3058333 L7.50166667,17.9166667 C7.50221133,18.1044087 7.62824568,18.268599 7.80946716,18.3176514 C7.99068865,18.3667038 8.18233295,18.2885018 8.2775,18.1266667 L10.9166667,13.6058333 C10.9739417,13.5083386 11.0983292,13.4740121 11.1975,13.5283333 L16.0525,16.195 C16.1677777,16.2585343 16.306298,16.2638619 16.4261152,16.2093697 C16.5459324,16.1548775 16.6329557,16.0469739 16.6608333,15.9183333 L19.9941667,0.501666667 C20.0233323,0.350495073 19.9681262,0.195407216 19.85,0.0966666667 Z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="ml-2 text-grey-darker text-sm">
            Mark as sent
          </div>
        </div>
        <div
          v-if="item && item.token && item.state == 'sent'"
          class="flex items-center icon"
          @click="handleEvent('markAsUnsent')"
        >
          <div class="w-1/12 text-center">
            <img src="@/assets/img/icons/email-mark-unsent.svg" />
          </div>
          <div class="ml-2 text-grey-darker text-sm">
            Mark as unsent
          </div>
        </div>
        <div
          v-if="item && item.token"
          class="flex items-center icon"
          @click="handleEvent('deleteItem')"
        >
          <div
            class="w-1/12 text-center"
          >
            <svg
              width="15px"
              height="19px"
              viewBox="0 -2 15 17"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="04---Desktop-Pages"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="8.1---New-Proposal"
                  transform="translate(-1239.000000, -25.000000)"
                  fill="#B1B6CD"
                  fill-rule="nonzero"
                >
                  <g
                    id="Group-2"
                    transform="translate(1239.333333, 25.000000)"
                  >
                    <path
                      id="Shape"
                      d="M12.2500687,6.28636118 L1.7500687,6.28636118 C1.55676903,6.28636118 1.4000687,6.44306151 1.4000687,6.63636118 L1.4000687,15.3863612 C1.4000687,16.1595598 2.02687005,16.7863612 2.8000687,16.7863612 L11.2000687,16.7863612 C11.9732673,16.7863612 12.6000687,16.1595598 12.6000687,15.3863612 L12.6000687,6.63636118 C12.6000687,6.44306151 12.4433684,6.28636118 12.2500687,6.28636118 Z"
                    />
                    <path
                      id="Shape"
                      d="M13.2454687,3.38906118 C12.3370996,2.96622621 11.3593784,2.71218945 10.3600687,2.63936118 C9.98122814,1.08994834 8.59267365,-7.10542736e-15 6.9976187,-7.10542736e-15 C5.40256375,-7.10542736e-15 4.01400926,1.08994834 3.6351687,2.63936118 C2.66491022,2.71627349 1.71501857,2.95895518 0.826768696,3.35686118 C0.35158701,3.5583228 0.0313780649,4.01138728 -1.09245946e-12,4.52656118 C-0.0025543733,4.71390486 0.0700358039,4.89448061 0.201596649,5.02788331 C0.333157495,5.16128601 0.512706652,5.23636118 0.700068696,5.23636118 L13.3000687,5.23636118 C13.6826044,5.23636118 13.9943307,4.92935381 14.0000687,4.54686118 C13.9887019,4.04897746 13.6963841,3.60046757 13.2454687,3.38906118 Z M6.9839687,1.40036118 C7.75562862,1.38538353 8.46829867,1.8116814 8.8200687,2.49866118 C7.60776446,2.42798917 6.39237294,2.42798917 5.1800687,2.49866118 C5.52033471,1.81631511 6.22156701,1.38937158 6.9839687,1.40036118 L6.9839687,1.40036118 Z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="ml-2 text-grey-darker text-sm">
            Delete
          </div>
        </div>
      </div>
    </div>
    <div class="edit-header pin-t fixed simple w-full z-10 bg-white">
      <div
        class="max-w-2xl flex justify-between mx-auto"
        :class="[hideClose ? '' : 'pl-4 ']"
      >
        <div :class="[hideClose ? 'w-full text-center' : '']">
          <img
            src="@/assets/img/octoa-white.svg"
            width="88"
            class="py-5"
          >
        </div>
        <div
          v-if="!hideClose"
          class="flex items-center"
        >
          <div class="flex md:hidden">
            <div
              v-if="item && item.token && type && !template && mode == 'edit'"
              class="mx-4 cursor-pointer text-white underline"
              @click="$emit('preview')"
            >
              View
            </div>

            <div
              v-if="item && item.token && type && !template && mode == 'view' && !item.answered_at"
              class="mx-4 cursor-pointer text-white underline"
              @click="$emit('edit')"
            >
              Edit
            </div>

            <div
              v-if="item && item.token && type && !template"
              class="mx-4 cursor-pointer text-white underline"
              @click="handleOpenMobileMenu"
            >
              <svg
                width="28px"
                height="9px"
                viewBox="0 0 28 9"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  id="07---Mobile-Pages"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <g
                    id="Sub-menu"
                    transform="translate(-272.000000, -139.000000)"
                    stroke="#FFFFFF"
                    stroke-width="1.5"
                  >
                    <g
                      id="navigation-menu-horizontal"
                      transform="translate(272.000000, 140.000000)"
                    >
                      <circle
                        id="Oval"
                        cx="3.9375"
                        cy="3.52683333"
                        r="3.0625"
                      />
                      <circle
                        id="Oval"
                        cx="24.0625"
                        cy="3.52683333"
                        r="3.0625"
                      />
                      <circle
                        id="Oval"
                        cx="14"
                        cy="3.52683333"
                        r="3.0625"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div
            class="hidden md:flex link-items mr-1 md:mr-8  text-green text-sm items-center"
          >
            <div
              v-if="item && type == 'invoice' && item.state == 'sent'"
              class="mx-1 md:mx-4 icon"
              @click="$emit('addPayment')"
            >
              <span
                v-tooltip="{ ...tooltip, content: 'Add payment' }"
                class="pt-2"
              >
                <svg
                  width="21px"
                  height="15px"
                  viewBox="0 0 21 15"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="04---Desktop-Pages"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="8.1---New-Proposal"
                      transform="translate(-1061.000000, -26.000000)"
                      fill="#B1B6CD"
                      fill-rule="nonzero"
                    >
                      <g
                        id="accounting-bills"
                        transform="translate(1061.000000, 26.000000)"
                      >
                        <path
                          id="Shape"
                          d="M17.8282186,11.193 L17.8282186,1.71325 C17.8316305,1.46539804 17.736521,1.22630741 17.5637244,1.04858787 C17.3909279,0.870868338 17.1546026,0.769081626 16.90675,0.765625 L1.2495,0.765625 C1.00164735,0.769081626 0.765322117,0.870868338 0.592525561,1.04858787 C0.419729005,1.22630741 0.324619483,1.46539804 0.328031423,1.71325 L0.328031423,11.193 C0.324619483,11.440852 0.419729005,11.6799426 0.592525561,11.8576621 C0.765322117,12.0353817 1.00164735,12.1371684 1.2495,12.140625 L16.90675,12.140625 C17.1546026,12.1371684 17.3909279,12.0353817 17.5637244,11.8576621 C17.736521,11.6799426 17.8316305,11.440852 17.8282186,11.193 Z M9.078125,9.078125 C7.62837753,9.078125 6.453125,7.90287247 6.453125,6.453125 C6.453125,5.00337753 7.62837753,3.828125 9.078125,3.828125 C10.5278725,3.828125 11.703125,5.00337753 11.703125,6.453125 C11.703125,7.14931829 11.4265633,7.81699731 10.9342803,8.3092803 C10.4419973,8.80156329 9.77431829,9.078125 9.078125,9.078125 Z"
                        />
                        <path
                          id="Path"
                          d="M20.015625,3.171875 C19.6531881,3.171875 19.359375,3.46568813 19.359375,3.828125 L19.359375,13.453125 C19.359375,13.5739373 19.2614373,13.671875 19.140625,13.671875 L3.390625,13.671875 C3.02818813,13.671875 2.734375,13.9656881 2.734375,14.328125 C2.734375,14.6905619 3.02818813,14.984375 3.390625,14.984375 L19.140625,14.984375 C19.9859112,14.9834106 20.6709106,14.2984112 20.671875,13.453125 L20.671875,3.828125 C20.671875,3.46568813 20.3780619,3.171875 20.015625,3.171875 Z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
            </div>
            <div
              v-if="item && item.token"
              class="mx-1 md:mx-4 icon"
              @click="$emit('share')"
            >
              <span
                v-tooltip="{ ...tooltip, content: 'Copy share link' }"
                class="pt-2"
              >
                <svg
                  width="18px"
                  height="18px"
                  viewBox="0 0 18 18"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="04---Desktop-Pages"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="8.1---New-Proposal"
                      transform="translate(-1122.000000, -25.000000)"
                      fill="#B1B6CD"
                      fill-rule="nonzero"
                    >
                      <g
                        id="share"
                        transform="translate(1122.000000, 25.000000)"
                      >
                        <path
                          id="Path"
                          d="M14.4,10.08 C13.4253464,10.0807172 12.493051,10.4784896 11.81808,11.1816 L7.86312,9.20376 C7.89701789,9.01764823 7.91603803,8.82913213 7.92,8.64 C7.91607775,8.45086546 7.89705729,8.2623462 7.86312,8.07624 L11.81808,6.0984 C13.017396,7.33089531 14.9218149,7.54093081 16.3608987,6.59942065 C17.7999825,5.6579105 18.3703018,3.82879543 17.7213439,2.23623237 C17.0723859,0.643669308 15.3860779,-0.26588637 13.698809,0.0665696999 C12.01154,0.39902577 10.7963151,1.88029364 10.8,3.6 C10.8039238,3.7893729 10.8229441,3.97813128 10.85688,4.16448 L6.90192,6.14088 C5.88296984,5.08408644 4.32440273,4.75185467 2.96306841,5.30125517 C1.60173409,5.85065566 0.710357062,7.17162364 0.710357062,8.63964 C0.710357062,10.1076564 1.60173409,11.4286243 2.96306841,11.9780248 C4.32440273,12.5274253 5.88296984,12.1951936 6.90192,11.1384 L10.85688,13.11552 C10.8229046,13.3018631 10.803884,13.4906247 10.8,13.68 C10.8,15.6682251 12.4117749,17.28 14.4,17.28 C16.3882251,17.28 18,15.6682251 18,13.68 C18,11.6917749 16.3882251,10.08 14.4,10.08 Z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
            </div>
            <div
              v-if="item && item.token && item.state == 'draft'"
              class="mx-1 md:mx-4 icon"
              @click="$emit('markAsSent')"
            >
              <span v-tooltip="{ ...tooltip, content: 'Mark as sent' }">
                <svg
                  width="20px"
                  height="19px"
                  viewBox="0 0 20 19"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="04---Desktop-Pages"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="8.1---New-Proposal"
                      transform="translate(-1293.000000, -24.000000)"
                      fill="#B1B6CD"
                      fill-rule="nonzero"
                    >
                      <g
                        id="send-email"
                        transform="translate(1293.000000, 24.000000)"
                      >
                        <path
                          id="Path"
                          d="M19.85,0.0966666667 C19.7309544,-0.002708744 19.5665702,-0.0277516553 19.4233333,0.0316666667 L0.256666667,7.94833333 C0.0999508816,8.01368953 -0.00142976764,8.16760488 0.000377405786,8.33739273 C0.00223340071,8.50718059 0.106910791,8.65887309 0.265,8.72083333 L5.54166667,10.77 C5.6713041,10.8205377 5.8176129,10.8030306 5.93166667,10.7233333 L12.9316667,5.745 C13.1094492,5.61646365 13.3564456,5.64779779 13.4965006,5.81665525 C13.6365555,5.98551271 13.6216874,6.23404436 13.4625,6.385 L7.62916667,12.0058333 C7.5476907,12.0843698 7.50166384,12.1926683 7.50166667,12.3058333 L7.50166667,17.9166667 C7.50221133,18.1044087 7.62824568,18.268599 7.80946716,18.3176514 C7.99068865,18.3667038 8.18233295,18.2885018 8.2775,18.1266667 L10.9166667,13.6058333 C10.9739417,13.5083386 11.0983292,13.4740121 11.1975,13.5283333 L16.0525,16.195 C16.1677777,16.2585343 16.306298,16.2638619 16.4261152,16.2093697 C16.5459324,16.1548775 16.6329557,16.0469739 16.6608333,15.9183333 L19.9941667,0.501666667 C20.0233323,0.350495073 19.9681262,0.195407216 19.85,0.0966666667 Z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
            </div>
            <div
              v-if="item && item.token && item.state == 'sent'"
              class="mx-1 md:mx-4 icon"
              @click="$emit('markAsUnsent')"
            >
              <span v-tooltip="{ ...tooltip, content: 'Mark as unsent' }">
                <img src="@/assets/img/icons/email-mark-unsent.svg" />
              </span>
            </div>
            <!-- <div
              v-if="item"
              class="
            >
              Mark as accepted
            </div> -->
            <div
              v-if="item && item.token"
              class="mx-1 md:mx-4 icon"
              @click="$emit('deleteItem')"
            >
              <span
                v-tooltip="{ ...tooltip, content: 'Delete' }"
                class="pt-2"
              >
                <svg
                  width="15px"
                  height="17px"
                  viewBox="0 0 15 17"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="04---Desktop-Pages"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="8.1---New-Proposal"
                      transform="translate(-1239.000000, -25.000000)"
                      fill="#B1B6CD"
                      fill-rule="nonzero"
                    >
                      <g
                        id="Group-2"
                        transform="translate(1239.333333, 25.000000)"
                      >
                        <path
                          id="Shape"
                          d="M12.2500687,6.28636118 L1.7500687,6.28636118 C1.55676903,6.28636118 1.4000687,6.44306151 1.4000687,6.63636118 L1.4000687,15.3863612 C1.4000687,16.1595598 2.02687005,16.7863612 2.8000687,16.7863612 L11.2000687,16.7863612 C11.9732673,16.7863612 12.6000687,16.1595598 12.6000687,15.3863612 L12.6000687,6.63636118 C12.6000687,6.44306151 12.4433684,6.28636118 12.2500687,6.28636118 Z"
                        />
                        <path
                          id="Shape"
                          d="M13.2454687,3.38906118 C12.3370996,2.96622621 11.3593784,2.71218945 10.3600687,2.63936118 C9.98122814,1.08994834 8.59267365,-7.10542736e-15 6.9976187,-7.10542736e-15 C5.40256375,-7.10542736e-15 4.01400926,1.08994834 3.6351687,2.63936118 C2.66491022,2.71627349 1.71501857,2.95895518 0.826768696,3.35686118 C0.35158701,3.5583228 0.0313780649,4.01138728 -1.09245946e-12,4.52656118 C-0.0025543733,4.71390486 0.0700358039,4.89448061 0.201596649,5.02788331 C0.333157495,5.16128601 0.512706652,5.23636118 0.700068696,5.23636118 L13.3000687,5.23636118 C13.6826044,5.23636118 13.9943307,4.92935381 14.0000687,4.54686118 C13.9887019,4.04897746 13.6963841,3.60046757 13.2454687,3.38906118 Z M6.9839687,1.40036118 C7.75562862,1.38538353 8.46829867,1.8116814 8.8200687,2.49866118 C7.60776446,2.42798917 6.39237294,2.42798917 5.1800687,2.49866118 C5.52033471,1.81631511 6.22156701,1.38937158 6.9839687,1.40036118 L6.9839687,1.40036118 Z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
            </div>
            <div
              v-if="item && item.token && type && !template && mode == 'edit'"
              class="mx-1 md:mx-4 icon"
              @click="leavePageWarning()"
            >
              <span v-tooltip="{ ...tooltip, content: 'view' }">
                <svg
                  width="21px"
                  height="13px"
                  viewBox="0 0 21 13"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="04---Desktop-Pages"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="8.1---New-Proposal"
                      transform="translate(-1179.000000, -27.000000)"
                      fill="#B1B6CD"
                      fill-rule="nonzero"
                    >
                      <g
                        id="view-1"
                        transform="translate(1179.666667, 27.000000)"
                      >
                        <path
                          id="Shape"
                          d="M19.5225149,4.93745923 C17.3183483,2.51245923 13.6625149,-0.0508741058 9.99584825,0.000792560846 C6.32918158,-0.0517074392 2.67334825,2.51329256 0.469181584,4.93745923 C-0.156393861,5.63687384 -0.156393861,6.69471128 0.469181584,7.39412589 C2.64751492,9.79329256 6.25168158,12.3341259 9.86168158,12.3341259 L10.1183483,12.3341259 C13.7408483,12.3341259 17.3441816,9.79329256 19.5250149,7.39329256 C20.1497618,6.69351492 20.1486852,5.63596346 19.5225149,4.93745923 Z M6.16251492,6.16745923 C6.16251492,4.05036769 7.87875671,2.33412589 9.99584825,2.33412589 C12.1129398,2.33412589 13.8291816,4.05036769 13.8291816,6.16745923 C13.8291816,8.28455077 12.1129398,10.0007926 9.99584825,10.0007926 C7.87875671,10.0007926 6.16251492,8.28455077 6.16251492,6.16745923 Z"
                        />
                        <circle
                          id="Oval"
                          cx="9.99584825"
                          cy="6.16745923"
                          r="1.66666667"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
            </div>

            <div
              v-if="item && item.token && type && !template && mode == 'view' && !item.answered_at"
              class="mx-1 md:mx-4 icon"
              @click="$emit('edit')"
            >
              <span v-tooltip="{ ...tooltip, content: 'edit' }">
                <svg
                  width="18px"
                  height="18px"
                  viewBox="0 0 18 18"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="04---Desktop-Pages"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="8.1---New-Proposal"
                      transform="translate(-1001.000000, -24.000000)"
                      fill="#B1B6CD"
                      fill-rule="nonzero"
                    >
                      <g
                        id="pencil-write-2"
                        transform="translate(1001.000000, 24.000000)"
                      >
                        <path
                          id="Path"
                          d="M10.299,11.44725 C10.1554712,11.5909254 9.98426193,11.7039593 9.79575,11.7795 L7.1445,12.84 C6.58730743,13.063031 5.95080782,12.9324635 5.52642216,12.5080778 C5.10203649,12.0836922 4.97146903,11.4471926 5.1945,10.89 L6.25575,8.23875 C6.33097937,8.05003456 6.4437597,7.87856763 6.58725,7.73475 L11.7,2.625 L2.679,2.625 C1.38828867,2.62623961 0.342153633,3.67203914 0.3405,4.96275 L0.3405,15.28725 C0.342565793,16.5777898 1.38845965,17.6233482 2.679,17.625 L13.00275,17.625 C14.2931684,17.6233472 15.3388472,16.5776684 15.3405,15.28725 L15.3405,6.40575 L10.299,11.44725 Z"
                        />
                        <path
                          id="Path"
                          d="M15.09975,0.81375 L7.383,8.5305 C7.34719882,8.56658804 7.31890386,8.60941284 7.29975,8.6565 L6.23925,11.30775 C6.18334681,11.4472361 6.21610991,11.6066242 6.3225,11.71275 C6.42932022,11.8173182 6.58749764,11.8495993 6.72675,11.79525 L9.375,10.73475 C9.42221092,10.7158317 9.46508008,10.6875074 9.501,10.6515 L17.22,2.9355 C17.6100657,2.55902569 17.7666452,2.001377 17.6295572,1.47688575 C17.4924692,0.952394508 17.0830477,0.542683356 16.5586535,0.405224455 C16.0342594,0.267765553 15.4765001,0.423950601 15.09975,0.81375 L15.09975,0.81375 Z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
            </div>


            <!-- <div
              v-if="item"
              class="mx-4"
              @click="$emit('send')"
            >
              Send
            </div> -->
          </div>
          <div
            v-if="!hideClose"
            class="close-container"
            @click.capture="handleClick"
          >
            <router-link
              v-if="!userMightUpdate"
              class="close"
              :to="redirect"
            />
            <a
              v-if="userMightUpdate"
              href="#"
              class="close"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VInnerHeader',
  props: {
    mode: {
      type: String,
      default: null
    },
    item: {
      type: Object,
      default: null
    },
    isSaving: {
      type: Boolean,
      default: false
    },
    isSaved: {
      type: Boolean,
      default: false
    },
    userMightUpdate: {
      type: Boolean,
      default: false
    },
    redirect: {
      type: String,
      default: '/'
    },
    type: {
      type: String,
      default: null
    },
    template:{
      type: Boolean,
      default: false
    },
    hideClose: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isMobileMenuOpen: false,
      tooltip: {
        delay: 0,
        show: false,
        offset: 0,
        trigger: 'hover',
        classes: ['header-tooltip'],
        placement: 'bottom'
      }
    }
  },
  methods: {
    async leavePageWarning() {
      const canNavigate = await this.$alert.confirm({
        title: 'Are you sure?',
        text: 'You might have unsaved changes, remember to save before leaving this page.',
      })

      if (canNavigate) {
        this.$router.push(`/proposals/preview/${this.item.id}`)
      }
    },
    handleOpenMobileMenu(){
      this.isMobileMenuOpen = true
    },
    handleCloseMobileMenu(){
      this.isMobileMenuOpen = false
    },
    handleEvent(event) {
      this.isMobileMenuOpen = false
      this.$emit(event)
    },
    handleClick(event) {
      this.$emit('click', event)
    },
  }
}
</script>

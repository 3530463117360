import Vue from 'vue'

Vue.mixin({
  methods: {
    /**
     * Return the response if it was successful, otherwise show errors
     *
     * @param res
     * @param errors
     * @returns {null|*}
     */
    handleErrors([res, errors]) {
      if (errors) {
        errors.forEach(this.$toasted.error)
        return null
      }

      return res
    }
  }
})

import ApiBase from '@/api/ApiBase'

// Settings API Abstraction
export default class Settings extends ApiBase {
  /**
   * Email
   *
   * @param {String} company
   * @param  {Object} data
   *
   * @return {Promise}
   */
  updateEmail(data = {}) {
    return this.authRequest('PATCH', 'settings/email', { data } )
  }

  /**
   * Add a new tax class
   *
   * @param {String} company
   * @param  {Object} data
   *
   * @return {Promise}
   */
  addTax(company, data = {}) {
    return this.authRequest('POST', `${company}/tax`, { data })
  }

}

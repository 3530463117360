<style lang="scss">
.tooltip-inner {
    max-width:500px !important;
}
.help-tooltip {

  margin-left: 5px;

  @screen sm {
    display: inline-block;
  }
  .tooltip-inner {
    font-style: italic;
    font-size: 13px;
  }

  .icon{
    vertical-align:middle;
    cursor:pointer;
  }
}
</style>
<template>
  <span class="help-tooltip">
    <img
      v-tooltip="{ ...tooltip, ...options }"
      :src="icon"
      width="20"
      class="help-tooltip__icon icon"
    >
  </span>
</template>

<script>
export default {
  name: 'VHelpTooltip',
  props: {
    dark: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      tooltip: {
        delay: 0,
        show: false,
        trigger: (window.innerWidth < 769) ? 'click' : 'hover',
        classes: ['help-tooltip'],
      }
    }
  },
  computed: {
    icon() {
      const file = this.dark ?
        'ico-help.svg' :
        'ico-help-dark.svg'

      return require(`../../assets/img/icons/${file}`)
    }
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  mounted(){
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    onResize () {
      if(window.innerWidth < 769){
        this.tooltip.trigger = 'click'
      } else {
        this.tooltip.trigger = 'hover'
      }
    },
  }
}
</script>

<template>
  <div class="-mt-2">
    <button
      v-for="color in colors"
      :key="color"
      :class="['color mr-2 mt-2 transition focus:outline hover:scale-105', color === value ? 'selected' : '']"
      :style="`background-color: ${color}`"
      @click="$emit('input', color)"
    />
  </div>
</template>

<script>
import { workflowColors } from '../../utils/workflows'

export default {
  name: 'VAltColorPicker',
  props: {
    value: {
      type: [String, null],
      default: null
    }
  },
  emits: ['update:modelValue'],
  data: () => ({
    colors: workflowColors,
  })
}
</script>

<style scoped>
 button {
   cursor: pointer;
   background-color: unset;
   color: unset;
   padding: 0;
   font-weight: unset;
   border-radius: 9999px !important;
   transition: all 100ms ease-in-out;
}

 button:focus {
   outline: 5px auto -webkit-focus-ring-color;
 }

 button:hover {
   transform: scale(1.1);
   @apply shadow-md;
 }

 button.selected {
   outline: 5px auto black;
 }

 .color {
   width: 26px;
   height: 26px;
 }
</style>

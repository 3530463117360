<template>
  <div class="sidebar">
    <div class="sidebar__head">
      <slot name="logo">
        Vesper
      </slot>
    </div>
    <div class="sidebar__body">
      <nav class="sidebar__menu">
        <v-button
          v-for="(item, index) in menuItems"
          :key="index"
          :to="item.to"
          :icon-left="item.icon"
          class="btn--sidebar btn--block sidebar__menu-item"
        >
          {{ item.text }}
        </v-button>
      </nav>
    </div>
    <div class="sidebar__footer">
      <v-button
        class="btn--sidebar btn--block sidebar__menu-item"
        :to="{name: 'manage.dashboard'}"
      >
        Manage Dashboard
      </v-button>
      <v-button
        class="btn--sidebar btn--block sidebar__menu-item"
        :to="{name: 'help.support'}"
      >
        Help & Support
      </v-button>
      <v-dropdown
        v-if="user"
        class="sidebar__menu-item-dropdown"
      >
        <v-button
          slot="label"
          class="btn--sidebar btn--block sidebar__menu-item sidebar__menu-item--avatar"
          href="#"
        >
          <v-avatar />
          <span :title="user.name">
            {{ userName }}
          </span>
        </v-button>
        <v-dropdown-item :to="{name: 'my-account.details'}">
          Manage Account
        </v-dropdown-item>
        <v-dropdown-item @click.prevent="logout">
          Log out
        </v-dropdown-item>
      </v-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Sidebar',
  data() {
    return {
      menuItems: [{
        icon: 'vesper-dashboard',
        text: 'Dashboard',
        to: { name: 'home' }
      }, {
        icon: 'vesper-futures',
        text: 'Futures',
        to: { name: 'futures' }
      }, {
        icon: 'vesper-calculations',
        text: 'Calculations',
        to: { name: 'calculations' }
      }, {
        icon: 'vesper-forecast',
        text: 'Forecast',
        to: { name: 'forecast' }
      }, {
        icon: 'vesper-news',
        text: 'News',
        to: { name: 'news' }
      }, {
        icon: 'vesper-documentation',
        text: 'Documentation',
        to: { name: 'documentation' }
      }]
    }
  },
  computed: {
    userName() {
      if (!this.user) return

      if (this.user.name && this.user.name.length > 15) {
        return this.user.name.substr(0, 13) + '...'
      }

      return this.user.name
    },
    ...mapState({
      user: state => {
        if (!state.auth || !state.auth.user) {
          return
        }

        if (state.auth.user && state.auth.user.length > 10) {
          return `${state.auth.user.substr(0, 7)}...`
        }
        return state.auth.user
      }
    })
  },
  methods: {
    logout() {
      this.$toasted.clear()

      try {
        this.$store.dispatch('auth/logout')
        this.$router.replace({ name: 'login' })
      } catch(e) {
        this.$toasted.global.api_error(e)
      }
    }
  }
}
</script>

<style lang="scss">
.sidebar {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: color(sidebar, bg);

  &__head {
    flex: none;
    display: flex;
    align-items: center;
    width: 100%;
    height: 90px;
    flex-basis: 90px;
    padding-left: 32px;
  }

  &__body {
    padding-left: 16px;
    padding-right: 16px;
    overflow-y: auto;
    flex: 1;
  }

  &__footer {
    border-top: 1px solid rgba(map-get($colors, bg1), .2);
    padding: 16px;
  }

  &__menu {
    width: 100%;
    height: 100%;
  }

  &__menu-item-dropdown {
    &,
    > span, > div {
      width: 100%;
    }
  }

  &__menu-item {
    margin-bottom: 10px;
    text-align: left !important;
    font-weight: 400 !important;
    font-size: 16px !important;

    &--avatar {
      .btn__content-text {
        display: flex;
        align-items: center;
        flex-direction:row;
        width: 100%;
        height: 100%;

        .avatar {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>

import ApiBase from '@/api/ApiBase'

// Promo API Abstraction
export default class Dashboard extends ApiBase {

  tasks(company, data = {}) {
    return this.authRequest('GET', `${company}/dashboard/tasks`, { data } )
  }

  stats(company, data = {}) {
    return this.authRequest('POST', `${company}/dashboard/stats`, { data } )
  }

  invoices(company, data = {}) {
    return this.authRequest('POST', `${company}/dashboard/stats/invoices`, { data } )
  }

  currencies(company, data = {}) {
    return this.authRequest('GET', `${company}/dashboard/stats/currencies`, { data } )
  }

  activities(company, data = {}) {
    return this.authRequest('GET', `${company}/dashboard/activities?page=${data.page}&take=${data.take}`, { data } )
  }

  markAllAsSeen(company, data = {}) {
    return this.authRequest('GET', `${company}/dashboard/mark-all-seen`, { data } )
  }
}

import { view } from '@/helpers'
import { applyMiddleware } from '@/middleware'
import projectRoutes from '@/router/project'
import contactRoutes from '@/router/contact'
import taskRoutes from '@/router/task'
import settingRoutes from '@/router/setting'
import templateRoutes from '@/router/template'
import invoiceRoutes from '@/router/invoice'
import proposalRoutes from '@/router/proposal'
import publicRoutes from '@/router/public'
import dashboardRoutes from '@/router/dashboard'
import calendarRoutes from '@/router/calendar'
import questionnaireRoutes from '@/router/questionnaire'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: view('Login'),
    meta: {
      hideHeader: true,
    },
    ...applyMiddleware('guest')
  },
  {
    path: '/logout',
    name: 'Logout',
    meta: {
      hideHeader: true,
    },
    component: view('Logout')
  },
  {
    path: '/start',
    name: 'Start',
    meta: {
      hideHeader: true,
    },
    component: view('StartAfterTrial')
  },
  {
    path: '/reactivate',
    name: 'Reactivate',
    meta: {
      hideHeader: true,
    },
    component: view('ReactivatePlan')
  },
  {
    path: '/register',
    name: 'Register',
    component: view('Register'),
    meta: {
      hideHeader: true,
      fullFooter: true
    },
    ...applyMiddleware('guest')
  },
  {
    path: '/register/company',
    name: 'RegisterCompany',
    component: view('RegisterCompany'),
    meta: {
      hideHeader: true,
      fullFooter: true
    },
    ...applyMiddleware('guest')
  },
  {
    path: '/forget-password',
    name: 'forget-password',
    component: view('ForgetPassword'),
    ...applyMiddleware('guest')
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: view('ResetPassword'),
    ...applyMiddleware('guest')
  },
  {
    path: '/forget-password',
    name: 'forgetPassword',
    component: view('ForgetPassword'),
    meta: {
        fullFooter: true
    },
    ...applyMiddleware('guest')
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: view('ResetPassword'),
    ...applyMiddleware('guest')
  },
  {
    path: '/embed/lead/form/:token',
    name: 'LeadForm',
    meta: {
        hideHeader: true,
        hideFooter: true,
    },
    component: view('LeadForm'),
    ...applyMiddleware('guest')
  },
  {
    path: '/view/:projectId/proposal/:token',
    name: 'ProposalView',
    meta: {
        hideHeader: true,
        hideFooter: true
    },
    component: view('Proposals/ProposalPreview'),
    ...applyMiddleware('guest')
  },{
    path: '/view/:projectId/invoice/:token',
    name: 'InvoiceView',
    meta: {
        hideHeader: true,
        hideFooter: true
    },
    component: view('Invoices/InvoicePreview'),
    ...applyMiddleware('guest')
  },{
    path: '/view/invoice/:token',
    name: 'InvoiceWithoutProjectView',
    meta: {
      hideHeader: true,
      hideFooter: true
    },
    component: view('Invoices/InvoicePreview'),
    ...applyMiddleware('guest')
  },{
    path: '/view/:projectId/questionnaire/:token',
    name: 'QuestionnaireView',
    meta: {
        hideHeader: true,
        hideFooter: true
    },
    component: view('Questionnaires/QuestionnairePreview'),
    ...applyMiddleware('guest')
  },
  {
    path: '/auth/token/:token',
    name: 'loginAs',
    component: view('LoginAs'),
    ...applyMiddleware('guest')
  },
  //
  // App routes
  //
  dashboardRoutes,
  calendarRoutes,
  projectRoutes,
  contactRoutes,
  taskRoutes,
  settingRoutes,
  templateRoutes,
  invoiceRoutes,
  proposalRoutes,
  questionnaireRoutes,
  publicRoutes,
  {
    path: '*',
    component: view('404')
  }
]

export default routes

<template>
  <div
    class="custom-checkbox inline-block"
    :class="[type == 'dark' ? 'dark' : '', type == 'tab' ? 'tab' : '']"
    style="width: auto!important"
  >
    <input
      :id="id"
      v-validate="'required'"
      :name="name"
      class="styled-checkbox"
      :checked="checked"
      type="checkbox"
      :disabled="disabled"
      @change="$emit('input', $event.target.checked)"
      @click.capture="handleClick"
    >
    <label
      :class="classes"
      :style="textStyle"
      :for="id"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'VCheckbox',
  props: {
    id: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    textStyle: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    value: {
      type: [Number, Boolean],
      default: false
    },
    classes: {
      type: String,
      default: null
    },
    active: {
      type: [String, Boolean],
      default: false
    },
    checked: {
      type: [String, Boolean],
      default: false
    },
  },
  computed: {
    disabled(){
      if(!this.active){
        return false
      }
      return true
    }
  },
  methods: {
    handleClick(event) {
      this.$emit('click', event)
    }
  }

}
</script>

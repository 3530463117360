import VTooltip from 'v-tooltip'

export default {
  expose(Vue) {
    Vue.directive('tooltip', VTooltip.VTooltip)

    Vue.use(VTooltip, {
      defaultDelay: { show: 1000, hide: 0 },
      defaultBoundariesElement: document.body,
      popover: {
        defaultHandleResize: false
      }
    })
  }
}

import auth from '@/auth'

export default (to, from, next) => {
  // User is not authenticated and is trying to access
  // a protected url.

  if (!auth.check() || !auth.user()) {
    // Make sure we remove everything first
    auth.clearStorage()

    return next({
      name: 'Login',
      query: {
       redirect: to.path
      }
    })
  }

  return next()
}

import ApiBase from '@/api/ApiBase'

// Currency API Abstraction
export default class Currency extends ApiBase {
  /**
   * Get all curriences
   *
   * @return {Promise}
   */
  list(options = {}) {
    return this.authRequest('GET', 'currencies', { ...options })
  }

}

import ApiBase from '@/api/ApiBase'

// Promo API Abstraction
export default class Promo extends ApiBase {

  /**
   * Lead form ask help
   * @param  {String}
   * @return {Promise}
   */
  leadForm(company, data = {}, options = {}) {
    return this.authRequest('POST', `${company}/promo/lead-form`, { data, options } )
  }
}

import ApiBase from '@/api/ApiBase'

// Company API Abstraction
export default class Billing extends ApiBase {

  /**
   * Get all billing
   *
   * @param {String} company
   * @param  {Object} data
   *
   * @return {Promise}
   */
  all(company, data) {
    return this.authRequest('GET', `${company}/subscription/billing-history`, { data })
  }
}

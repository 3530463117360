import ApiBase from '@/api/ApiBase'
import { client } from './VOClient'

// Proposal API Abstraction
export default class Proposal extends ApiBase {

  /**
   * Get
   * @param  {Object}
   * @return {Promise}
   */
  list(data = {}, options = {}) {
    return this.authRequest('POST', 'proposals', {data, options})
  }

  /**
   * View for public
   * @param  {String}
   * @return {Promise}
   */
  view(target, type, options = {}) {
    return this.authRequest('GET', `view/proposals/${target}?type=${type}`, options)
  }

  /**
   * Update / Create
   * @param  {Object}
   * @return {Promise}
   */
  save(data = {}, options = {}) {
    return this.authRequest('POST', 'proposals', { data, options })
  }

  /**
   * Delete
   * @param  {Object}
   * @return {Promise}
   */
  delete(data = {}, options = {}) {
    return this.authRequest('DELETE', 'proposals', { data, options })
  }

  /**
   * Patch state
   * @param  {String}
   * @return {Promise}
   */
  updateState(target, state, options = {}) {
    return this.authRequest('PATCH', `proposals/${target}/${state}`,  options)
  }

  /**
   * Accept
   * @param  {Object}
   * @return {Promise}
   */
  accept(data = {}, target, options = {}) {
    return this.authRequest('POST', `view/proposals/accept/${target}`, { data, options })
  }

  /**
   * Get detail
   * @param  {String}
   * @return {Promise}
   */
  detail(target, type, options = {}) {
    return this.authRequest('GET', `proposals/${target}/detail?type=${type}`, options)
  }

  /**
   * Get last reference number
   * @param  {String}
   * @return {Promise}
   */
  lastReferenceNumber(options = {}) {
    return this.authRequest('GET', 'proposals/all/last-reference-number', options)
  }
}

export const createProposalFromTemplate = (companyId, templateId, workflowJobId) => {
  return client.post(`company/${companyId}/templates/proposals/${templateId}/create`, { workflow_job_id : workflowJobId })
}

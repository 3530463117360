<template>
  <div class="v-radio">
    <input
      ref="input"
      type="radio"
      :name="name"
      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
      :value="value"
      :checked="isChecked"
      v-model="valueModel"
    />
  </div>
</template>

<script>
export default {
  name: 'VRadio',
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      default: undefined
    },
    isChecked: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    valueModel: {
      get() {
        return this.isChecked
          ? this.value
          : null;
      },
      set(value) {
        this.$emit('change', value)
      }
    }
  }
}
</script>

<style lang="scss">
  .v-radio {
    input {
      border: 1px solid #d1d1d1;
      appearance: none;
      border-radius: 100%;
      width: 21px;
      height: 21px;
      position: relative;
      color: white;

      &:checked {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 20 20' fill='%230942bc' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='10' cy='10' r='5'/%3e%3c/svg%3e");
        background-color: currentColor;
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
</style>

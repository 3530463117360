import ApiBase from '@/api/ApiBase'

// Template Questionnaire API Abstraction
export default class LeadForm extends ApiBase {

  /**
   * Get questionnaire
   * @param  {String} company
   * @return {Promise}
   */
  list(company, options = {}) {
    return this.authRequest('GET', `company/${company}/templates/questionnaires`, options)
  }

  /**
   * Duplicate
   * @param  {String}
   * @return {Promise}
   */
  duplicate(company, id, options = {}) {
    return this.authRequest('POST', `company/${company}/templates/questionnaires/${id}/duplicate`, options)
  }

  /**
   * Get questionnaire detail
   * @param  {String}
   * @return {Promise}
   */
  detail(company, id, options = {}) {
    return this.authRequest('GET', `company/${company}/templates/questionnaires/${id}/detail`, options)
  }

  /**
   * Update / Create questionnaire
   * @param  {String} company
   * @return {Promise}
   */
  updateOrCreate(data = {}, options = {}) {
    return this.authRequest('POST', 'templates/questionnaires', {data, options})
  }

  /**
   * Delete questionnaire
   * @param  {String} company
   * @return {Promise}
   */
  delete(id, options = {}) {
    return this.authRequest('DELETE', `templates/questionnaires/${id}`, options)
  }

}

import ApiBase from '@/api/ApiBase'
import { client } from './VOClient'

// Tasks API Abstraction
export default class Tasks extends ApiBase {

  /**
   * Count tasks
   *
   * @return {Promise}
   */
  count(options = {}) {
    return this.authRequest('GET', 'tasks/count', { ...options })
  }

  /**
   * Create task
   *
   * @return {Promise}
   */
  create(data = {}) {
    return this.authRequest('POST', 'tasks', { data })
  }

  /**
   * Complete task
   *
   * @return {Promise}
   */
  complete(id, data = {}) {
    return this.authRequest('PATCH', `tasks/${id}/complete`, { data })
  }

  /**
   * Save task
   *
   * @return {Promise}
   */
  update(id, data = {}) {
    return this.authRequest('PATCH', `tasks/${id}`, { data })
  }

  /**
   * Load list tasks
   *
   * @return {Promise}
   */
  list(search, filter, sortBy, sortType, options = {}) {
    return this.authRequest('GET', `tasks?search=${search}&filter=${filter}&sortBy=${sortBy}&sortType=${sortType}`, { ...options })
  }

  /**
   * Delete task
   *
   * @return {Promise}
   */
  delete(id) {
    return this.authRequest('DELETE', `tasks/${id}`)
  }
}

export const getTasks = (filter = 'all', search = '') => {
  return client.get('tasks', { filter, search })
}

export const deleteTask = (id) => {
  return client.delete(`tasks/${id}`)
}

export const completeTask = (id, complete = true) => {
  return client.patch(`tasks/${id}/complete`, { is_completed: complete})
}

/**
 *
 * @param {number} id
 * @param {object} data
 * @param {boolean} data.is_completed,
 * @param {number} data.project_id
 * @param {string} data.name
 * @param {string} data.due_date
 * @returns {Promise<[*,null]|[*,(*|[*]|[string])]|undefined>}
 */
export const updateTask = (id, data) => {
  return client.patch(`tasks/${id}`, data)
}

/**
 *
 * @param {object} data
 * @param {boolean} data.is_completed,
 * @param {number} data.project_id
 * @param {string} data.name
 * @param {string} data.due_date
 * @returns {Promise<[*,null]|[*,(*|[*]|[string])]|undefined>}
 */
export const createTask = (data) => {
  return client.post('tasks', data)
}

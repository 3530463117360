import ApiBase from '@/api/ApiBase'

// Company API Abstraction
export default class Company extends ApiBase {


  /**
   * Gamification
   *
   * @param {String} company
   * @param  {Object} data
   * @return {Promise}
   */
  gamification(company, data = {}, options = {}) {
    return this.authRequest('POST', `${company}/gamification` , { data, ...options })
  }

  /**
   * Request payout
   *
   * @param {String} company
   * @param  {Object} data
   * @return {Promise}
   */
  requestPayout(company, data = {}, options = {}) {
    return this.authRequest('PUT', `${company}/setting/payout` , { data, ...options })
  }

  /**
   * Patch payment info
   *
   * @param {String} company
   * @param  {Object} data
   * @return {Promise}
   */
  patchPaymentInfo(company, data = {}, options = {}) {
    return this.authRequest('PUT', `${company}/setting/payment-info` , { data, ...options })
  }

  /**
   * Patch default language
   *
   * @param {String} company
   * @param  {Object} data
   * @return {Promise}
   */
  patchLanguage(company, data = {}, options = {}) {
    return this.authRequest('PUT', `${company}/setting/language` , { data, ...options })
  }

  /**
   * Delete custom language
   *
   * @param {String} company
   * @param  {Object} data
   * @return {Promise}
   */
  destroyLanguage(company, code, data = {}, options = {}) {
    return this.authRequest('DELETE', `${company}/setting/language/${code}`, { data, ...options })
  }

  /**
   * Upload logo
   *
   * @param {String} company
   * @param  {Object} data
   *
   * @return {Promise}
   */
  uploadLogo(company, data) {
    return this.authRequest('POST', `company/${company}/upload`, { data })
  }

  /**
   * Remove logo
   *
   * @param {String} company
   * @param  {Object} data
   *
   * @return {Promise}
   */
  removeLogo(company, data) {
    return this.authRequest('DELETE', `company/${company}/logo`, { data })
  }

  /**
   * Patch company tax settings
   *
   * @param {String} company
   * @param  {Object} data
   * @return {Promise}
   */
  patchTax(company, data = {}, options = {}) {
    return this.authRequest('PATCH', `company/${company}/tax` , { data, ...options })
  }

  /**
   * Patch company invoice settings
   *
   * @param {String} company
   * @param  {Object} data
   * @return {Promise}
   */
  patchInvoice(company, data = {}, options = {}) {
    return this.authRequest('PATCH', `company/${company}/invoice` , { data, ...options })
  }

  /**
   * Patch company details
   *
   * @param {String} company
   * @param  {Object} data
   * @return {Promise}
   */
  patch(company, data = {}, options = {}) {
    return this.authRequest('PATCH', `company/${company}` , { data, ...options })
  }

  /**
   * Get company details
   * @param  {String} company
   * @return {Promise}
   */
  getDetails(company, options = {}) {
    return this.authRequest('GET', `company/${company}`, options)
  }
}

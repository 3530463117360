<style lang="scss" src="./style.scss"></style>
<template>
  <div class="VueTables__search-field">
    <input
      :id="`VueTables__search_${props.id}`"
      :class="`VueTables__search__input ${props.theme.input} ${props.theme.small}`"
      type="text"
      :placeholder="props.display('filterPlaceholder')"
      autocomplete="off"
      @keyup="e=>props.search(props.opts.debounce)(e)"
    >
  </div>
</template>

<script>
export default {
    name: 'VDatatableFilter',
    props: ['props']
}
</script>

<style lang="scss">
  .btn {
    &--dropdown-item {
      &,
      .btn__content-text {
        &,
        &:hover,
        &:focus {
          color: #000;
        }
      }
    }
  }
</style>
<template>
  <v-button
    v-close-popover="!keepOpen"
    class="btn--dropdown-item"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-button>
</template>
<script>
import VButton from '@/components/VButton'

export default {
  name: 'VDropdownItem',
  components: {
    VButton
  },
  inheritAttrs: false,
  props: {
    keepOpen: {
      type: Boolean,
      default: false
    }
  }
}
</script>

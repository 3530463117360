<style lang="scss">
  .tutorial-btn{
    background:#F6F6F6;
    border:1px solid #E4E4E4;
    border-radius:4px;
    padding:9px 8px 7px 8px;
    cursor:pointer;
    height:42px;

    .ico{
      padding:0 0 0 0;
    }

    &:hover{
      background: darken(#F6F6F6, 2%);
    }

    &.dark{
      color:#B1B6CD;
      background:#3F4354;
      border:1px solid #3F424F;

      &:hover{
        background: lighten(#3F4354, 4%);
      }
    }
  }
</style>
<template>
  <div
    class="tutorial-btn flex items-center"
    :class="[mode == 'dark' ? 'dark' : '']"
    @click="$emit('click')"
  >
    <div class="mr-2">
      <span v-if="mode == 'light'">
        <img
          class="ico align-middle"
          :src="require(`@/assets/img/icons/ico-tutorial-dark.svg`)"
        >
      </span>
      <span v-else>
        <img
          class="ico align-middle"
          :src="require(`@/assets/img/icons/ico-tutorial-light.svg`)"
        >
      </span>
    </div>
    <div>
      <div class="text">
        Tutorial
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VCheckbox',
  props: {
    mode: {
      type: String,
      default: 'light'
    },
  }
}
</script>

import ApiBase from '@/api/ApiBase'

// Discount API Abstraction
export default class DiscountCode extends ApiBase {

  /**
   * Apply discount code
   * @param  {String}
   * @return {Promise}
   */
  apply(company, data = {}) {
    return this.authRequest('POST', `${company}/discount-code/apply`, { data } )
  }

  /**
   * Remove discount code
   * @param  {String}
   * @return {Promise}
   */
  remove(company, data = {}) {
    return this.authRequest('POST', `${company}/discount-code/remove`, { data } )
  }
}

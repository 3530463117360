<template>
  <div>
    <div class="block lg:hidden">
      <div class="bg-grey-black px-4">
        <div class="select-container w-full my-5">
          <select
            v-model="templateNav"
            @change="changeNav"
          >
            <option
              value="/templates"
            >
              Lead forms
            </option>
            <option
              value="/templates/proposals"
            >
              Proposals
            </option>
            <option
              value="/templates/invoices"
            >
              Invoices
            </option>
            <option
              value="/templates/contracts"
            >
              Contracts
            </option>
            <option
              value="/templates/products"
            >
              Products
            </option>
            <option
              value="/templates/mails"
            >
              Emails
            </option>
            <option
              value="/templates/questionnaires"
            >
              Questionnaires
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="hidden lg:block">
      <h1 class="text-black text-3xl mb-5">
        Templates
      </h1>
      <router-link
        to="/templates"
        exact
        class="nav"
      >
        <v-row class="items-center">
          <v-col>
            <v-image
              :src="require('@/assets/img/icons/leadform.svg')"
              :width="24"
              class="ml-3 mr-4"
            />
          </v-col>
          <v-col>
            Lead forms
          </v-col>
        </v-row>
      </router-link>
      <router-link
        to="/templates/proposals"
        class="nav"
      >
        <v-row class="items-center">
          <v-col>
            <v-image
              :src="require('@/assets/img/icons/proposal.svg')"
              :width="24"
              class="ml-3 mr-4"
            />
          </v-col>
          <v-col>
            Proposals
          </v-col>
        </v-row>
      </router-link>
      <router-link
        to="/templates/invoices"
        class="nav"
      >
        <v-row class="items-center">
          <v-col>
            <v-image
              :src="require('@/assets/img/icons/invoice.svg')"
              :width="24"
              class="ml-3 mr-4"
            />
          </v-col>
          <v-col>
            Invoices
          </v-col>
        </v-row>
      </router-link>
      <router-link
        to="/templates/contracts"
        class="nav"
      >
        <v-row class="items-center">
          <v-col>
            <v-image
              :src="require('@/assets/img/icons/contract.svg')"
              :width="24"
              class="ml-3 mr-4"
            />
          </v-col>
          <v-col>
            Contracts
          </v-col>
        </v-row>
      </router-link>
      <router-link
        to="/templates/products"
        class="nav"
      >
        <v-row class="items-center">
          <v-col>
            <v-image
              :src="require('@/assets/img/icons/product.svg')"
              :width="24"
              class="ml-3 mr-4"
            />
          </v-col>
          <v-col>
            Products
          </v-col>
        </v-row>
      </router-link>
      <router-link
        to="/templates/mails"
        class="nav"
      >
        <v-row class="items-center">
          <v-col>
            <v-image
              :src="require('@/assets/img/icons/email.svg')"
              :width="24"
              class="ml-3 mr-4"
            />
          </v-col>
          <v-col>
            Emails
          </v-col>
        </v-row>
      </router-link>
      <router-link
        to="/templates/questionnaires"
        class="nav"
      >
        <v-row class="items-center">
          <v-col>
            <v-image
              :src="require('@/assets/img/icons/ico-questionnaire.svg')"
              :width="24"
              class="ml-3 mr-4"
            />
          </v-col>
          <v-col>
            Questionnaires
          </v-col>
        </v-row>
      </router-link>

      <router-link
        to="/templates/workflows"
        class="nav"
      >
        <v-row class="items-center">
          <v-col>
            <v-image
              :src="require('@/assets/img/icons/ico-workflow.svg')"
              :width="24"
              class="ml-3 mr-4"
            />
          </v-col>
          <v-col>
            Workflows
          </v-col>
        </v-row>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TemplateMenu',
  data(){
    return {
      templateNav: '/templates',
    }
  },
  mounted(){
    this.templateNav = this.$router.currentRoute.path
  },
  methods:{
    changeNav(){
      this.$router.push(this.templateNav)
    }
  }
}
</script>

<template>
  <div class="row">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'VRow'
}
</script>

<style lang="scss">
$gap: 10px;

.row {
  display: flex;
  flex-wrap: wrap;
  padding-top: 2px;
  padding-bottom: 2px;

  margin-left: -15px;
  margin-right: -15px;

  &--center {
    justify-content: center;
  }
}
</style>

import axios from 'axios'
import config from '@/config'

export const createHttpClient = (options = {}) => axios.create({
  timeout: config.apiTimeout,
  ...options
})

export const createCancelSource = () => {
  const CancelToken = axios.CancelToken
  return CancelToken.source()
}

const apiHttpClient = createHttpClient({
  baseURL: config.apiBaseUrl,
  timeout: config.apiTimeout,
  headers: {
    'X-APP-VERSION': config.appVersion
  }
})

export default apiHttpClient

import { ServerTable } from 'vue-tables-2'
import VDatatableFilter from '@/components/VDatatableFilter'
import VDatatablePerPage from '@/components/VDatatablePerPage'
import VDatatable from '@/components/VDatatable'
import VDatatableSortControl from '@/components/VDatatableSortControl'

export default {
  expose(Vue) {
    Vue.use(ServerTable, {}, false, 'bootstrap4', {
        genericFilter: VDatatableFilter,
        perPageSelector: VDatatablePerPage,
        sortControl: VDatatableSortControl,
    })
  }
}

import { AppLayout as Main } from '@/layouts'
import { createSubRoutes } from '@/router/helpers'
import { view } from '@/helpers'
import { applyMiddleware } from '@/middleware'

export default createSubRoutes('/', Main, [
  {
    path: '/learning',
    name: 'Learning',
    component: view('Learning'),
   },
  // {
  //   path: '/',
  //   name: 'home',
  //   component: view('Projects')
  // },
  // {
  //   path: '/embed/lead/form/:token',
  //     name: 'LeadForm',
  //     meta: {
  //         hideHeader: true,
  //         hideFooter: true
  //     },
  //     component: view('LeadForm'),
  // }
  // ,{
  //   path: '/view/:projectId/proposal/:token',
  //   name: 'ProposalView',
  //   meta: {
  //       hideHeader: true,
  //       hideFooter: true
  //   },
  //   component: view('ProposalPreview')
  // },{
  //   path: '/view/:projectId/invoice/:token',
  //   name: 'InvoiceView',
  //   meta: {
  //       hideHeader: true,
  //       hideFooter: true
  //   },
  //   component: view('InvoicePreview')
  // }
])

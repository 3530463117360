import ApiBase from '@/api/ApiBase'

// Health API Abstraction
export default class Health extends ApiBase {
  /**
   * Keep checking session
   *
   * @return {Promise}
   */
  ping() {
    return this.authRequest('GET', 'ping')
  }
}

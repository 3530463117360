import config from '@/config'
import auth from '@/auth'
import httpClient from '@/http'

export default class ApiBase {
  constructor() {
    this.httpClient = httpClient
    this.auth = auth
  }

  /**
   * Make http request
   *
   * @param  string method
   * @param  string url
   * @param  Object options
   * @return Promise
   */
  request(method, url, options = {}) {
    return this.httpClient.request({
      url,
      headers: {
        'X-Transaction-ID': config.transactionId
      },
      method,
      ...options
    })
  }

  /**
   * Authenticate including auth headers
   */
  authRequest(method, url, options = {}) {
    const authHeader = `Bearer ${this.auth.getToken()}`
    return this.request(method, url, {
      authRequest: true,
      headers: {
        Authorization: authHeader
      },
      ...options
    })
  }
}

import auth from '@/auth'
import Api from '@/api'

const user = auth.user()
const company = user && user.companySettings || {}

export default {
  namespaced: true,
  state: {
    company,
    team: {}
  },
  mutations: {
    /**
     * Set current company
     */
    SET_COMPANY(state, company) {
      state.company = company
    },
    /**
     * Set company users
     */
    SET_COMPANY_TEAM(state, team = {}) {
      state.team = team
    }
  },
  actions: {

    /**
     * Extend trial
     */
    async extendTrial({ commit, state, dispatch }, input = {}) {
      const { id } = state.company
      const { data } = await Api.get('subscription').extendTrial(id, input)
      commit('SET_COMPANY', data)

      dispatch('auth/refreshUser', null, {root:true})
    },

    /**
     * Gamification
     */
    async gamification({ commit, state, dispatch }, input = {}) {
      const { id } = state.company
      const { data } = await Api.get('company').gamification(id, input)

      commit('SET_COMPANY', data)
      dispatch('auth/refreshUser', null, {root:true})
    },

    /**
     * Upload company logo
     */
    async uploadLogo({ commit, state }, input = {}) {
      const { id } = state.company
      const { data } = await Api.get('company').uploadLogo(id, input)
    },
    /**
     * Refresh company
     */
    async refreshCompanyDetails({ commit, state, dispatch }) {
      const { id } = state.company
      const { data } = await Api.get('company').getDetails(id)
      commit('SET_COMPANY', data)
      //dispatch('refreshCompanyTeam')
    },

    /**
     * Update company
     */
    async patch({ commit, state }, input = {}) {
      const { id } = state.company
      const { data } = await Api.get('company').patch(id, input)
      commit('SET_COMPANY', data)
    },

    /**
     * Update company invoice setting
     */
    async patchInvoice({ commit, state }, input = {}) {
      const { id } = state.company
      const { data } = await Api.get('company').patchInvoice(id, input)
      commit('SET_COMPANY', data)
    },

    /**
     * Update language setting
     */
    async patchLanguage({ commit, state }, input = {}) {
      const { id } = state.company
      const { data } = await Api.get('company').patchLanguage(id, input)
      commit('SET_COMPANY', data)
    },

    /**
     * Update payment info
     */
    async patchPaymentInfo({ commit, state }, input = {}) {
      const { id } = state.company
      const { data } = await Api.get('company').patchPaymentInfo(id, input)
      commit('SET_COMPANY', data)
    },

    /**
     * Update tax invoice setting
     */
    async patchTax({ commit, state }, input = {}) {
      const { id } = state.company
      const { data } = await Api.get('company').patchTax(id, input)
      commit('SET_COMPANY', data)
    },
    /**
     * Get members inside a company
     */
    async refreshCompanyTeam({ commit, state }) {
      const { id } = state.company
      const { data } = await Api.get('teammates').members(id)
      commit('SET_COMPANY_TEAM', data)
    },

    async sendInvitation({ state, dispatch }, input = {}) {
      const { id } = state.company
      const { data } = await Api.get('teammates').sendInvitation(id, input)
      dispatch('refreshCompanyTeam')
    }
  },
  getters: {
    id: state => state.company && state.company.id,
    details: state => state.company,
    name: state => state.company && state.company.name || '',
    team: state => state.team || {}
  }
}

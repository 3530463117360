import { AppLayout as Main } from '@/layouts'
import { createSubRoutes } from '@/router/helpers'
import { view } from '@/helpers'

export default createSubRoutes('/', Main, [
  {
    path: '/calendar',
    name: 'Calendar',
    component: view('Calendar')
  },
])

import auth from '@/auth'
import Api from '@/api'

// Initial state for authenticated
const authenticated = auth.check()
const user = auth.user()

export default {
  namespaced: true,
  state: {
    authenticated,
    user
  },
  mutations: {
    /**
     * Set current user authenciation status
     */
    SET_AUTHENTICATED(state, status) {
      state.authenticated = status
    },
    /**
     * Set current user
     */
    SET_USER(state, user) {
      state.user = user
    }
  },
  actions: {
    /**
     * Login user
     */
    async login({ commit, dispatch }, credentials = {}) {

      await auth.attempt(credentials)

      const user = auth.user()
      commit('SET_AUTHENTICATED', true)
      commit('SET_USER', user)

      if(user.company){
        const company = user.companySettings
        commit('company/SET_COMPANY', company, { root: true })
      }
    },
    async loginAs({ commit, dispatch }, token = {}) {
      await auth.loginAs(token)

      const user = auth.user()
      commit('SET_AUTHENTICATED', true)
      commit('SET_USER', user)

      if(user.company){
        const company = user.companySettings
        commit('company/SET_COMPANY', company, { root: true })
      }
    },
    /**
     * Patch user data
     */
    async patchUser({ commit, dispatch }, form = {}) {
      const { data } = await Api.get('user').patch(form)
      auth.saveUser(data.me)

      commit('SET_USER', data.me)
    },
    /**
     * Refresh user
     */
    async refreshUser({ commit, state, dispatch }) {
      const { id } = auth.user()
      const { data } = await Api.get('user').getDetails(id)
      commit('SET_USER', data)

      if(data.company){
        const company = data.companySettings
        commit('company/SET_COMPANY', company, { root: true })
      }

      //dispatch('refreshCompanyTeam')
    },
    /**
     * Register user data
     */
    async createUser({ commit, dispatch }, form = {}) {
      const { data } = await Api.get('register').createAccount(form)

      auth.saveUser(data.me)
      auth.saveToken(data.access_token)

      commit('SET_AUTHENTICATED', true)
      commit('SET_USER', data.me)
    },
    /**
     * Register company data
     */
    async createCompany({ commit, dispatch }, form = {}) {

      var { data } = await Api.get('register').createCompany(form)

      const { id } = auth.user()
      var { data } = await Api.get('user').getDetails(id)

      auth.saveUser(data)
      commit('SET_USER', data)

      const company = data.companySettings
      commit('company/SET_COMPANY', company, { root: true })
    },

    /**
     * Clear local store
     */
    clearStore({ commit }) {
      commit('SET_AUTHENTICATED', false)
      commit('SET_USER', null)
    },
    /**
     * Logout user
     */
    async logout({ dispatch }, token = null) {
      dispatch('clearStore')
      await auth.logout(token)
    }
  },
  getters: {
    user: state => (state.user) ? state.user : null,
    userId: state => state.user && state.user.id,
    emailSignature: state => (state.user && state.user.me.email_signature) ? state.user.me.email_signature : null,
  }
}

import ApiBase from '@/api/ApiBase'

// Promo API Abstraction
export default class Stripe extends ApiBase {

  /**
   * Create payment intent
   * @param  {String}
   * @return {Promise}
   */
  create(company, data = {}) {
    return this.request('POST', 'view/stripe/create', { data } )
  }
  ideal(company, data = {}) {
    return this.authRequest('POST', `${company}/integration/stripe/ideal`, { data } )
  }
  bancontact(company, data = {}) {
    return this.authRequest('POST', `${company}/integration/stripe/bancontact`, { data } )
  }
  giropay(company, data = {}) {
    return this.authRequest('POST', `${company}/integration/stripe/giropay`, { data } )
  }
  log(company, invoiceId, redirectStatus) {
    return this.request('POST', `view/stripe/log`, {'data': { invoiceId, redirectStatus }} )
  }

  /**
   * Link account
   * @param  {String}
   * @return {Promise}
   */
  link(company, data = {}) {
    return this.authRequest('POST', `${company}/integration/stripe/connect`, { data } )
  }

  disconnect(company, data = {}) {
    return this.authRequest('POST', `${company}/integration/stripe/disconnect`, { data } )
  }
}

<style lang="scss" src="./style.scss"></style>
<template>
  <!-- <div class="select-container">
        <select :id="props.selectAttrs.id"
                :class="props.selectAttrs.class"
                :value="props.selectAttrs.value"
                @change="props.selectEvents.change">
            <option v-for="val in props.perPageValues" :value="val">{{val}}</option>
        </select>
    </div> -->
</template>

<script>
    export default {
        name: 'VDatatablePerPage',
        props: ['props']
    }
</script>

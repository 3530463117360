import { AppLayout as Main } from '@/layouts'
import { createSubRoutes } from '@/router/helpers'
import AuthMiddleware from '@/middleware/auth'
import { view } from '@/helpers'

export default createSubRoutes('/', Main, [
{
    path: '/templates/questionnaires',
    name: 'TemplatesQuestionnaireList',
    component: view('Templates/TemplatesQuestionnaireList')
  },
  {
    path: '/templates/questionnaires/create',
    name: 'TemplatesQuestionnaireCreate',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
    },
    component: view('Templates/TemplatesQuestionnaire')
  },
  {
    path: '/templates/questionnaires/:templateId',
    name: 'TemplatesQuestionnaire',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
    },
    component: view('Templates/TemplatesQuestionnaire')
  },
  {
    path: '/templates',
    name: 'TemplatesLeadFormList',
    component: view('Templates/TemplatesLeadFormList')
  },
  {
    path: '/templates/lead-forms/create',
    name: 'TemplatesLeadFormCreate',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
    },
    component: view('Templates/TemplatesLeadForm')
  },
  {
    path: '/templates/lead-forms/:templateId',
    name: 'TemplatesLeadForm',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
    },
    component: view('Templates/TemplatesLeadForm')
  },
  {
    path: '/templates/contracts',
    name: 'TemplatesContractList',
    component: view('Templates/TemplatesContractList')
  },
  {
    path: '/templates/contracts/create',
    name: 'TemplatesContractCreate',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
    },
    component: view('Templates/TemplatesContract')
  },
  {
    path: '/templates/contracts/:templateId',
    name: 'TemplatesContract',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
    },
    component: view('Templates/TemplatesContract')
  },
  {
    path: '/templates/products',
    name: 'TemplatesProductList',
    component: view('Templates/TemplatesProductList')
  },
  {
    path: '/templates/products/create',
    name: 'TemplatesProductCreate',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
    },
    component: view('Templates/TemplatesProduct')
  },
  {
    path: '/templates/products/:templateId',
    name: 'TemplatesProduct',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
    },
    component: view('Templates/TemplatesProduct')
  },
  {
    path: '/templates/mails',
    name: 'TemplatesMailList',
    component: view('Templates/TemplatesMailList')
  },
  {
    path: '/templates/mails/create',
    name: 'TemplatesMailCreate',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
    },
    component: view('Templates/TemplatesMail')
  },
  {
    path: '/templates/mails/:templateId',
    name: 'TemplatesMail',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
    },
    component: view('Templates/TemplatesMail')
  },
  {
    path: '/templates/proposals',
    name: 'TemplatesProposalList',
    component: view('Templates/TemplatesProposalList')
  },
  {
    path: '/templates/proposals/create',
    name: 'TemplatesProposalCreate',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
    },
    component: view('Templates/TemplatesProposal')
  },
  {
    path: '/templates/proposals/:templateId',
    name: 'TemplatesProposal',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
    },
    component: view('Templates/TemplatesProposal')
  },
  {
    path: '/templates/invoices',
    name: 'TemplatesInvoiceList',
    component: view('Templates/TemplatesInvoiceList')
  },
  {
    path: '/templates/invoices/create',
    name: 'TemplatesInvoiceCreate',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
    },
    component: view('Templates/TemplatesInvoice')
  },
  {
    path: '/templates/invoices/:templateId',
    name: 'TemplatesInvoice',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
    },
    component: view('Templates/TemplatesInvoice')
  },
  {
    path: '/templates/workflows',
    name: 'TemplatesWorkflowList',
    component: view('Templates/TemplatesWorkflowList')
  },
  {
    path: '/templates/workflows/create',
    name: 'TemplatesWorkflowCreate',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
      mode: 'create'

    },
    component: view('Templates/TemplatesWorkflow')
  },
  {
    path: '/templates/workflows/:workflowId/edit',
    name: 'TemplatesWorkflowEdit',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
      mode: 'edit'
    },
    component: view('Templates/TemplatesWorkflow')
  },
  {
    path: '/templates/workflows/:workflowId/manage',
    name: 'TemplatesWorkflowManage',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: false,
    },
    component: view('Templates/TemplatesWorkflowManage')
  }
])

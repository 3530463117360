import { ClientTable } from 'vue-tables-2'
import VDatatable from '@/components/VDatatable'
import VDatatableSortControl from '@/components/VDatatableSortControl'

export default {
  expose(Vue) {
    Vue.use(ClientTable, {}, false, 'bootstrap4', {
        sortControl: VDatatableSortControl,
    })
  }
}

<style lang="scss">
  .octoa-notification{
    .close-octoa{
      min-width:28px;
    }
    .close{
      position: absolute;
      top:-10px;
      right:-10px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      cursor: pointer;
      background-color: #698FFF;

      &:before,
      &:after {
          position: absolute;
          left: 10px;
          content: ' ';
          height: 12px;
          top: 4px;
          width: 1px;
          background:#fff;
      }
      &:before {
          transform: rotate(45deg);
      }
      &:after {
          transform: rotate(-45deg);
      }

      &:hover{
           @apply bg-green;
      }
    }
  }
</style>
<template>
  <div
    v-show="isVisible"
  >
    <div class="octoa-notification flex flex-wrap mx-auto max-w-2xl items-center px-2 py-3 mb-5 bg-blue-washed-light rounded rounded border-solid text-sm ">
      <div class="w-2/12 md:w-10 md:text-center">
        <v-image
          :src="require('@/assets/img/icons/ico-alert.svg')"
          :width="21"
          class="align-bottom"
        />
      </div>
      <div
        class="w-10/12 md:w-11/12 px-2 text-left text-grey-darker"
        v-html="content"
      />
      <div
        v-if="!hideClose"
        class="close"
        @click="close"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'VNotification',
  props: {
    content: {
      type: String,
      default: null
    },
    hideClose: {
      type: Boolean,
      default: false
    },
  },
  data(){
      return {
        isVisible: true,
      }
  },
  methods: {
    close(){
      this.isVisible = false
    }
  }
}
</script>

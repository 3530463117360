import { AppLayout as Main } from '@/layouts'
import { createSubRoutes } from '@/router/helpers'
import { view } from '@/helpers'
import AuthMiddleware from '@/middleware/auth'

export default createSubRoutes('/', Main, [
  {
    path: '/projects/:id/questionnaire/choose',
    name: 'QuestionnaireChoose',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
      hideFooter: true
    },
    component: view('Questionnaires/QuestionnaireChoose')
  },
  {
    path: '/projects/:id/questionnaire/create',
    name: 'QuestionnaireCreate',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
      hideFooter: true
    },
    component: view('Questionnaires/QuestionnaireEdit')
  },{
    path: '/projects/:id/questionnaire/:questionnaireId',
    name: 'QuestionnaireDetail',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
      hideFooter: true
    },
    component: view('Questionnaires/QuestionnaireEdit')
  },
  {
    path: '/questionnaire/preview/:questionnaireId',
    name: 'QuestionnairePreview',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
      hideFooter: true
    },
    component: view('Questionnaires/QuestionnairePreview')
  }
])

import ApiBase from '@/api/ApiBase'

// Register API Abstraction
export default class Register extends ApiBase {
  /**
   * Create new account
   *
   * @param  {Object} data
   * @return {Promise}
   */
  createAccount(data = {}, options = {}) {
    return this.request('POST', '/register/account', { data, ...options })
  }

  /**
   * Check if existing email exist
   *
   * @param  {Object} data
   * @return {Promise}
   */
  emailCheck(data = {}, options = {}) {
    return this.request('POST', '/register/email', { data, ...options })
  }

  /**
   * Create new company
   *
   * @param  {Object} data
   * @return {Promise}
   */
  createCompany(data = {}, options = {}) {
    return this.request('POST', '/register/company', { data, ...options })
  }

  /**
   * Create new first lead form data
   *
   * @param  {Object} data
   * @return {Promise}
   */
  // createWizard(data = {}, options = {}) {
  //   return this.request('POST', '/register/wizard', { data, ...options })
  // }
}

<style>
  .simulate-button {
    position: fixed;
    left: 10px;
    top: 10px;
    color: #fff;
    background-color: red;
    padding: 5px;
    border-radius: 3px;
    z-index: 1000;
    display: none;
  }
</style>

<template>
  <div class="main">
    <router-view />
    <div
      v-if="$route.name != 'forgetPassword' && $route.name != 'resetPassword' && $route.name != 'Register' &&
      $route.name != 'Login' && $route.name != 'QuestionnaireView' && $route.name != 'InvoiceView' &&
      $route.name != 'ProposalView' && $route.name != 'LeadForm' && updateAvailable && $route.name != 'InvoiceWithoutProjectView'"
      class="new-version items-center flex"
    >
      <div class="px-4 py-6 md:py-5 icon">
        <img
          src="@/assets/img/icons/ico-alert-circle.svg"
          width="25"
        >
      </div>
      <div class="px-4 py-3">
        <strong>New version available!</strong>
        <div class="text">
          Save what you are doing and <span @click="refreshApp">update Octoa</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from '@/bus'

export default {
  name: 'App',
  data() {
    return {
      refreshing: false,
      registration: null,
      updateAvailable: false,
      updateServiceWorkerRequested: false
    }
  },
  watch: {
    updateAvailable(v) {
      console.log('Update available: '+v)
      v && this.createToast()
    }
  },
  created() {

    Bus.$on('requestAppRefresh', () => {
      console.log('Refresh requested')
      this.refreshApp()
    })

    Bus.$on('requestServiceWorkerUpdate', () => {
      console.log('Service worker update requested')
      this.updateServiceWorker()
    })

    document.addEventListener('swUpdated', (e) => {
      console.log('Service worker updated')
      this.registration = e.detail
      if (this.updateServiceWorkerRequested) {
        console.log('swUpdate: updateServiceWorkerRequested so refreshApp')
        this.refreshApp()
        this.updateServiceWorkerRequested = false
        return
      }

      this.updateAvailable = true
    }, { once: true })

    document.addEventListener('swRegistered', (e) => {
      console.log('Service worker registered')
      this.registration = e.detail
    }, { once: true })


    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        this.reloadApp()
      })
    } else {
       console.log('No service worker')
    }

    // navigator.serviceWorker.addEventListener('controllerchange', () => {
    //   this.reloadApp()
    // })
    //
    // navigator.serviceWorker.register('/service-worker.js').then(reg => {
    //   reg.onupdatefound = () => {
    //     const installingWorker = reg.installing;
    //     installingWorker.onstatechange = () => {
    //       if (installingWorker.state === 'installed' &&
    //           navigator.serviceWorker.controller) {

    //         this.reloadApp()
    //       }
    //     };
    //   };
    // });
  },
  methods: {
    updateServiceWorker() {
      if (!this.registration) {
        console.log('Update service worker: Invalid registration')
        return
      }
      console.log('Update service worker: Updating')
      this.updateServiceWorkerRequested = true
      this.registration.update()
    },
    reloadApp() {
      if (this.refreshing) {
        return
      }
      console.log('Reloading app')
      this.refreshing = true
      window.location.reload()
    },
    refreshApp() {

      this.updateAvailable = false

      if (!this.registration || !this.registration.waiting) {
        console.log('Refresh app: Invalid registration')
        return
      }

      console.log('Refresh app: Refreshing app')
      this.registration.waiting.postMessage('skipWaiting')

    },
    createToast() {

      if (this.$options.toastEl) {
        this.$options.toastEl.goAway(0)
      }

      this.$store.dispatch('setHasNewUpdate', true)

      // this.$options.toastEl = this.$toasted.show('New version available!', {
      //   duration: null,
      //   className: 'toaster__update-app',
      //   action: {
      //     text: 'update Octoa',
      //     onClick: () => this.refreshApp()
      //   }
      // })

      return true
    }
  }
}
</script>


<style lang="scss">
@import '@/style/base.scss';

html, body, .main {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  color: color(body, text);
}
.new-version{
  position:fixed;
  bottom:30px;
  left:20px;
  max-width:50%;
  background-color:#698ffe;
  border-radius:4px;
  color:#fff;
  z-index:900000000;

  @media (min-width: 640px) {
    left:30px;
    max-width:90%;
  }

  .icon{
    background-color:#5c85fb;
    border-radius:4px 0 0 4px;
  }

  @media (max-width: 640px) {
    .icon{
      display:none;
    }
  }

  .text{
    margin-top:5px;
    span{
      text-decoration:underline;
      cursor:pointer;
    }
  }

}
</style>

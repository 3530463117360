<template>
  <component
    :is="component"
    v-bind="$attrs"
    :type="htmlType"
    :disabled="disabled || loading"
    :tabindex="tabIndex"
    :class="{
      'btn': true,
      'button': component === 'button',
      'btn--disabled': disabled,
      'btn--loading': loading,
    }"
    role="button"
    @click.capture="handleClick"
  >
    <span
      v-if="iconLeft || loading"
      class="btn__content-icon btn__content-icon--left"
    >
      <v-icon
        v-if="!loading"
        :icon="iconLeft"
      />
      <v-icon
        v-if="loading"
        icon="spinner"
        spin
      />
    </span>
    <span class="btn__content-text">
      <slot>
        {{ label }}
      </slot>
    </span>
    <span
      v-if="iconRight"
      class="btn__content-icon btn__content-icon--right"
    >
      <v-icon :icon="iconRight" />
    </span>
  </component>
</template>

<script>
import VIcon from '@/components/VIcon'

export default {
  name: 'VButton',
  components: {
    VIcon
  },
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    iconLeft: {
      type: String,
      default: null
    },
    iconRight: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button'
    },
    label: {
      type: String,
      default: null
    }
  },
  computed: {
    htmlType() {
      if (this.component === 'button') {
        return this.type
      }
      return ''
    },
    tabIndex() {
      if (this.loading || this.disabled) {
        return '-1'
      }

      return '0'
    },
    component() {
      if (this.$attrs.to) {
        return 'router-link'
      }
      if (this.$attrs.href) {
        return 'a'
      }
      return 'button'
    }
  },
  methods: {
    handleClick(event) {
      this.$emit('click', event)
    }
  }
}
</script>

<style lang="scss">
.btn {
  &[button] {
    -webkit-appearance: button;
  }

  display: inline-block;
  text-align: center;
  align-items: center;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: bold;
  user-select: none;
  line-height: 1.5;
  transition: all 0.3s ease-in-out;
  overflow: visible;
  padding: 6px 12px;
  border-radius: 4px;
  text-decoration: none;

  &:focus,
  &:hover {
    outline: none;
  }

  &__content-text {
    display: inline-flex;
  }

  &__content-icon {
    position: relative;
    width: 18px;
    height: 18px;

    svg {
      height: 100%;
    }

    &--left {
      margin-right: 10px;
    }

    &--right {
      margin-left: 10px;
    }
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &[disabled],
  &--disabled,
  &--loading {
    cursor: not-allowed;
    opacity: 0.75;
  }

  &--block {
    display: block;
    width: 100%;
  }

  &--tab {
    font-weight: 600;
    background-color: transparent;
    color: #000;
    padding: 10px 12px;

    .btn__content-text {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;

      img {
        margin-right: 10px;
      }
    }

    &:hover,
    &:focus,
    &.active {
      background-color: #cdd8e5;
    }
  }

  &--accent-link {
    color: color(accent);
  }

  @include button-variation("&--secondary", color(btn, secondary));

  @include button-variation("&--error", color(btn, error));

  @include button-variation("&--sidebar", color(btn, sidebar)) {
    padding: 8px 12px;
  }
}
</style>

import VuePlyr from 'vue-plyr'

export default {
  expose(Vue) {
    Vue.use(VuePlyr, {
      plyr: {
        fullscreen: { enabled: true },
      },
      emit: ['ended']
    })
  }
}

import store from '@/plugins/store'
import router from '@/plugins/router'
import http from '@/plugins/http'
import api from '@/plugins/api'
import veeValidate from '@/plugins/vee-validate'
import vToast from '@/plugins/v-toast'
import vTooltip from '@/plugins/v-tooltip'
import storage from '@/plugins/storage'
import auth from '@/plugins/auth'
import globalComponents from '@/plugins/global-components'
import layouts from '@/plugins/layouts'
import globalFilters from '@/plugins/global-filters'
import alert from '@/plugins/alert'
import mask from '@/plugins/mask'
import vClipboard from '@/plugins/v-clipboard'
import vInstant from '@/plugins/v-instant'
import vModal from '@/plugins/v-modal'
import vNumeric from '@/plugins/v-numeric'
import vAutosuggest from '@/plugins/v-autosuggest'
import vTextareaAutoresize from '@/plugins/v-textarea-autoresize'
import config from '@/plugins/config'
import vCalendar from '@/plugins/v-calendar'
import vPlyr from '@/plugins/v-plyr'
import vAutocomplete from '@/plugins/v-autocomplete'
import vServerTable from '@/plugins/v-server-table'
import vClientTable from '@/plugins/v-client-table'
import translate from '@/plugins/translate'
import highcharts from '@/plugins/highcharts'
import ckeditor from '@/plugins/ckeditor'

const plugins = {
  store,
  router,
  storage,
  http,
  api,
  auth,
  globalFilters,
  veeValidate,
  vToast,
  vTooltip,
  alert,
  mask,
  globalComponents,
  layouts,
  vClipboard,
  vInstant,
  vModal,
  vNumeric,
  vAutosuggest,
  vTextareaAutoresize,
  config,
  vCalendar,
  vPlyr,
  vAutocomplete,
  vServerTable,
  vClientTable,
  translate,
  highcharts,
  ckeditor,
}

export const install = Vue => {
  // Install all custom plugins
  Object.values(plugins).forEach(plugin => {
    plugin.install && Vue.use(plugin)
    plugin.expose && plugin.expose(Vue)
  })
}

import { AppLayout as Main } from '@/layouts'
import { createSubRoutes } from '@/router/helpers'
import { view } from '@/helpers'
import AuthMiddleware from '@/middleware/auth'

export default createSubRoutes('/', Main, [
  {
    path: '/settings',
    name: 'Settings',
    component: view('Settings/Settings')
  },
  {
    path: '/settings/invoicing',
    name: 'SettingsInvoicing',
    component: view('Settings/SettingsInvoicing')
  },
  {
    path: '/settings/tax',
    name: 'SettingsTaxail',
    component: view('Settings/SettingsTax')
  },
  {
    path: '/settings/plan-and-billing',
    name: 'SettingsPlanBilling',
    component: view('Settings/SettingsPlanBilling')
  },
  {
    path: '/settings/plan-and-billing',
    name: 'SettingsPlanBillingWithoutHeader',
    meta: {
      hideHeader: true,
    },
    component: view('Settings/SettingsPlanBilling')
  },
  {
    path: '/settings/plan-and-billing/payment/update',
    name: 'SettingsPlanBillingPaymentUpdate',
    component: view('Settings/Billings/UpdatePayment')
  },
  {
    path: '/settings/plan-and-billing/payment/:plan',
    name: 'SettingsPlanBillingPayment',
    meta: {
      middleware: AuthMiddleware,
      hideHeader: true,
    },
    component: view('Settings/Billings/CreditcardPayment')
  },
  {
    path: '/settings/email',
    name: 'SettingsEmail',
    component: view('Settings/SettingsEmail')
  },
  {
    path: '/settings/account',
    name: 'SettingsAccount',
    component: view('Settings/SettingsAccount')
  },
  {
    path: '/settings/referral',
    name: 'SettingsReferral',
    component: view('Settings/SettingsReferral')
  },
  {
    path: '/settings/language',
    name: 'SettingsLanguage',
    component: view('Settings/SettingsLanguage')
  },
  {
    path: '/settings/integration',
    name: 'SettingsIntegration',
    component: view('Settings/SettingsIntegration')
  },
  {
    path: '/settings/invoicing/mollie/callback',
    name: 'SettingsMollie',
    component: view('Settings/SettingsMollie')
  },
  {
    path: '/settings/pictime-callback',
    name: 'pictimeCallback',
    component: view('Settings/PictimeCallback'),
  },
])

import ApiBase from '@/api/ApiBase'

// Lead API Abstraction
export default class Lead extends ApiBase {

  /**
   * Create lead
   * @param  {String} company
   * @return {Promise}
   */
  submit(data = {}, options = {}) {
    return this.authRequest('POST', 'lead', {data, options})
  }
}

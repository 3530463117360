import ApiBase from '@/api/ApiBase'

// User API Abstraction
export default class User extends ApiBase {

  /**
   * Patch user
   *
   * @param  {Object} data
   * @return {Promise}
   */
  patch(data = {}, options = {}) {
    return this.authRequest('PATCH', 'settings/account', { data, ...options })
  }

  forgetPassword(data = {}, options = {}) {
    return this.authRequest('POST', 'password/email', { data, ...options })
  }

  resetPassword(data = {}, options = {}) {
    return this.request('POST', 'password/reset', { data, ...options })
  }

  /**
   * Get user details
   * @param  {String} company
   * @return {Promise}
   */
  getDetails(user, options = {}) {
    return this.authRequest('GET', 'user/me', options)
  }

  updatePassword(data = {}, options = {}) {
    return this.authRequest('PATCH', 'auth/user-password', { data, ...options })
  }

  patchSettings(data = {}, options = {}) {
    return this.authRequest('PATCH', 'user-settings', { data, ...options })
  }

  checkIntroSteps(data = {}, options = {}) {
    return this.authRequest('GET', 'user/check-intro-steps', options)
  }
}

<template>
  <div>
    <div
      class="editor"
      :class="{ 'basic': toolbar == 'basic' }"
    >
      <editor-menu-bar
        v-slot="{ commands, isActive, getMarkAttrs, menu }"
        :editor="editor"
      >
        <div class="menubar">
          <!-- <form class="menububble__form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
            <input class="menububble__input" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu"/>
            <button class="menububble__button" @click="setLinkUrl(commands.link, null)" type="button">
              Update
            </button>
          </form> -->
          <form
            v-if="tokenMenuIsActive"
            class="menububble__form"
          >
            <div class="select-container">
              <select
                v-model="token"
                @change="insertToken()"
              >
                <option value="null">
                  Choose a token to insert
                </option>
                <option value="1">
                  Client first name
                </option>
                <option value="2">
                  Client last name
                </option>
                <option value="3">
                  Client email
                </option>
                <option value="4">
                  Project date
                </option>
                <option value="6">
                  Project name
                </option>
                <option
                  v-if="!blankEmail"
                  value="5"
                >
                  Link to the document
                </option>
              </select>
            </div>
          </form>
          <div class="toolbar">
            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.bold() }"
              @click="commands.bold"
            >
              <icon name="bold" />
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.italic() }"
              @click="commands.italic"
            >
              <icon name="italic" />
            </button>

            <v-dropdown
              align="left"
              class="inline-block"
            >
              <div
                slot="link"
                class="menubar__button"
                v-show="toolbar == 'full'"
              >
                <icon name="font_family" />
              </div>
              <div
                slot="dropdown"
                class="bg-white border z-10 w-48 -mt-1 border-grey-light overflow-hidden"
              >
                <div>
                  <button
                    class="menubar__button_link"
                    style="font-family: Sans Serif;"
                    @click="commands.font_type({ name: 'Sans Serif' })"
                  >
                    Sans Serif
                  </button>
                </div>
                <div>
                  <button
                    class="menubar__button_link"
                    style="font-family: Serif;"
                    @click="commands.font_type({ name: 'Serif' })"
                  >
                    Serif
                  </button>
                </div>
                <div>
                  <button
                    class="menubar__button_link"
                    style="font-family: Arial;"
                    @click="commands.font_type({ name: 'Arial' })"
                  >
                    Arial
                  </button>
                </div>
                <div>
                  <button
                    class="menubar__button_link"
                    style="font-family: Times New Roman;"
                    @click="commands.font_type({ name: 'Times New Roman' })"
                  >
                    Times New Roman
                  </button>
                </div>
                <div>
                  <button
                    class="menubar__button_link"
                    style="font-family: Garamond;"
                    @click="commands.font_type({ name: 'Garamond' })"
                  >
                    Garamond
                  </button>
                </div>
                <div>
                  <button
                    class="menubar__button_link"
                    style="font-family: Georgia;"
                    @click="commands.font_type({ name: 'Georgia' })"
                  >
                    Georgia
                  </button>
                </div>
                <div>
                  <button
                    class="menubar__button_link"
                    style="font-family: Tahoma;"
                    @click="commands.font_type({ name: 'Tahoma' })"
                  >
                    Tahoma
                  </button>
                </div>
                <div>
                  <button
                    class="menubar__button_link"
                    style="font-family: Trebuchet MS;"
                    @click="commands.font_type({ name: 'Trebuchet MS' })"
                  >
                    Trebuchet MS
                  </button>
                </div>
                <div>
                  <button
                    class="menubar__button_link"
                    style="font-family: Verdana;"
                    @click="commands.font_type({ name: 'Verdana' })"
                  >
                    Verdana
                  </button>
                </div>
              </div>
            </v-dropdown>

            <v-dropdown
              class="inline-block"
              align="left"
            >
              <div
                slot="link"
                class="menubar__button"
                v-show="toolbar == 'full'"
              >
                <icon name="font_size" />
              </div>
              <div
                slot="dropdown"
                class="bg-white border z-10 w-24 -mt-1 border-grey-light overflow-hidden"
              >
                <div>
                  <button
                    class="menubar__button_link"
                    @click="commands.font_size({ name: '8px' })"
                  >
                    8
                  </button>
                </div>
                <div>
                  <button
                    class="menubar__button_link"
                    @click="commands.font_size({ name: '10px' })"
                  >
                    10
                  </button>
                </div>
                <div>
                  <button
                    class="menubar__button_link"
                    @click="commands.font_size({ name: '12px' })"
                  >
                    12
                  </button>
                </div>
                <div>
                  <button
                    class="menubar__button_link"
                    @click="commands.font_size({ name: '14px' })"
                  >
                    Default
                  </button>
                </div>
                <div>
                  <button
                    class="menubar__button_link"
                    @click="commands.font_size({ name: '16px' })"
                  >
                    16
                  </button>
                </div>
                <div>
                  <button
                    class="menubar__button_link"
                    @click="commands.font_size({ name: '18px' })"
                  >
                    18
                  </button>
                </div>
                <div>
                  <button
                    class="menubar__button_link"
                    @click="commands.font_size({ name: '21px' })"
                  >
                    21
                  </button>
                </div>
              </div>
            </v-dropdown>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.underline() }"
              @click="commands.underline"
            >
              <icon name="underline" />
            </button>

            <v-dropdown
              class="menubar__button"
              v-show="toolbar == 'full'"
              align="left"
            >
              <div
                slot="link"
              >
                <icon name="heading" />
              </div>
              <div
                slot="dropdown"
                class="bg-white border z-10 w-48 mt-5 border-grey-light overflow-hidden"
              >
                <div>
                  <button
                    class="menubar__button_link"
                    style="font-size:12px;"
                    @click="commands.heading({ level: 0 })"
                  >
                    Paragraph
                  </button>
                </div>
                <div>
                  <button
                    class="menubar__button_link"
                    style="font-size:21px; font-weight:bold"
                    @click="commands.heading({ level: 1 })"
                  >
                    Heading 1
                  </button>
                </div>
                <div>
                  <button
                    class="menubar__button_link"
                    style="font-size:18px; font-weight:bold"
                    @click="commands.heading({ level: 2 })"
                  >
                    Heading 2
                  </button>
                </div>
                <div>
                  <button
                    class="menubar__button_link"
                    style="font-size:16px; font-weight:bold"
                    @click="commands.heading({ level: 3 })"
                  >
                    Heading 3
                  </button>
                </div>
              </div>
            </v-dropdown>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.bullet_list() }"
              @click="commands.bullet_list"
              v-show="toolbar == 'full'"
            >
              <icon name="ul" />
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.ordered_list() }"
              @click="commands.ordered_list"
              v-show="toolbar == 'full'"
            >
              <icon name="ol" />
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.link() }"
              @click="setUrl(commands.link)"
            >
              <icon name="link" />
            </button>


            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.blockquote() }"
              @click="commands.blockquote"
              v-show="toolbar == 'full'"
            >
              <icon name="quote" />
            </button>

            <button
              class="menubar__button"
              @click="commands.undo"
              v-show="toolbar == 'full'"
            >
              <icon name="undo" />
            </button>

            <button
              class="menubar__button"
              @click="commands.redo"
              v-show="toolbar == 'full'"
            >
              <icon name="redo" />
            </button>

            <button
              class="menubar__button"
              @click="showImagePrompt(commands.image)"
              v-show="toolbar == 'full'"
            >
              <icon name="image" />
            </button>
            <div
              v-if="allowToken"
              class="menubar__link no-hover"
              @click="showTokenMenu()"
            >
              <div>
                <span>Insert Token</span>
                <v-help-tooltip
                  class="-mt-px"
                  :options="tooltipContent"
                />
              </div>
            </div>

            <!-- <div
              v-if="allowToken"
              class="menubar__button"
              style="float:right; background-color: rgba(0,0,0, 0.05); cursor:default!important"
            >
              Type { to insert token
            </div> -->
          <!--   <div
              v-if="allowToken"
              class="menubar__select"
            >
              <div class="select-container">
                <select @change="insertToken('Hey')">
                  <option value="clientFirstName" data-id="1">Client first name</option>
                  <option value="clientLastName" data-id="2">Client last name</option>
                  <option value="clientEmail" data-id="3">Client email</option>
                  <option value="projectDate" data-id="4">Project date</option>
                  <option value="documentLink" data-id="5">Link to the document</option>
                </select>
              </div>
            </div> -->
          </div>
        </div>
      </editor-menu-bar>
      <editor-content
        class="editor__content"
        :editor="editor"
      />
    </div>

    <div
      v-show="showSuggestions"
      ref="suggestions"
      class="suggestion-list"
    >
      <template v-if="hasResults">
        <div
          v-for="(user, index) in filteredUsers"
          :key="user.id"
          class="suggestion-list__item"
          :class="{ 'is-selected': navigatedUserIndex === index }"
          @click="selectUser(user)"
        >
          {{ user.name }}
        </div>
      </template>
      <div
        v-else
        class="suggestion-list__item is-empty"
      >
        No token found
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/VEditor/Icon'
import Fuse from 'fuse.js'
import tippy from 'tippy.js'
import { DOMParser } from 'prosemirror-model'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import { FontType, FontSize, Enter } from '@/components/VEditor/Extensions'

import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Mention,
  Image,
} from 'tiptap-extensions'

export default {
  name: 'VEditor',
  components: {
    Icon,
    EditorMenuBar,
    EditorContent,
  },
  props: {
    toolbar:{
      type: String,
      default: 'full'
    },
    blankEmail: {
      type: Boolean,
      default: false
    },
    editorContent: {
      type: String,
      default: null
    },
    allowToken: {
      type: Boolean,
      default: true
    },
    updatePending: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      query: null,
      suggestionRange: null,
      filteredUsers: [],
      navigatedUserIndex: 0,
      insertMention: () => {},
      observer: null,
      linkUrl: null,
      token: null,
      linkMenuIsActive: false,
      tokenMenuIsActive: false,
      editor: null,
      html: 'Update content to see changes',
      tooltipContent: {
        content: `
          A token is a variable which will be replace by the actual content. For example if a client first name is John, {clientFirstName} will be replaced with John when the email get sends out.
        `
      },
    }
  },
  computed: {
    hasResults() {
      return this.filteredUsers.length
    },
    showSuggestions() {
      return this.query || this.hasResults
    },
  },
  watch: {
    updatePending: function(newState, oldState) {
      if(this.updatePending){
        this.editor.setContent(this.editorContent)
        this.$emit('update:update-pending', false)
      }
    }
  },
  mounted(){
    let extensions = [
        new Blockquote(),
        new CodeBlock(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new BulletList(),
        new OrderedList(),
        new ListItem(),
        new TodoItem(),
        new TodoList(),
        new Bold(),
        new Code(),
        new Italic(),
        new Link(),
        new Strike(),
        new Underline(),
        new History(),
        new Image(),
        new FontType(),
        new FontSize(),
        new Enter(),
      ]

    if(this.allowToken === true){
      let listOfToken = [
        { id: 1, name: 'clientFirstName' },
        { id: 2, name: 'clientLastName' },
        { id: 3, name: 'clientEmail' },
        { id: 4, name: 'projectDate' },
        { id: 5, name: 'documentLink' },
        { id: 6, name: 'projectName' },
        { id: 7, name: 'clientAddress' },
        { id: 8, name: 'clientPostalCode' },
        { id: 9, name: 'clientCity' },
        { id: 10, name: 'clientCountry' },
        { id: 11, name: 'clientCompanyName' },
        { id: 12, name: 'location' },
        { id: 13, name: 'clientPortal' },
      ];

      if(this.blankEmail){
        listOfToken.pop()
      }

      let useOfToken = new Mention({
        matcher: {
          char: '{',
          allowSpaces: false,
          startOfLine: false,
        },
        // a list of all suggested items
        items: () => listOfToken,
        // is called when a suggestion starts
        onEnter: ({
          items, query, range, command, virtualNode,
        }) => {
          this.query = query
          this.filteredUsers = items
          this.suggestionRange = range
          this.renderPopup(virtualNode)
          // we save the command for inserting a selected mention
          // this allows us to call it inside of our custom popup
          // via keyboard navigation and on click
          this.insertMention = command
        },
        // is called when a suggestion has changed
        onChange: ({
          items, query, range, virtualNode,
        }) => {
          this.query = query
          this.filteredUsers = items
          this.suggestionRange = range
          this.navigatedUserIndex = 0
          this.renderPopup(virtualNode)
        },
        // is called when a suggestion is cancelled
        onExit: () => {
          // reset all saved values
          this.query = null
          this.filteredUsers = []
          this.suggestionRange = null
          this.navigatedUserIndex = 0
          this.destroyPopup()
        },
        // is called on every keyDown event while a suggestion is active
        onKeyDown: ({ event }) => {
          // pressing up arrow
          if (event.keyCode === 38) {
            this.upHandler()
            return true
          }
          // pressing down arrow
          if (event.keyCode === 40) {
            this.downHandler()
            return true
          }
          // pressing enter
          if (event.keyCode === 13) {
            this.enterHandler()
            return true
          }
          return false
        },
        // is called when a suggestion has changed
        // this function is optional because there is basic filtering built-in
        // you can overwrite it if you prefer your own filtering
        // in this example we use fuse.js with support for fuzzy search
        onFilter: (items, query) => {
          if (!query) {
            return items
          }
          const fuse = new Fuse(items, {
            threshold: 0.2,
            keys: ['name'],
          })
          return fuse.search(query)
        },
      })
      extensions.push(useOfToken)
    }

    this.editor = new Editor({
      extensions: extensions,
      content:'',
      onUpdate: ({ getJSON, getHTML }) => {
        this.$emit('update:editor-content', getHTML())
      },
    })

    this.editor.setContent(this.editorContent)
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  methods: {
    showTokenMenu() {
      this.tokenMenuIsActive = true
    },
    elementFromString(value) {
      const element = document.createElement('div')
      element.innerHTML = value.trim()

      return element
    },
    insertHTML({ state, view }, value) {
      const { selection } = state
      const element = this.elementFromString(value)
      const slice = DOMParser.fromSchema(state.schema).parseSlice(element)
      const transaction = state.tr.insert(selection.anchor, slice.content)

      view.dispatch(transaction)
    },
    insertToken(){
      let name = ''
      if (this.token == 1) {
        name = '{clientFirstName}';
      } else if (this.token == 2) {
        name = '{clientLastName}';
      } else if (this.token == 3) {
        name = '{clientEmail}';
      } else if (this.token == 4) {
        name = '{projectDate}';
      } else if (this.token == 5) {
        name = '{documentLink}';
      } else if (this.token == 6) {
        name = '{projectName}';
      } else if (this.token == 7) {
        name = '{clientAddress}';
      } else if (this.token == 8) {
        name = '{clientPostalCode}';
      } else if (this.token == 9) {
        name = '{clientCity}';
      } else if (this.token == 10) {
        name = '{clientCountry}';
      } else if (this.token == 11) {
        name = '{clientCompanyName}';
      } else if (this.token == 12) {
        name = '{location}';
      } else if (this.token == 13) {
        name = '{location}';
      }


      if(this.token > 0){
        this.insertHTML(this.editor, `<span class="mention" data-mention-id="${this.token}" contenteditable="false">${name}</span>`)
      }
      this.tokenMenuIsActive = false
    },
    setUrl(command) {
      const state = this.editor.state

      // get marks, if any from selected area
      const { from, to } = state.selection
      let marks = []
      state.doc.nodesBetween(from, to, (node) => {
        marks = [...marks, ...node.marks]
      })

      const mark = marks.find((markItem) => markItem.type.name === 'link')

      let urlSetting = ''

      if (mark && mark.attrs.href) {
        const presetURL = mark.attrs.href
        prompt('Please update url', presetURL) // let a user see the previously set URL
      } else {
        urlSetting = prompt('Please add url', '') // a clean prompt, has had no anchor
      }

      command({ href: urlSetting })
    },
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href
      this.linkMenuIsActive = true
      this.$nextTick(() => {
        this.$refs.linkInput.focus()
      })
    },
    hideLinkMenu() {
      this.linkUrl = null
      this.linkMenuIsActive = false
    },
    setLinkUrl(command, url) {
      command({ href: url, target: '_blank' })
      this.hideLinkMenu()
    },

    // navigate to the previous item
    // if it's the first item, navigate to the last one
    upHandler() {
      this.navigatedUserIndex = ((this.navigatedUserIndex + this.filteredUsers.length) - 1) % this.filteredUsers.length
    },
    // navigate to the next item
    // if it's the last item, navigate to the first one
    downHandler() {
      this.navigatedUserIndex = (this.navigatedUserIndex + 1) % this.filteredUsers.length
    },
    enterHandler() {
      const user = this.filteredUsers[this.navigatedUserIndex]
      if (user) {
        this.selectUser(user)
      }
    },
    // we have to replace our suggestion text with a mention
    // so it's important to pass also the position of your suggestion text
    selectUser(user) {
      this.insertMention({
        range: this.suggestionRange,
        attrs: {
          id: user.id,
          label: user.name+'}',
        },
      })
      this.editor.focus()
    },
    // renders a popup with suggestions
    // tiptap provides a virtualNode object for using popper.js (or tippy.js) for popups
    renderPopup(node) {
      if (this.popup) {
        return
      }
      this.popup = tippy(node, {
        content: this.$refs.suggestions,
        trigger: 'mouseenter',
        interactive: true,
        theme: 'dark',
        placement: 'top-start',
        inertia: true,
        duration: [400, 200],
        showOnInit: true,
        arrow: true,
        arrowType: 'round',
      })
      // we have to update tippy whenever the DOM is updated
      if (MutationObserver) {
        this.observer = new MutationObserver(() => {
          this.popup.popperInstance.scheduleUpdate()
        })
        this.observer.observe(this.$refs.suggestions, {
          childList: true,
          subtree: true,
          characterData: true,
        })
      }
    },
    destroyPopup() {
      if (this.popup) {
        this.popup.destroy()
        this.popup = null
      }
      if (this.observer) {
        this.observer.disconnect()
      }
    },
    showImagePrompt(command) {
      const src = prompt('Enter the url of your image here (this needs to be hosted by yourself)')
      if (src !== null) {
        command({ src })
      }
    },
  }
}
</script>
<style lang="scss">
.mention {
  background: #DDE6FF;
  color: #0942BC;
  font-weight: 600;
  border-radius: 4px;
  padding: 0.2rem 0.5rem;
  white-space: nowrap;
}
.mention-suggestion {
  color: #0942BC;
  background: #DDE6FF;
  padding: 0.2rem 0.5rem;
  font-weight: 600;
  border-radius: 4px;
}

.suggestion-list {
  padding: 0.2rem;
  border: 2px solid rgba(#000, 0.1);
  font-size: 0.8rem;
  font-weight: bold;
  &__no-results {
    padding: 0.2rem 0.5rem;
  }
  &__item {
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
    margin-bottom: 0.2rem;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    &.is-selected,
    &:hover {
      background-color: rgba(#fff, 0.2);
    }
    &.is-empty {
      opacity: 0.5;
    }
  }
}
.tippy-tooltip.dark-theme {
  background-color: #000;
  padding: 0;
  font-size: 1rem;
  text-align: inherit;
  color: #fff;
  border-radius: 5px;
  .tippy-backdrop {
    display: none;
  }
  .tippy-roundarrow {
    fill: #000;
  }
  .tippy-popper[x-placement^=top] & .tippy-arrow {
    border-top-color: #000;
  }
  .tippy-popper[x-placement^=bottom] & .tippy-arrow {
    border-bottom-color: #000;
  }
  .tippy-popper[x-placement^=left] & .tippy-arrow {
    border-left-color: #000;
  }
  .tippy-popper[x-placement^=right] & .tippy-arrow {
    border-right-color: #000;
  }
}

.menubar{
  transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
  border: 1px solid #d1d1d1;
  border-radius:4px 4px 0 0;
  border-bottom:none;
  display:flex;
  flex-wrap: wrap;

  &.is-hidden {
    visibility: hidden;
    opacity: 0;
  }

  &.is-focused {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.2s, opacity 0.2s;
  }

  &__button_link{
    font-weight: normal;
    width:100%;
    display: block;
    background: transparent;
    border: 0;
    padding: .6rem;
    border-radius: 0!important;
    cursor: pointer;
    text-align:left;
    background:transparent;
    color:#000;

    &:hover {
      background-color: rgba(#000, 0.05);
    }
  }

  &__link{
    font-weight: bold;
    display: inline-flex;
    background: transparent;
    border: 0;
    margin-top:-2px;
    padding: 0 0 .3rem .3rem;
    margin-right: 0.6rem;
    border-radius: 50%;
    cursor: pointer;
    background:transparent;
    color:#000;

    &.no-hover{
      &:hover {
        background:none;
        text-decoration:underline
      }
    }
  }

  &__button {
    font-weight: bold;
    display: inline-flex;
    background: transparent;
    border: 0;
    padding: .5rem .5rem .3rem .5rem;
    margin-right: 0.6rem;
    margin:0.2rem 0.6rem 0.2rem 0;
    border-radius: 50%!important;
    cursor: pointer;
    background:transparent;
    color:#000;

    &:hover {
      background-color: rgba(#000, 0.05);
    }

    &.no-hover{
      &:hover {
        background:none;
        text-decoration:underline
      }
    }

    &.is-active {
      background-color: rgba(#000, 0.1);
    }
  }

  span#{&}__button {
    font-size: 13.3333px;
  }
}

.editor{
  .ProseMirror{
    height:250px;
    overflow:auto;
    margin:0 0 20px 0;
    border: 1px solid #d1d1d1;
    padding:15px 23px;
    border-radius:0 0 4px 4px;
    line-height:1.3rem;
    &:focus{
      outline:0;
    }
    a{
      text-decoration:underline;
      cursor:pointer;
    }
  }

  &.basic{
    .ProseMirror{
      height:100px;
    }
  }
  .menububble__form{
    display:flex;
    width:100%;
    margin-bottom:10px;
    border-bottom:1px solid #d1d1d1!important;
    align-items: center;
    padding:15px;

    .menububble__input{
      width:300px;
    }
    .menububble__button{
      margin-left:10px;
    }
  }

  .menubar__select{
    cursor:pointer;
    font-weight: bold;
    display: -webkit-inline-box;
    display: inline-flex;
    background: transparent;
    border: 0;
    padding: 0 .5rem;
    margin-right: .2rem;
    border-radius: 3px;
    cursor: pointer;
    background: transparent;
    color: #000;
    justify-content: flex-end;
    display:flex;
    flex-grow:1;

    .select-container{
      float:right;
    }
  }
}

.modal{
  .menubar{
    border:none;
  }
  .editor{
    .ProseMirror{
      border:none;
      border-top:1px solid #d1d1d1;
      margin:0;
    }
  }
}
</style>

<style lang="scss">
.dark{
  label{
    color:#B1B6CD;
  }
}

.tab-container{

  width:100%;

  @media (min-width: 640px){
   padding:14px;
   margin:0 0 -8px 0;
  }

  .checkbox{
    width:20px;
    height:20px;
    position:absolute;
    left:32px;
    top:16px;
    cursor:pointer;

    @media (max-width: 640px) {
      left:50%;
      margin-left:-10px;
      top:15px;
    }
  }

  .tab{
    @media (max-width: 640px) {
      width:100%;
      display:block;
    }

    .text{
      text-align:center;
      @media (min-width: 640px) {
        display:inline;
        text-align:left;
      }
    }
  }

  .styled-checkbox{

    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + label:before {
      content: '';
      margin:0 auto 6px auto;
      display: block;
      vertical-align: text-top;
      width: 21px;
      height: 21px;
      background: white;
      border-radius: 3px;
      border: 1px solid #b8c2cc;
      transition: all .2s ease;

      @media (min-width: 640px) {
        margin-right: 10px;
        display: inline-block;
      }

    }

    + label{
      border-radius: 2px 2px 0 0;
      padding:14px;
      background-color: #3F4354;
      margin-right:0px;
      color:#B1B6CD;
      font-weight:normal;

      @media (min-width: 640px) {
        padding:18px;
      }

    }

    &:checked + label:before {
      background-color: #C9CFDB;
    }
    &:hover + label:before {
      background: #C9CFDB;
    }
    &:disabled + label:after,
    &:checked + label:after {
      transition: all .2s ease;
      content: '';
      position: absolute;
      left:45.5%;
      transform:translate(-50%,0);
      top:25px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow:
      1px 0 0 white,
      2px 0 0 white,
      3px 0 0 white,
      4px 0 0 white,
      4px -1px 0 white,
      4px -2px 0 white,
      4px -3px 0 white,
      4px -4px 0 white,
      4px -5px 0 white,
      4px -6px 0 white,
      4px -7px 0 white,
      4px -8px 0 white,
      4px -9px 0 white,
      4px -10px 0 white;
      transform: rotate(45deg);

      @media (min-width: 340px) {
        left:45.5%;
      }

      @media (min-width: 640px) {
        left:23px;
        top:28px;
      }
    }
  }

  &.active{
    .styled-checkbox{
      &:checked + label{
        background-color: #F4F4F4;
        color:#000;
      }
    }
  }

}
</style>
<template>
  <div
    class="inline-block dark tab-container"
    :class="[currentStep == itemStep ? 'active' : '']"
  >
    <input
      :id="id"
      class="styled-checkbox"
      :checked="value"
      type="checkbox"
      :disabled="disabled"
      @change="$emit('input', $event.target.checked)"
    >
    <label
      class="tab"
      @click="clickTab"
    >
      <div class="text">
        {{ label }}
      </div>
    </label>

    <label
      :for="id"
      class="checkbox"
    />
  </div>
</template>

<script>
export default {
  name: 'VCheckboxTab',
  props: {
    id: {
      type: String,
      default: null
    },
    itemStep:{
      type: String,
      default: null,
    },
    currentStep:{
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: [Number, Boolean],
      default: false
    },
    classes: {
      type: String,
      default: null
    },
    active: {
      type: [String, Boolean],
      default: false
    },
  },
  computed: {
    disabled(){
      if(!this.active){
        return false
      }
      return true
    }
  },
  methods:{
    clickTab(){
      if(this.value){
        this.$emit('switchStep')
      }
      if(!this.value){
        this.$emit('activeTab')
      }
    }
  }

}
</script>

import ApiBase from '@/api/ApiBase'
import { client } from './VOClient'

// Questionnaire API Abstraction
export default class Questionnaires extends ApiBase {

  /**
   * Create lead
   * @param  {String} company
   * @return {Promise}
   */
  submit(target, data = {}, options = {}) {
    return this.authRequest('POST', `view/questionnaires/${target}/submit`, {data, options})
  }

  /**
   * Get
   * @param  {Object}
   * @return {Promise}
   */
  list(data = {}, options = {}) {
    return this.authRequest('POST', 'questionnaires', {data, options})
  }

  /**
   * View for public
   * @param  {String}
   * @return {Promise}
   */
  view(target, type, options = {}) {
    return this.authRequest('GET', `view/questionnaires/${target}?type=${type}`, options)
  }

  /**
   * Update / Create
   * @param  {Object}
   * @return {Promise}
   */
  save(data = {}, options = {}) {
    return this.authRequest('POST', 'questionnaires', { data, options })
  }

  /**
   * Delete
   * @param  {Object}
   * @return {Promise}
   */
  delete(data = {}, options = {}) {
    return this.authRequest('DELETE', 'questionnaires', { data, options })
  }

  /**
   * Answer
   * @param  {Object}
   * @return {Promise}
   */
  answer(data = {}, target, options = {}) {
    return this.authRequest('POST', `view/questionnaires/answer/${target}`, { data, options })
  }

  /**
   * Get detail
   * @param  {String}
   * @return {Promise}
   */
  detail(target, type, options = {}) {
    return this.authRequest('GET', `questionnaires/${target}/detail?type=${type}`, options)
  }

  /**
   * Patch state
   * @param  {String}
   * @return {Promise}
   */
  updateState(target, state, options = {}) {
    return this.authRequest('PATCH', `questionnaires/${target}/${state}`,  options)
  }
}

export const createQuestionnaireFromTemplate = (companyId, templateId, workflowJobId) => {
  return client.post(`company/${companyId}/templates/questionnaires/${templateId}/create`, { workflow_job_id : workflowJobId })
}

<template>
  <span class="label">
    <slot />
  </span>
</template>

<script>
export default {
  name: 'VLabel'
}
</script>

<style lang="scss">
.label {
  user-select: none;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  padding: 3px 11px;
  border-radius: 11px;
  background-color: #f8f8f8;

  img {
    flex: none;
    border: 0;
    width: auto;
    height: auto;
  }

  &--forecast {
    background-color: #dde4fb;
    color: #547AE9;
  }
}
</style>

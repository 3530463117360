import ApiBase from '@/api/ApiBase'

// OAuth API Abstraction
export default class OAuth extends ApiBase {
  /**
   * Gmail
   *
   * @param  {Object} data
   *
   * @return {Promise}
   */
  gmail(data = {}) {
    return this.authRequest('GET', 'oauth/gmail', { data } )
  }

  /**
   * Gmail check if connected
   *
   * @param  {Object} data
   *
   * @return {Promise}
   */
  gmailCheck(data = {}) {
    return this.authRequest('GET', 'oauth/gmail/check', { data } )
  }

  /**
   * Disconnect Gmail
   *
   * @param  {Object} data
   *
   * @return {Promise}
   */
  gmailDisconnect(data = {}) {
    return this.authRequest('GET', 'oauth/gmail/disconnect', { data } )
  }

  /**
   * Gmail callback
   *
   * @param  {Object} data
   *
   * @return {Promise}
   */
  gmailCallback(data = {}) {
    return this.authRequest('POST', 'oauth/gmail/callback', { data } )
  }
}
